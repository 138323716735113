import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
//import Link from "@mui/material/Link";
import "./ModulosBreadCrumbs.css";
import { Link } from "react-router-dom";

export default function ModulosBreadCrumbs(props) {
  let menuItems = props.menuItems;
  let marginTop = props.marginTop;
  let marginLeft = props.marginLeft;

  if (typeof marginTop === "undefined") {
    marginTop = "0px";
  }

  if (typeof marginLeft === "undefined") {
    marginLeft = "0px";
  }

  return (
    <>
      <Breadcrumbs
        className="clink-breadcrumb"
        aria-label="breadcrumb"
        sx={{ mb: "20px", mt: { marginTop }, ml: { marginLeft } }}
      >
        {menuItems.map((menu, index) => (
          <Link
            className="clink-breadcrumb"
            underline="none"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            key={index}
            to={menu.href}
          >
            {menu.componenteIcon}
            {menu.text}
          </Link>
        ))}
      </Breadcrumbs>
    </>
  );
}
