import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@mui/material";
import React, { useEffect, useContext, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import { EncuestaContext } from "../contexts/EncuestaContext";
import { DataGrid } from "@mui/x-data-grid";


const EliminaPractica = ({ id_practica, tableRownPractica }) => {
  const { deletePractica, idEncuesta } = useContext(EncuestaContext);

  const handleDeletePractica = async () => {
    await deletePractica(idEncuesta, id_practica);
    if (typeof tableRownPractica === 'function') {
      tableRownPractica();
    }
  };

  return (
    <Stack direction="row">
      <Tooltip TransitionComponent={Zoom} title="Eliminar" placement="top">
        <IconButton aria-label="delete" onClick={handleDeletePractica}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

function TablaPractica() {
  const { practicaList, deletePractica } = useContext(EncuestaContext);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    renderTableRownPractica();
  }, [practicaList]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'subdimension',
      headerName: 'SubDimensión',
      width: 200,
      editable: false,
    },
    {
      field: 'npractica',
      headerName: 'N° de Práctica',
      width: 300,
      editable: false,
    },
    {
      field: 'practica',
      headerName: 'Práctica',
      width: 600,
      editable: false,
      renderCell: (params) => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.value
          }}
        />
      ),
    },
    {
      field: 'configuracion',
      headerName: 'Configuración',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <EliminaPractica id_practica={params.id} tableRownPractica={renderTableRownPractica} />
      )
    },
  ];

  const renderTableRownPractica = () => {
    let contenido = practicaList;

    const idEncuesta = contenido.id_encuesta;
    setRows([]);
    if (Object.keys(contenido).length > 0) {
      contenido = contenido.Dimensions;
      contenido.forEach((dimension) => {
        let subdimension = dimension.SubDimensions;
        subdimension.forEach((infosubdimension) => {
          let numeropractica = infosubdimension.NumeroPracticas;
          numeropractica.forEach((infonumeropractica) => {
            let practica = infonumeropractica.Practicas;
            practica.forEach((infopractica) => {

              let objTablaPractica = {
                id: infopractica.id_practica,
                dimension: dimension.nombre,
                subdimension: infosubdimension.nombre,
                npractica: infonumeropractica.nombre,
                practica: infopractica.nombre,
              };

              setRows((prevRows) => [...prevRows, objTablaPractica]);

            });
          });
        });
      });
    }
    return rows;
  };

  if (rows.length == 0) {
    return (
      <>
        <Box
          sx={{
            width: "100%",
            maxWidth: { xs: 200, md: 200, lg: 250, xl: 250 },
          }}
        />
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </>

    );
  }

//  console.log(rows);

  return (
    <>
      {rows && rows.length > 0 && (
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[15]}
          checkboxSelection
          disableRowSelectionOnClick
          rowHeight={50}
          columnHeaderHeight={40}
        />

      )}
    </>
  );
}

export default TablaPractica;
