import { Box, Container, LinearProgress } from "@mui/material";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Login from "./Login";
import Footer from "./views/Footer";

import React, { useContext, useEffect } from "react";
import { AuthContext } from "./contexts/AuthContext";
import { EncuestaProvider } from "./contexts/EncuestaContext";
import { PlanificaEncuestaProvider } from "./contexts/PlanificaEncuestaContext";
import { ContestaEncuestaProvider } from "./contexts/ContestaEncuestaContext";
import { SigeProvider } from "./contexts/SigeContext";
import Dashboard from "./views/Dashboard";
import axios from "axios";
import Perfil from "./views/Perfil";
import AgregarCuenta from "./views/AgregarCuenta";
import ModuloPME from "./views/ModuloPME";
import Modulos from "./views/Modulos/Modulos";
import CargoCliente from "./views/CargoCliente";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Encuesta from "./views/Encuesta";
import MenuDashboardSec from "./views/MenuDashboardSec";
import MenuDashboard from "./views/MenuDashboard";
import MenuDashboardBase from "./views/MenuDashboardBase";
import SettingEncuesta from "./views/SettingEncuesta";
import NominaAlumnoManual from "./views/NominaAlumnoManual";
import AsistenciaEscolarManual from "./views/AsistenciaEscolarManual";
import AdmEstudiantil from "./views/AdmEstudiantil";
import NominaAlumno from "./views/NominaAlumno";
import AsistenciaEscolar from "./views/AsistenciaEscolar";
import { MineducProvider } from "./contexts/MineducContext";
import { IlumenProvider } from "./contexts/IlumenContext";
import ContestarEncuesta from "./views/ContestarEncuesta/ContestarEncuesta";
import PlanificarEncuesta from "./views/PlanificaEncuesta/PlanificaEncuesta";
import AccesoIlumen from "./views/AccesoIlumen";

import { ThemeProvider, CssBaseline } from '@mui/material';
import theme_main from './themes/main';

//import "./App.css";

const API_ADMIN_PROTO = process.env.REACT_APP_API_ADMIN_PROTO;
const API_ADMIN_HOST = process.env.REACT_APP_API_ADMIN_HOST;
const API_ADMIN_PORT = process.env.REACT_APP_API_ADMIN_PORT;

function App() {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    // Realiza la validación de la sesión aquí (por ejemplo, mediante una llamada a la API)
    // Actualiza el estado de isAuthenticated según el resultado de la validación
    const checkSession = async () => {
      //const localSessionToken = localStorage.getItem("token");
      //const formtoken = { token: localSessionToken };
      const formtoken = {};
      try {
        const { data } = await axios.post(
          `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/verify`,
          formtoken,
          {
            withCredentials: true,
          }
        );

        setIsAuthenticated(data.status);
      } catch (error) {
        setIsAuthenticated(false);
        console.log(error);
      }
    };

    checkSession();
  }, [setIsAuthenticated]);

  if (isAuthenticated === null) {
    // Renderiza un estado de carga mientras se realiza la validación de la sesión
    return (
      <>
        <Container
          maxWidth="lg"
          sx={{
            marginTop: "100px",
            alignContent: "center",
            textAlign: "center",
            /*background: "red"*/
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </Container>
      </>
    );
  }
  //console.log("App.js:" + isAuthenticated);
  return (
    <>
      <Box
        display="block"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        height="auto"
        sx={{ background: "#ffffff" }}
      >
        <Container
          maxWidth={false}
          height="auto"
          sx={{
            alignContent: "center",
            textAlign: "center",
            minHeight: "100%",
            height: "100%",
          }}
        >
          <ThemeProvider theme={theme_main}>
            <CssBaseline />
            <BrowserRouter>
              {isAuthenticated && <MenuDashboardBase />}
              <Routes>
                {!isAuthenticated && <Route path="/login" element={<Login />} />}
                {!isAuthenticated &&
                  <>
                    <Route
                      path="/contestar_encuesta/:id"
                      element={
                        <ContestaEncuestaProvider>
                          <ContestarEncuesta />
                        </ContestaEncuestaProvider>
                      } />
                  </>
                }
                {isAuthenticated ? (
                  <>
                    <Route path="/" element={
                      <IlumenProvider>
                        <Dashboard />
                      </IlumenProvider>
                    } />
                  </>
                ) : (
                  <Route path="/" element={<Navigate to="/login" replace />} />
                )}
                {isAuthenticated && <Route path="/perfil" element={<Perfil />} />}
                {isAuthenticated && (
                  <Route path="/agregar_cuenta" element={<AgregarCuenta />} />
                )}
                {isAuthenticated && (
                  <Route path="/modulos" element={<Modulos />} />
                )}
                {isAuthenticated && (
                  <Route path="/modulo/gopme" element={<ModuloPME />} />
                )}
                {isAuthenticated && (
                  <Route path="/cargo_cliente" element={<CargoCliente />} />
                )}
                {isAuthenticated && (
                  <>
                    <Route
                      path="/modulo/gopme/encuesta"
                      element={
                        <EncuestaProvider>
                          <Encuesta />
                        </EncuestaProvider>
                      }
                    />
                    <Route
                      path="/modulo/gopme/encuesta/:id"
                      element={
                        <EncuestaProvider>
                          <SettingEncuesta />
                        </EncuestaProvider>
                      }
                    />
                    <Route
                      path="/modulo/gopme/planificar_encuesta"
                      element={
                        <MineducProvider>
                          <EncuestaProvider>
                            <PlanificaEncuestaProvider>
                              <PlanificarEncuesta />
                            </PlanificaEncuestaProvider>
                          </EncuestaProvider>
                        </MineducProvider>
                      }
                    />
                  </>
                )}
                {isAuthenticated && (
                  <>
                    <Route
                      path="/modulo/ilumen"
                      element={<AdmEstudiantil />}
                    />
                    <Route
                      path="/modulo/ilumen/nomina_alumno"
                      element={
                        <MineducProvider>
                          <NominaAlumno />
                        </MineducProvider>
                      }
                    />
                    <Route
                      path="/modulo/ilumen/acceso_ilumen"
                      element={
                        <IlumenProvider>
                          <AccesoIlumen />
                        </IlumenProvider>
                      }
                    />
                    <Route
                      path="/modulo/ilumen/nomina_alumno/manual"
                      element={
                        <EncuestaProvider>
                          <SigeProvider>
                            <MineducProvider>
                              <NominaAlumnoManual />
                            </MineducProvider>
                          </SigeProvider>
                        </EncuestaProvider>
                      }
                    />
                    <Route
                      path="/modulo/ilumen/asistencia_escolar"
                      element={
                        <MineducProvider>
                          <AsistenciaEscolar />
                        </MineducProvider>
                      }
                    />
                    <Route
                      path="/modulo/ilumen/asistencia_escolar/manual"
                      element={
                        <MineducProvider>
                          <AsistenciaEscolarManual />
                        </MineducProvider>
                      }
                    />
                  </>
                )}
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </Container>
        <ToastContainer />
      </Box>
      <Footer />
    </>
  );
}

export default App;
