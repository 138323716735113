import React, { useContext, useRef, useState } from 'react';
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {
  Button,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { Mail, Fingerprint } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReCAPTCHA from 'react-google-recaptcha';

import { AuthContext } from "../contexts/AuthContext";

import imagenMain from "../assets/logoHeader.png";


const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;;

export default function FormLogin() {
  const { isAuthenticated, login } = useContext(AuthContext);

  const navigate = useNavigate();

  const captcha = useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captchaIsDone, setCaptchaIsDone] = useState(true);
  const handleRecaptcha = () => {
    setCaptchaIsDone(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      console.log(captchaIsDone);
      if (!captchaIsDone) {
        return;
      }
      const tokenReCaptcha = captcha.current.getValue();
      const formlogin = { email: email, password: password, tokenReCaptcha: tokenReCaptcha };
      login(formlogin);
    } catch (error) {

      toast.error(error, {
        position: "bottom-left",
        autoClose: 1000,
      });
    }

  };


  if (isAuthenticated) {
    navigate("/");
  }


  return (
    <>
      <Paper elevation={3} style={{ padding: '20px', paddingTop: "80px", paddingBottom: "80px" }}>
        <div style={{ textAlign: 'center' }}>
          <img
            src={imagenMain}
            style={{ width: '100px' }}
          />
        </div>
        <Typography variant="h5" style={{ textAlign: 'center', paddingTop: "40px" }}>
          Iniciar Sesión
        </Typography>
        <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Correo Electrónico"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              margin="normal"
              sx={{ background: "#fafafa" }}
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Contraseña"
              variant="outlined"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              margin="normal"
              sx={{ background: "#fafafa" }}
              size='small'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Fingerprint />
                  </InputAdornment>
                ),
              }}
            />
            <Box style={{ display: 'flex', marginBottom: "20px", marginTop: "20px", width: '100%' }}>
              <ReCAPTCHA
                ref={captcha}
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={handleRecaptcha}
                style={{ margin: 'auto' }}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
            >
              Iniciar Sesión
            </Button>
          </form>
      </Paper>
    </>
  );
}
