import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  NativeSelect,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";
import ModulosBreadCrumbs from "./Modulos/ModulosBreadCrumbs/ModulosBreadCrumbs";
import HomeIcon from "@mui/icons-material/Home";
import SourceIcon from "@mui/icons-material/Source";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

import { EncuestaContext } from "../contexts/EncuestaContext";

export const NOTIFICATIONTIME = process.env.REACT_APP_NOTIFICATIONTIME;

const API_ADMIN_PROTO = process.env.REACT_APP_API_ADMIN_PROTO;
const API_ADMIN_HOST = process.env.REACT_APP_API_ADMIN_HOST;
const API_ADMIN_PORT = process.env.REACT_APP_API_ADMIN_PORT;

const Encuesta = () => {
  const { encuestaList, getEncuestas, cerrarEncuesta, estadoEncuesta, setEstadoEncuesta } = useContext(EncuestaContext);
  const [encuesta, setEncuesta] = useState("");
  const [tipoEncuesta, setTipoEncuesta] = useState("");
  const [anioEncuesta, setAnioEncuesta] = useState("");

  const [tipoEncuestaList, setTipoEncuestaList] = useState([]);

  const actualAnio = new Date().getFullYear();
  const iniciaAnio = 2000;


  const handleEstadoEncuesta = async (id, estado) => {
    const nuevoEstado = await cerrarEncuesta(id, estado);
    getEncuestas();
  };

  const renderOptionsAnios = () => {
    const options = [];
    options.push(<option key="   " value="    "></option>);
    for (let anio = actualAnio; anio >= iniciaAnio; anio--) {
      options.push(
        <option key={anio} value={anio}>
          {anio}
        </option>
      );
    }

    return options;
  };

  const renderOptionsTipoEncuesta = () => {
    const options = [];
    const contenido = tipoEncuestaList;
    options.push(<option key="   " value="    "></option>);
    if (Object.keys(contenido).length > 0) {
      contenido.forEach((item) => {
        options.push(
          <option key={item.id_tipo_encuesta} value={item.id_tipo_encuesta}>
            {item.nombre}
          </option>
        );
      });
    }
    return options;
  };

  useEffect(() => {
    getEncuestas();
    getTipoEncuesta();
  }, []);

  const getTipoEncuesta = async () => {
    try {
      const response = await axios.get(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/encuesta_tipo`, { withCredentials: true });
      if (response.data.success) {
        setTipoEncuestaList(response.data.tipo_encuesta);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createEncuesta = async () => {
    const formEncuesta = {
      nombre: encuesta,
      id_tipo_encuesta: tipoEncuesta,
      anio: anioEncuesta,
    };

    axios
      .post(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/encuestas`, formEncuesta, { withCredentials: true })
      .then((response) => {
        setEncuesta("");
        //setAnioEncuesta("");

        if (response.data.success) {
          toast.success(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        } else {
          toast.error(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        }

        getEncuestas();
        // Realizar acciones adicionales después de enviar los datos
      })
      .catch((error) => {
        console.error("Error al enviar la solicitud POST", error);
      });
  };

  const deleteEncuesta = async (id) => {
    try {
      const response = await axios.delete(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/encuestas/${id}`, { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }

      getEncuestas();
    } catch (error) {
      console.error(error);
    }
  };

  let menuItems = [
    {
      href: "/",
      text: "Home",
      componenteIcon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulos",
      text: "Modulos",
      componenteIcon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulo/gopme",
      text: "goPME",
      componenteIcon: <SourceIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          background: "#ffffff",
          height: "auto",
          pt: "40px",
        }}
      >
        <ModulosBreadCrumbs menuItems={menuItems} />
        <Box>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: {
                lg: 30,
                md: 25,
                sm: 20,
                xs: 16,
              },
            }}
          >
            GESTIÓN DE ENCUESTA
          </Typography>

          <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />

          <Grid container spacing={2} sx={{ width: "100%" }}>
            <Grid
              item
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%" }}
            >
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    id="encuesta"
                    name="encuesta"
                    type="text"
                    label="Encuesta"
                    size="small"
                    value={encuesta}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PostAddIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    onChange={(e) => setEncuesta(e.target.value)}
                  />
                  <FormControl>
                    <InputLabel variant="standard" htmlFor="anio">
                      Tipo
                    </InputLabel>
                    <NativeSelect
                      defaultValue={0}
                      size="small"
                      inputProps={{
                        name: "tipo-encuesta",
                        id: "tipo-encuesta",
                      }}
                      onChange={(e) => setTipoEncuesta(e.target.value)}
                    >
                      {renderOptionsTipoEncuesta()}
                    </NativeSelect>
                  </FormControl>
                  <FormControl>
                    <InputLabel variant="standard" htmlFor="anio">
                      Año
                    </InputLabel>
                    <NativeSelect
                      defaultValue={0}
                      size="small"
                      inputProps={{
                        name: "anio",
                        id: "anio",
                      }}
                      onChange={(e) => setAnioEncuesta(e.target.value)}
                    >
                      {renderOptionsAnios()}
                    </NativeSelect>
                  </FormControl>
                  <Button
                    size="small"
                    variant="contained"
                    type="button"
                    onClick={createEncuesta}
                  >
                    Agregar
                  </Button>
                </Stack>
                <Alert severity="warning"><strong>Advertencia</strong>: Las encuestas solo pueden ser ejecutadas si se encuentran cerradas. Abrir la encuesta para edición solo es posible si no existen datos guardados de encuestados.</Alert>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ width: "100%" }}>
            <Grid
              item
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%" }}
            >
              <TableContainer sx={{ m: "auto", mt: "30px" }} component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Encuesta</TableCell>
                      <TableCell align="center">Tipo Encuesta</TableCell>
                      <TableCell align="center">Año</TableCell>
                      <TableCell align="center">Configuración</TableCell>
                      <TableCell align="center">Estado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {encuestaList.map((encuesta) => (
                      <TableRow
                        key={encuesta.id_encuesta}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" component="th" scope="row">
                          {encuesta.nombre}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {encuesta.TipoEncuestum.nombre}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {encuesta.anio}
                        </TableCell>
                        <TableCell align="center">
                          {encuesta.status && (
                            <Stack direction="row">
                              <Tooltip
                                TransitionComponent={Zoom}
                                title="Configurar"
                                placement="top"
                              >
                                <Link
                                  to={`/modulo/gopme/encuesta/${encuesta.id_encuesta}`}
                                >
                                  <IconButton aria-label="Configurar">
                                    <SettingsIcon />
                                  </IconButton>
                                </Link>
                              </Tooltip>
                              <Tooltip
                                TransitionComponent={Zoom}
                                title="Eliminar"
                                placement="top"
                              >
                                <IconButton
                                  aria-label="delete"
                                  onClick={() =>
                                    deleteEncuesta(encuesta.id_encuesta)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {encuesta.status ? (
                            <>
                              <Stack direction="row" spacing={1}>
                                <Alert severity="success">Encuesta abierta</Alert>
                                <Button variant="contained" size="small" color="error" onClick={() =>
                                  handleEstadoEncuesta(encuesta.id_encuesta, false)
                                } endIcon={<LockOpenIcon />} >Cerrar</Button>
                              </Stack>
                            </>
                          ) : (
                            <>
                              <Stack direction="row" spacing={1}>
                                <Alert severity="error">Encuesta cerrada</Alert>
                                <Button variant="contained" size="small" color="success" onClick={() =>
                                  handleEstadoEncuesta(encuesta.id_encuesta, true)
                                } endIcon={<LockIcon />} >Abrir</Button>
                              </Stack>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Encuesta;
