import React, { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const PlanificaEncuestaContext = createContext();
export const NOTIFICATIONTIME = process.env.REACT_APP_NOTIFICATIONTIME;

const API_ADMIN_PROTO = process.env.REACT_APP_API_ADMIN_PROTO;
const API_ADMIN_HOST = process.env.REACT_APP_API_ADMIN_HOST;
const API_ADMIN_PORT = process.env.REACT_APP_API_ADMIN_PORT;

const API_RRSS_PROTO = process.env.REACT_APP_API_RRSS_PROTO;
const API_RRSS_HOST = process.env.REACT_APP_API_RRSS_HOST;
const API_RRSS_PORT = process.env.REACT_APP_API_RRSS_PORT;

export const PlanificaEncuestaProvider = ({ children }) => {

    const enviarNotificacionEncuesta = async (id_planifica_encuesta, participante, metodo) => {
        const formnotificacionencuesta = {
            id_planifica_encuesta: id_planifica_encuesta,
            participante: participante,
            metodo: metodo,
        };
        switch (metodo) {
            case 'Email':
                await axios
                    .post(
                        `${API_RRSS_PROTO}://${API_RRSS_HOST}:${API_RRSS_PORT}/enviar_notificacion/`,
                        formnotificacionencuesta, { withCredentials: true }
                    )
                    .then((response) => {


                        if (response.data.success) {

                            toast.success(response.data.message, {
                                position: "bottom-left",
                                autoClose: NOTIFICATIONTIME,
                            });


                        } else {
                            toast.error(response.data.message, {
                                position: "bottom-left",
                                autoClose: NOTIFICATIONTIME,
                            });
                        }

                    })
                    .catch((error) => {
                        console.error("Error al enviar la solicitud POST", error);
                    });
                break;

                default:
                    toast.error("Método de notificación no detectada.", {
                        position: "bottom-left",
                        autoClose: NOTIFICATIONTIME,
                    });
                break;
        }

    };


    const createPlanificacionEncuesta = async (id_colegio, inicia_encuesta, finaliza_encuesta, id_encuesta) => {
        const formplanificaencuesta = {
            id_colegio: id_colegio,
            inicia_encuesta: inicia_encuesta,
            finaliza_encuesta: finaliza_encuesta,
            id_encuesta: id_encuesta,
        };

        await axios
            .post(
                `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/planifica_encuesta/`,
                formplanificaencuesta, { withCredentials: true }
            )
            .then((response) => {


                if (response.data.success) {

                    toast.success(response.data.message, {
                        position: "bottom-left",
                        autoClose: NOTIFICATIONTIME,
                    });
                    getPlanificacionEncuesta();

                } else {
                    toast.error(response.data.message, {
                        position: "bottom-left",
                        autoClose: NOTIFICATIONTIME,
                    });
                }

            })
            .catch((error) => {
                console.error("Error al enviar la solicitud POST", error);
            });
    };

    const [listCountParticipante, setListCountParticipante] = useState(null);
    const getInfoGeneralParticipantes = async (id_planifica_encuesta) => {
        const formplanificaencuesta = {
            id_planifica_encuesta: id_planifica_encuesta,
        };

        await axios
            .post(
                `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/planifica_encuesta/info_general_participantes`,
                formplanificaencuesta, { withCredentials: true }
            )
            .then((response) => {

                if (response.data.success) {
                    setListCountParticipante(response.data.countParticipante);
                }

            })
            .catch((error) => {
                console.error("Error al enviar la solicitud POST", error);
            });
    };

    const [allPlanificacionEncuesta, setAllPlanificacionEncuesta] = useState(null);
    const getPlanificacionEncuesta = async () => {

        await axios
            .get(
                `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/planifica_encuesta/`, { withCredentials: true }
            )
            .then((response) => {

                if (response.data.success) {
                    setAllPlanificacionEncuesta(response.data.planifica_encuesta);
                }

            })
            .catch((error) => {
                console.error("Error al enviar la solicitud POST", error);
            });
    };



    const contextValues = {
        createPlanificacionEncuesta,
        allPlanificacionEncuesta,
        getPlanificacionEncuesta,
        getInfoGeneralParticipantes,
        listCountParticipante,
        enviarNotificacionEncuesta,
    };

    return (
        <PlanificaEncuestaContext.Provider value={contextValues}>
            {children}
        </PlanificaEncuestaContext.Provider>
    );
};
