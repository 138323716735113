import React, { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const SigeContext = createContext();
export const NOTIFICATIONTIME = process.env.REACT_APP_NOTIFICATIONTIME;

const API_ADMIN_PROTO = process.env.REACT_APP_API_ADMIN_PROTO;
const API_ADMIN_HOST = process.env.REACT_APP_API_ADMIN_HOST;
const API_ADMIN_PORT = process.env.REACT_APP_API_ADMIN_PORT;

export const SigeProvider = ({ children }) => {
  /* BEGIN GET CARGOS */
  const [cargoList, setCargoList] = useState([]);
  const getCargos = async () => {
    try {
      const response = await axios.get(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/cargos`,{ withCredentials: true });
      if (response.data.success) {
        setCargoList(response.data.cargo);
        console.log(response.data.cargo);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /* END GET CARGOS */

  /* BEGIN GUARDA NOMINA BASADO EN EXCEL */
  const [encuestaList, setEncuestaList] = useState([]);

  const createNominaManual = async (id_encuesta, id_cliente_colegio, datos) => {
    const formNominaManual = {
      id_encuesta: id_encuesta,
      id_cliente_colegio: id_cliente_colegio,
      datos: datos,
    };
    console.log(formNominaManual);
    axios
      .post(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/sige_encuesta`, formNominaManual,{ withCredentials: true })
      .then((response) => {
        //setAnioEncuesta("");

        if (response.data.success) {
          toast.success(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        } else {
          toast.error(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        }
      })
      .catch((error) => {
        console.error("Error al enviar la solicitud POST", error);
      });
  };

  /* END GUARDA NOMINA BASADO EN EXCEL */

  const contextValues = {
    createNominaManual,
    getCargos,
    cargoList,
  };

  return (
    <SigeContext.Provider value={contextValues}>
      {children}
    </SigeContext.Provider>
  );
};
