import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
    RadioGroup,
    FormControlLabel,
    Radio,
    Divider,
    ThemeProvider,
    createTheme,
    makeStyles,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const greenBackground = {
    backgroundColor: "#87a900",
    borderRadius: "50%", // Para hacer que el fondo sea circular
    //padding: "8px", // Ajusta el tamaño del fondo según tus necesidades
    color: "white",
  };
  
  const whiteIcon = {
    color: "white",
  };

function BoxEncuesta({ titulo, pregunta, respuestaSi, respuestaNo, respuestaSN , radioSelected, setRadioSelected}) {

    const handleChange = (event) => {
        setRadioSelected(event.target.value);
        console.log(event.target.value);
    };

    const renderRadio = (value, label) => (
        <>
            <FormControlLabel
                key={value}
                value={value}
                control={
                    <Radio
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircleOutlineIcon style={greenBackground} />}
                    />
                }
                label={label}
                labelPlacement="end"
            />
            <Divider />
        </>
    );

    return (
        <>
            <Typography variant="h6" align="justify" sx={{
                mb: "21px",
                fontSize: {
                    lg: 20,
                    md: 20,
                    sm: 20,
                    xs: 20,
                },
            }} gutterBottom>
                {titulo}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 2,
                    //border: 1,
                    //borderColor: "primary.main",
                    //borderRadius: 2,
                    //boxShadow: 1,
                    width: "90%",
                    margin: "auto",
                    background: "white",
                    borderRadius: "10px",
                    pt: 5,
                    pb: 5,
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                }}
            >
                <Typography variant="body1" align="justify" sx={{
                    mb: "30px",
                    fontSize: {
                        lg: 16,
                        md: 16,
                        sm: 16,
                        xs: 16,
                    },
                }} gutterBottom>
                    {pregunta}
                </Typography>
                <Box sx={{ textAlign: "justify", width: "100%" }}>

                    <RadioGroup
                        name="custom-radio-group"
                        value={radioSelected}
                        onChange={handleChange}
                    >
                        {renderRadio("option1", respuestaSi)}
                        {renderRadio("option2", respuestaNo)}
                        {renderRadio("option3", respuestaSN)}
                    </RadioGroup>

                </Box>

            </Box>
        </>
    );
}

export default BoxEncuesta;
