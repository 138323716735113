import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import WorkIcon from "@mui/icons-material/Work";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const NOTIFICATIONTIME = process.env.REACT_APP_NOTIFICATIONTIME;

const API_ADMIN_PROTO = process.env.REACT_APP_API_ADMIN_PROTO;
const API_ADMIN_HOST = process.env.REACT_APP_API_ADMIN_HOST;
const API_ADMIN_PORT = process.env.REACT_APP_API_ADMIN_PORT;

const CargoCliente = () => {
  const [cargo, setCargo] = useState("");
  const [modcargo, setModCargo] = useState("");
  const [cargoList, setCargoList] = useState([]);
  const [editingCargoId, setEditingCargoId] = useState(null);

  useEffect(() => {
    getCargos();
  }, []);

  const getCargos = async () => {
    try {
      const response = await axios.get(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/cargos`,{ withCredentials: true });
      if (response.data.success) {
        setCargoList(response.data.cargo);
        console.log(response.data.cargo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createCargo = async () => {
    try {
      const response = await axios.post(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/cargos`, {
        cargo,
      },{ withCredentials: true });
      setCargo("");
      getCargos();
      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateCargo = async (id, cargo) => {
    try {
      const response = await axios.put(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/cargos/${id}`, {
        cargo,
      },{ withCredentials: true });

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
        setEditingCargoId(null);
        setModCargo(null);
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }

      getCargos();
    } catch (error) {
      console.error(error);
    }
  };

  const deleteCargo = async (id) => {
    try {
      const response = await axios.delete(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/cargos/${id}`,{ withCredentials: true });

      toast.success(response.data.message, {
        position: "bottom-left",
        autoClose: NOTIFICATIONTIME,
      });

      getCargos();
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    if (editingCargoId) {
      setModCargo(e.target.value);
      console.log(e.target.value);
    }
  };

  const handleClickEdit = (id) => {
    console.log("handleClickEdit: " + id);
    setEditingCargoId(id);
    cargoList.map((cargo) => {
      if (cargo.id == id) {
        console.log(cargo.cargo + " - " + cargo.id);
        setModCargo(cargo.cargo);
      }
    });
  };

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          background: "#ffffff",
          height: "auto",
          pt: "64px",
        }}
      >
        <Box sx={{ pt: "40px", pb: "40px", pl: "40px", mr: "40px" }}>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: {
                lg: 30,
                md: 25,
                sm: 20,
                xs: 16,
              },
            }}
          >
            AGREGAR NUEVO TIPO DE CARGO
          </Typography>

          <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />

          <Grid container spacing={2} sx={{ width: "100%" }}>
            <Grid
              item
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%" }}
            >
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Stack direction="column" spacing={2}>
                  <TextField
                    id="cargo"
                    name="cargo"
                    type="text"
                    label="Cargo"
                    size="small"
                    value={cargo}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <WorkIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    onChange={(e) => setCargo(e.target.value)}
                  />
                  <Button
                    size="small"
                    variant="contained"
                    type="button"
                    onClick={createCargo}
                  >
                    Ingresar
                  </Button>
                </Stack>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ width: "100%" }}>
            <Grid
              item
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%" }}
            >
              <TableContainer sx={{ m: "auto", mt: "30px" }} component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Cargo</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cargoList.map((cargo) => (
                      <TableRow
                        key={cargo.id_cargo}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {editingCargoId === cargo.id_cargo ? (
                            <TextField
                              margin="none"
                              size="small"
                              value={modcargo}
                              onBlur={(e) =>
                                updateCargo(cargo.id_cargo, e.target.value)
                              }
                              onChange={handleInputChange}
                            />
                          ) : (
                            cargo.cargo
                          )}
                        </TableCell>

                        <TableCell>
                          <Stack direction="row" spacing={1}>
                            {editingCargoId !== cargo.id_cargo && (
                              <Tooltip
                                TransitionComponent={Zoom}
                                title="Actualizar"
                                placement="top"
                              >
                                <IconButton
                                  aria-label="Actualizar"
                                  onClick={() =>
                                    handleClickEdit(cargo.id_cargo)
                                  }
                                >
                                  <EditNoteIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip
                              TransitionComponent={Zoom}
                              title="Eliminar"
                              placement="top"
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={() => deleteCargo(cargo.id_cargo)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default CargoCliente;
