import React, { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const IlumenContext = createContext();
export const NOTIFICATIONTIME = process.env.REACT_APP_NOTIFICATIONTIME;

const API_ADMIN_PROTO = process.env.REACT_APP_API_ADMIN_PROTO;
const API_ADMIN_HOST = process.env.REACT_APP_API_ADMIN_HOST;
const API_ADMIN_PORT = process.env.REACT_APP_API_ADMIN_PORT;

export const IlumenProvider = ({ children }) => {

  /* BEGIN GET CARGO */
  const [infoUsuarioIlumen, setInfoUsuarioIlumen] = useState([]);
  const [tipoEnsenanza, setTipoEnsenanza] = useState([]);
  const [infoGeneralDashboard, setInfoGeneralDashboard] = useState([]);

  const getUsuariosIlumen = async () => {
    try {
      const response = await axios.get(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/ilumen/usuarios`, { withCredentials: true });
      if (response.data.success) {
        setInfoUsuarioIlumen(response.data.usuarios);
        setTipoEnsenanza(response.data.tipo_ensenanza);
        //setCargoList(response.data.cargo);
        //console.log(response.data.cargo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cambiarEstadoIlumen = async (id_usuario, estado) => {
    const form = { id_usuario, estado };
    try {
      const response = await axios.post(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/ilumen/cambiar_estado`, form, { withCredentials: true });
      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getInfoGeneralDashboard = async () => {
    try {
      const response = await axios.get(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/ilumen/informacion_general_dashboard`, { withCredentials: true });
      if (response.data.success) {
        setInfoGeneralDashboard(response.data.info_general);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* END GET CARGO */


  const contextValues = {
    infoUsuarioIlumen,
    setInfoUsuarioIlumen,
    getUsuariosIlumen,
    cambiarEstadoIlumen,
    tipoEnsenanza,
    getInfoGeneralDashboard,
    infoGeneralDashboard
  };

  return (
    <IlumenContext.Provider value={contextValues}>
      {children}
    </IlumenContext.Provider>
  );
};
