import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@mui/material";
import React, { useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import { EncuestaContext } from "../contexts/EncuestaContext";

const TablaSubDimension = () => {
  const { subDimensionList, deleteSubDimension, idEncuesta } =
    useContext(EncuestaContext);

  const renderTableRownSubDimension = () => {
    let contenido = subDimensionList;
    const idEncuesta= contenido.id_encuesta;
    contenido = contenido.Dimensions;
    const rows = [];
    if (Object.keys(contenido).length > 0) {
      contenido.forEach((dimension) => {
        let subdimension = dimension.SubDimensions;
        subdimension.forEach((infosubdimension) => {
          rows.push(
            <TableRow
              key={infosubdimension.id_subdimension}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                {dimension.nombre}
              </TableCell>
              <TableCell component="th" scope="row">
                {infosubdimension.nombre}
              </TableCell>
              <TableCell>
                <Stack direction="row">
                  <Tooltip
                    TransitionComponent={Zoom}
                    title="Eliminar"
                    placement="top"
                  >
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        deleteSubDimension(
                          idEncuesta,
                          infosubdimension.id_subdimension
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
            </TableRow>
          );
        });
      });
    }
    return rows;
  };

  const rownSubDimension = renderTableRownSubDimension();

  return (
    <>
      <TableContainer sx={{ m: "auto", mt: "30px" }} component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Dimensión</TableCell>
              <TableCell>SubDimensión</TableCell>
              <TableCell>Configuración</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rownSubDimension}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TablaSubDimension;
