import React from "react";
import { Box, Container } from "@mui/material";
import AuthEncuesta from "./AuthEncuesta";
import { useParams } from "react-router-dom";
import IniciaEncuesta from "./IniciaEncuesta";
import imagenBackground from "../../assets/background-iphone.jpg";

export default function ContestarEncuesta() {
    const { id } = useParams();
    console.log(id)
    let EncuestaMode;
    switch (id) {
        case 'auth':
            EncuestaMode = <AuthEncuesta />;
            break;
        case 'init':
            EncuestaMode = <IniciaEncuesta />;
            break;
        default:
            EncuestaMode = <AuthEncuesta />;
    }
    return (
        <>
            <Container
                sx={{
                    boxShadow: 3,
                    background: "#fbfbfb",
                    height: '100vh',
                    backgroundImage: `url(${imagenBackground})`, // Utiliza la importación de la imagen aquí
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    padding: "20px",

                }}
            >
                <Box sx={{ pt: "40px" }}>
                    {EncuestaMode}
                </Box>
            </Container>
        </>
    );
}
