import { Box, Container, Grid, Typography } from "@mui/material";

export default function Footer() {
  return (
    <>
      <Container
        maxWidth="xxl"
        sx={{
          maxHeight: "55",
          position: "fixed",
          bottom: 0,
          background: "black",
          height: "55",
        }}
      >
        <Box sx={{ m: "5px", color: "#f3f3f3", }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              align="center"
              sx={{
                fontWeight: "bold",
                fontSize: {
                  lg: 9,
                  md: 9,
                  sm: 9,
                  xs: 9,
                },
              }}
            >
              © 2023 Laboratorio Khrono Dev
            </Typography>
          </Grid>
        
        </Box>
      </Container>
    </>
  );
}
