import React from 'react'
import ModulosBreadCrumbs from './Modulos/ModulosBreadCrumbs/ModulosBreadCrumbs';
import { Container, Grid } from '@mui/material';
import CardModules from '../components/Dashboard/CardModules/CardModules';
import HomeIcon from "@mui/icons-material/Home";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import logoAsistenciaEscolar from '../assets/modules/asistencia_escolar.png';
import logoNominaAlumno from '../assets/modules/modulo_nomina_alumno.png';
import logoAccesoIlumen from '../assets/modules/modulo_acceso_ilumen.png';

export default function AdmEstudiantil() {
    let menuItems = [
        {
          href: "/",
          text: "Home",
          componenteIcon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
        },
        {
          href: "/modulos",
          text: "Modulos",
          componenteIcon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
        },
        {
          href: "/modulo/ilumen",
          text: "iLumen",
          componenteIcon: <AutoStoriesIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
        },
      ];
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          background: "#ffffff",
          height: "auto",
          pt: "40px",
        }}
      >
        <ModulosBreadCrumbs menuItems={menuItems} />
        

        <Grid container spacing={2} sx={{ mb: "20px" }}>
          <CardModules
              index="1"
              link="/modulo/ilumen/nomina_alumno"
              title="NOMINA DE ALUMNO"
              image={logoNominaAlumno}
              color="#4e9dff"
              subcontenido="<b>Nomina de alumno</b>: Es una herramienta esencial para administrar de manera eficiente y centralizada la información de todos los estudiantes. Permite registrar y mantener actualizados los datos personales de los alumnos, como nombres, apellidos, direcciones, contactos de emergencia y otros detalles relevantes."
            />
            <CardModules
              index="2"
              link="/modulo/ilumen/asistencia_escolar"
              title="ASISTENCIA ESCOLAR"
              image={logoAsistenciaEscolar}
              color="#4e9dff"
              subcontenido="<b>Asistencia escolar</b>: Proporciona un sistema de registro y seguimiento de la asistencia de los estudiantes. Permite marcar y realizar un seguimiento de las ausencias, tardanzas y permisos, lo que facilita el control y la generación de informes relacionados con la asistencia escolar."
            />
            <CardModules
              index="3"
              link="/modulo/ilumen/acceso_ilumen"
              title="GESTIÓN CUENTA ILUMEN"
              image={logoAccesoIlumen}
              color="#4e9dff"
              subcontenido="<b>Gestión Cuenta Ilumen</b>: Permite habilitar o deshabilitar accesos a ilumen y notificar a los usuarios, tambien se asignan los tiempos de expiración del servicio."            />
        </Grid>
      </Container>
    </>
  )
}
