import React, { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const EncuestaContext = createContext();
export const NOTIFICATIONTIME = process.env.REACT_APP_NOTIFICATIONTIME;

const API_ADMIN_PROTO = process.env.REACT_APP_API_ADMIN_PROTO;
const API_ADMIN_HOST = process.env.REACT_APP_API_ADMIN_HOST;
const API_ADMIN_PORT = process.env.REACT_APP_API_ADMIN_PORT;

export const EncuestaProvider = ({ children }) => {
  const [idEncuesta, setIdEncuesta] = useState(null);

  /* BEGIN GET CARGO */
  const [cargoList, setCargoList] = useState([]);
  const getCargos = async () => {
    try {
      const response = await axios.get(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/cargos`, { withCredentials: true });
      if (response.data.success) {
        setCargoList(response.data.cargo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* END GET CARGO */

  /* BEGIN GET ENCUESTA */
  const [encuestaList, setEncuestaList] = useState([]);

  const getEncuestas = async () => {
    try {
      const response = await axios.get(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/encuestas`, { withCredentials: true });
      if (response.data.success) {
        setEncuestaList(response.data.encuestas);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* END GET ENCUESTA */

  /*BEGIN CREA DIMENSION */
  const [dimensionList, setDimensionList] = useState([]);
  const [dimension, setDimension] = useState("");

  const getDimensiones = async (id) => {
    try {
      const response = await axios.get(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/dimension/${id}`, { withCredentials: true });
      if (response.data.success) {
        setDimensionList(response.data.dimensiones.Dimensions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createDimension = async (encuestaId, dimension) => {
    const formDimension = { id_encuesta: encuestaId, nombre: dimension };

    axios
      .post(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/dimension`,
        formDimension, { withCredentials: true }
      )
      .then((response) => {
        //setAnioEncuesta("");

        if (response.data.success) {
          setDimension("");
          toast.success(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        } else {
          toast.error(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        }
        getDimensiones(idEncuesta);
      })
      .catch((error) => {
        console.error("Error al enviar la solicitud POST", error);
      });
  };

  const deleteDimension = async (id_dimension) => {
    try {
      const response = await axios.delete(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/dimension/${id_dimension}`, { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }

      getDimensiones(idEncuesta);
    } catch (error) {
      console.error(error);
    }
  };
  /*END CREA DIMENSION */

  /*BEGIN CREA SUBDIMENSION */
  const [subDimension, setSubDimension] = useState("");
  const [dimensionSelect, setDimensionSelect] = useState("");
  const [subDimensionList, setSubDimensionList] = useState({});

  const getSubDimensiones = async (id) => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/subdimension/${id}`, { withCredentials: true }
      );
      if (response.data.success) {
        setSubDimensionList(response.data.dimension);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createSubDimension = async (
    id_encuesta,
    id_dimension,
    subdimension
  ) => {
    const formSubDimension = {
      id_dimension: id_dimension,
      nombre: subdimension,
    };

    axios
      .post(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/subdimension`,
        formSubDimension, { withCredentials: true }
      )
      .then((response) => {
        //setAnioEncuesta("");

        if (response.data.success) {
          setSubDimension("");
          //setDimensionSelect("");
          toast.success(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        } else {
          toast.error(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        }
        getSubDimensiones(id_encuesta);
      })
      .catch((error) => {
        console.error("Error al enviar la solicitud POST", error);
      });
  };

  const deleteSubDimension = async (id_encuesta, id_subdimension) => {
    try {
      const response = await axios.delete(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/subdimension/${id_subdimension}`, { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }

      getSubDimensiones(id_encuesta);
    } catch (error) {
      console.error(error);
    }
  };

  /*BEGIN CREA N° PRACTICA */
  const [npractica, setnPractica] = useState("");
  const [subDimensionSelect, setSubDimensionSelect] = useState("");
  const [nPracticaList, setnPracticaList] = useState({});

  const getnPracticas = async (id) => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/npractica/${id}`, { withCredentials: true }
      );
      if (response.data.success) {
        setnPracticaList(response.data.dimension);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createNPractica = async (id_encuesta, id_subdimension, npractica) => {
    const formnpractica = {
      id_subdimension: id_subdimension,
      nombre: npractica,
    };

    axios
      .post(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/npractica`,
        formnpractica, { withCredentials: true }
      )
      .then((response) => {
        //setAnioEncuesta("");

        if (response.data.success) {
          setnPractica("");
          //setDimensionSelect("");
          toast.success(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        } else {
          toast.error(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        }
        getnPracticas(id_encuesta);
      })
      .catch((error) => {
        console.error("Error al enviar la solicitud POST", error);
      });
  };

  const deleteNPractica = async (id_encuesta, id_numero_practica) => {
    try {
      const response = await axios.delete(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/npractica/${id_numero_practica}`, { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }

      getnPracticas(id_encuesta);
    } catch (error) {
      console.error(error);
    }
  };

  /*END CREA N° PRACTICA */

  /*BEGIN CREA PRACTICA */
  const [practica, setPractica] = useState("");
  const [nPracticaSelect, setNPracticaSelect] = useState("");
  const [practicaSelect, setPracticaSelect] = useState("");
  const [practicaList, setPracticaList] = useState({});

  const getPracticas = async (id) => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/practica/${id}`, { withCredentials: true }
      );
      if (response.data.success) {
        setPracticaList(response.data.dimension);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createPractica = async (id_encuesta, id_numero_practica, practica) => {
    const formnpractica = {
      id_numero_practica: id_numero_practica,
      nombre: practica,
    };
    
    axios
      .post(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/practica`,
        formnpractica, { withCredentials: true }
      )
      .then((response) => {
        //setAnioEncuesta("");

        if (response.data.success) {
          setPractica("");
          //setDimensionSelect("");
          toast.success(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        } else {
          toast.error(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        }
        getPracticas(id_encuesta);
      })
      .catch((error) => {
        console.error("Error al enviar la solicitud POST", error);
      });
  };

  const setPracticaDesarrollo = async (id_desarrollo_encuesta, id_desarrollo) => {
    const formnpractica = {
      id_desarrollo: id_desarrollo,
    };

    try {
      const response = await axios.put(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/practica_desarrollo/${id_desarrollo_encuesta}`,
        formnpractica,
        { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });


      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }

    } catch (error) {
      console.error("Error al enviar la solicitud POST", error);
    }
  };


  const deletePractica = async (id_encuesta, id_practica) => {
    try {
      const response = await axios.delete(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/practica/${id_practica}`, { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }

      getPracticas(id_encuesta);
    } catch (error) {
      console.error(error);
    }
  };

  /*END CREA PRACTICA */

  /*BEGIN DESARROLLO */
  const [desarrollo, setDesarrollo] = useState("");
  const [desarrolloList, setDesarrolloList] = useState({});
  const [desarrolloSelect, setDesarrolloSelect] = useState("");

  const getNivelDesarrollo = async () => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/desarrollo/`, { withCredentials: true }
      );
      if (response.data.success) {
        setDesarrolloList(response.data.desarrollo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /*END DESARROLLO */

  /*BEGIN DESARROLLO ENCUESTA */
  const [desarrolloEncuesta, setDesarrolloEncuesta] = useState("");
  const [desarrolloEncuestaList, setDesarrolloEncuestaList] = useState({});
  //const [stateDesarrolloEncuesta, setStateDesarrolloEncuesta] = useState([]);

  const getDesarrolloEncuesta = async (id) => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/desarrollo_encuesta/${id}`, { withCredentials: true }
      );
      if (response.data.success) {
        setDesarrolloEncuestaList(response.data.desarrolloencuesta);
        let contenido = response.data.desarrolloencuesta;
        const idEncuesta = contenido.id_encuesta;
        const collectedData = [];
        if (Object.keys(contenido).length > 0) {
          contenido = contenido.Dimensions;
          contenido.forEach((dimension) => {
            let subdimension = dimension.SubDimensions;
            subdimension.forEach((infosubdimension) => {
              let numeropractica = infosubdimension.NumeroPracticas;
              numeropractica.forEach((infonumeropractica) => {
                let practica = infonumeropractica.Practicas;
                practica.forEach((infopractica) => {
                  let desarrolloencuesta = infopractica.DesarrolloEncuesta;
                  desarrolloencuesta.forEach((infodesarrolloencuesta) => {
                    collectedData.push({
                      idEncuesta: idEncuesta,
                      idDesarrolloEncuesta: infodesarrolloencuesta.id_desarrollo_encuesta,
                      nombreNumeroPractica: infonumeropractica.nombre,
                      nombrePractica: infopractica.nombre,
                      nombreDesarrollo: infodesarrolloencuesta.nombre,
                      idDesarrollo: infodesarrolloencuesta.id_desarrollo,
                      countPreguntas: countPreguntas(infodesarrolloencuesta.EncuestaPregunta),
                      nombreDimension: dimension.nombre,
                      nombreSubdimension: infosubdimension.nombre,
                    });

                  })
                })
              })
            })
          })

        }
        //setStateDesarrolloEncuesta(collectedData);

      }
    } catch (error) {
      console.error(error);
    }
  };

  function countPreguntas(objPregunta) {
    let cantidadPreguntas = 0;
    cantidadPreguntas = parseInt(objPregunta.length);
    return cantidadPreguntas;
  }

  const createDesarrolloEncuesta = async (
    id_encuesta,
    id_practica,
    desarrollo,
  ) => {
    const formdesarrollo = {
      id_practica: id_practica,
      nombre: desarrollo,
    };
    
    await axios
      .post(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/desarrollo_encuesta`,
        formdesarrollo, { withCredentials: true }
      )
      .then((response) => {
        //setAnioEncuesta("");

        if (response.data.success) {
          setDesarrolloEncuesta("");
          //setDimensionSelect("");
          toast.success(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
          
        } else {
          toast.error(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        }

      })
      .catch((error) => {
        console.error("Error al enviar la solicitud POST", error);
      });
  };

  const deleteDesarrolloEncuesta = async (
    id_encuesta,
    id_desarrollo_encuesta
  ) => {
    try {
      const response = await axios.delete(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/desarrollo_encuesta/${id_desarrollo_encuesta}`, { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }

      getPracticas(id_encuesta);
      getDesarrolloEncuesta(id_encuesta);
    } catch (error) {
      console.error(error);
    }
  };
  /*END DESARROLLO ENCUESTA */

  /*BEGIN PREGUNTA ENCUESTA */
  const [opcionRespuesta, setOpcionRespuesta] = useState("");
  const [opcionRespuestaList, setOpcionRespuestaList] = useState({});
  const [encuestaPreguntaList, setEncuestaPreguntaList] = useState({});
  const [pregunta, setPregunta] = useState("");

  const getOpcionRespuesta = async () => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/opcion_respuesta/`, { withCredentials: true }
      );
      if (response.data.success) {
        setOpcionRespuestaList(response.data.opcion_respuesta);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPreguntaEncuesta = async (id) => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/encuesta_pregunta/${id}`, { withCredentials: true }
      );
      if (response.data.success) {
        setEncuestaPreguntaList(response.data.pregunta);
        setAsignaCargoIdList(response.data.cargo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createPreguntaEncuesta = async (
    id_desarrollo_encuesta,
    id_encuesta_pregunta,
    id_opcion_respuesta,
    pregunta
  ) => {
    if (id_opcion_respuesta === "") {
      id_opcion_respuesta = null;
    }

    const formpregunta = {
      id_desarrollo_encuesta: id_desarrollo_encuesta,
      id_encuesta_pregunta: id_encuesta_pregunta,
      id_opcion_respuesta: parseInt(id_opcion_respuesta),
      pregunta: pregunta,
    };

    axios
      .post(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/encuesta_pregunta`,
        formpregunta, { withCredentials: true }
      )
      .then((response) => {
        if (response.data.success) {
          setPregunta("");
          toast.success(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        } else {
          toast.error(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        }
        getPreguntaEncuesta(id_desarrollo_encuesta);
        setPreguntaCondicional(null);
        const selectElement = document.getElementById(
          "select-pregunta-condicional-" + id_desarrollo_encuesta
        );
        const options = selectElement.options;
        for (let i = 0; i < options.length; i++) {
          if (options[i].value === "null") {
            options[i].selected = true;
            break;
          }
        }
      })
      .catch((error) => {
        console.error("Error al enviar la solicitud POST", error);
      });
  };

  const deletePreguntaEncuesta = async (
    id_desarrollo_encuesta,
    id_encuesta_pregunta
  ) => {
    try {
      const response = await axios.delete(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/encuesta_pregunta/${id_encuesta_pregunta}`, { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }

      getPreguntaEncuesta(id_desarrollo_encuesta);
      getDesarrolloEncuesta(idEncuesta);
    } catch (error) {
      console.error(error);
    }
  };

  /*END PREGUNTA ENCUESTA */

  /* BEGIN PREGUNTA CONDICIONAL */
  const [preguntaCondicional, setPreguntaCondicional] = useState("");
  const deletePreguntaCondicionalEncuesta = async (
    id_desarrollo_encuesta,
    id_encuesta_pregunta_condicional
  ) => {
    try {
      const response = await axios.delete(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/encuesta_pregunta_condicional/${id_encuesta_pregunta_condicional}`, { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }

      getPreguntaEncuesta(id_desarrollo_encuesta);
      getDesarrolloEncuesta(idEncuesta);
    } catch (error) {
      console.error(error);
    }
  };

  /* END PREGUNTA CONDICIONAL */

  /* BEGIN ASIGNAR CARGO A PREGUNTA ENCUESTA */
  const [asignaCargoIdList, setAsignaCargoIdList] = useState({});
  const getEstadoAsignaCargosId = async (id_cargo, id_encuesta_pregunta) => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/cargo_encuesta_pregunta/?id_cargo=${id_cargo}&id_encuesta_pregunta=${id_encuesta_pregunta}`, { withCredentials: true }
      );
      if (response.data.success) {
        //setAsignaCargoIdList(response.data.cargoencuestapregunta);
        return response.data.cargoencuestapregunta;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const asignaCargoPreguntaEncuesta = async (
    estado,
    id_cargo,
    id_encuesta_pregunta
  ) => {
    const formAsignacargo = {
      estado,
      id_cargo,
      id_encuesta_pregunta,
    };

    axios
      .post(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/cargo_encuesta_pregunta`,
        formAsignacargo, { withCredentials: true }
      )
      .then((response) => {
        if (response.data.success) {
          setPregunta("");
          toast.success(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        } else {
          toast.error(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        }
        //getPreguntaEncuesta(id_desarrollo_encuesta);
      })
      .catch((error) => {
        console.error("Error al enviar la solicitud POST", error);
      });
  };
  /* END ASIGNAR CARGO A PREGUNTA ENCUESTA */

  /* CONTROL DE ESTADO CERRADO DE ENCUESTA */
  const [estadoEncuesta, setEstadoEncuesta] = useState(false);
  const cerrarEncuesta = async (
    id_encuesta,
    estado
  ) => {
    const formCerrarEncuesta = {
      id_encuesta,
      estado
    };

    await axios
      .post(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/encuesta/cerrar`,
        formCerrarEncuesta, { withCredentials: true }
      )
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });

        } else {
          toast.error(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        }
        //getPreguntaEncuesta(id_desarrollo_encuesta);
      })
      .catch((error) => {
        console.error("Error al enviar la solicitud POST", error);
      });
  };


  const contextValues = {
    idEncuesta, // SET ENCUESTA
    getEncuestas,
    setIdEncuesta,
    cargoList,
    getCargos,
    encuestaList,
    setEncuestaList,
    dimensionList, // LISTA DIMENSION
    getDimensiones,
    dimension, // SET DIMENSION
    setDimension,
    createDimension, // FUNC DIMENSION
    deleteDimension,
    subDimension, // SET SUBDIMENSION
    setSubDimension,
    dimensionSelect,
    setDimensionSelect,
    getSubDimensiones,
    subDimensionList,
    setSubDimensionList,
    createSubDimension, // FUNC SUBDIMENSION
    deleteSubDimension,
    npractica, // FUNC NPRACTICA
    setnPractica,
    setSubDimensionSelect,
    subDimensionSelect,
    getnPracticas,
    createNPractica,
    nPracticaList,
    deleteNPractica,
    practica, // FUNC PRACTICA
    setPractica,
    nPracticaSelect,
    practicaList,
    setNPracticaSelect,
    createPractica,
    deletePractica,
    getPracticas,
    practicaSelect,
    setPracticaSelect,
    setPracticaDesarrollo,
    desarrollo, // FUNC DESARROLLO
    setDesarrollo,
    createDesarrolloEncuesta,
    getNivelDesarrollo,
    desarrolloList,
    setDesarrolloSelect,
    desarrolloEncuesta,
    setDesarrolloEncuesta,
    desarrolloEncuestaList,
    setDesarrolloEncuestaList,
    getDesarrolloEncuesta,
    deleteDesarrolloEncuesta,
    getOpcionRespuesta,
    opcionRespuestaList,
    setOpcionRespuesta,
    opcionRespuesta,
    createPreguntaEncuesta,
    pregunta,
    setPregunta,
    encuestaPreguntaList,
    getPreguntaEncuesta,
    deletePreguntaEncuesta,
    preguntaCondicional,
    setPreguntaCondicional,
    deletePreguntaCondicionalEncuesta,
    asignaCargoPreguntaEncuesta,
    getEstadoAsignaCargosId,
    asignaCargoIdList,
    cerrarEncuesta,
    estadoEncuesta,
    setEstadoEncuesta,

  };

  return (
    <EncuestaContext.Provider value={contextValues}>
      {children}
    </EncuestaContext.Provider>
  );
};
