import React, { useContext, useEffect, useState } from "react";
import * as XLSX from 'xlsx';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModulosBreadCrumbs from "./Modulos/ModulosBreadCrumbs/ModulosBreadCrumbs";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PostAddIcon from "@mui/icons-material/PostAdd";
import { MineducContext } from "../contexts/MineducContext";
import { nombreMes, obtenerDiasDelMes } from '../utils/utils'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  IconButton,
  InputLabel,
  NativeSelect,
  TableCell,
  TextField,
  Typography,
  styled
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PeopleIconIcon from "@mui/icons-material/People";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/RemoveCircle';

import { DataGrid } from "@mui/x-data-grid";

export const NOTIFICATIONTIME = process.env.REACT_APP_NOTIFICATIONTIME;

//#region Estilos 
const AsistenciaTableCell = styled(TableCell)({
  padding: '11px',
  textAlign: 'center',
});
const style = {
  backgroundColor: "#fff",
  width: "100%",
  maxWidth: "98%",
  maxHeight: "90vh",
  overflowY: "auto",
  padding: 2,
  border: "10px solid #d77b00",
};
//#endregion
//#region Funciones para renderizado de asistencias 
function calcularDiasDelMes(asistencias, mes, anio) {
  const ultimoDiaDelMes = new Date(anio, mes, 0).getDate(); // Último día del mes actual
  const diasDelMes = [];
  for (let i = 1; i <= ultimoDiaDelMes; i++) {
    diasDelMes.push(<AsistenciaTableCell>{asistencias[`${i}`]}</AsistenciaTableCell>);
  }
  return diasDelMes;
}
function calcularDias(mes, anio) {
  const ultimoDiaDelMes = new Date(anio, mes, 0).getDate(); // Último día del mes actual
  const dias = [];
  for (let i = 1; i <= ultimoDiaDelMes; i++) {
    dias.push(<AsistenciaTableCell sx={{ color: '#ffffff' }}>{i}</AsistenciaTableCell>);
  }
  return dias;
}

export default function AsistenciaCursoManual() {
  const { getAllColegios,
    infoAsistenciaAlumno,
    listAsistenciaAlumnos, getSessionAsistenciaAlumnosManual, getSessionEstadoSigeMineduc,
    infoAsistenciaCurso, listaColegios, setInfoAsistenciaAlumno, listAsistenciaCursos, setInfoAsistenciaCurso, getNominaAlumnos,
    getAsistenciaAlumnos, getAsistenciaCursos, getNominaAlumnosDB, listaSessionEstadoSigeMineduc, uploadAsistenciaAlumnoManual,
    responseUploadAsistenciaAlumnoManual, listaSessionAsistenciaAlumnosManual, getSessionAsistenciaAlumnosSigeMineduc, listaSessionAsistenciaAlumnosSigeMineduc } = useContext(MineducContext);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));

  // Inicialización de variables
  const fechaActual = new Date();
  const [diasMes, setDiasMes] = useState([]);
  const [excelFile, setExcelFile] = useState(false);
  const [typeError, setTypeError] = useState('¡Todavía no se ha seleccionado ningún archivo!');

  // submit state
  const [anio, setAnio] = useState(null);
  const [mes, setMes] = useState(null);
  const [colegio, setColegio] = useState(null);

  const [statusPreparedFile, setStatusPreparedFile] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [saveExcelData, setSaveExcelData] = useState(null);


  const [openViewUpload, setOpenViewUpload] = useState(null);
  const [openViewCursos, setOpenViewCursos] = useState(null);
  const [openViewAsistencia, setOpenViewAsistencia] = useState(null);
  const [statusLoading, setStatusLoading] = useState(null);
  const [sessionSeleccionada, setSessionSeleccionada] = useState({});
  const [sessionSeleccionadaTipoEnsenanza, setSessionSeleccionadaTipoEnsenanza] = useState([]);

  const [sessionRBD, setSessionRBD] = useState(null);
  const [sessionMes, setSessionMes] = useState(null);
  const [sessionAnio, setSessionAnio] = useState(null);
  const [sessionCursoLetra, setSessionCursoLetra] = useState(null);

  let menuItems = [
    {
      href: "/",
      text: "Home",
      componenteIcon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulos",
      text: "Modulos",
      componenteIcon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulo/ilumen",
      text: "iLumen",
      componenteIcon: <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulo/ilumen/asistencia_escolar",
      text: "Asistencia de alumno",
      componenteIcon: <AutoStoriesIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];


  const LoadingSpinner = () => {
    return (
      <Box
        sx={{
          position: "fixed", // Hace que el spinner sea absoluto en relación con la ventana del navegador
          top: "50%", // Centra verticalmente en la pantalla
          left: "50%", // Centra horizontalmente en la pantalla
          transform: "translate(-50%, -50%)", // Centra el contenido
          zIndex: 9999, // Establece una capa superior para que esté por delante de todos los componentes
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  const EstadoAsistencia = ({ estadoDias, dia }) => {
    dia = parseInt(dia);
    let estado = null;

    for (const forDia in estadoDias) {
      const estadoDia = estadoDias[forDia];
      if (parseInt(forDia) === dia) {
        estado = estadoDia;
      }
    }

    switch (estado) {
      case 0:
        return (
          <>
            <IconButton style={{ fontSize: '10px', color: '#ca252f' }}>
              <CancelIcon />
            </IconButton>
          </>
        );
        break;

      case 1:
        return (
          <>
            <IconButton style={{ fontSize: '10px', color: '#17984f' }}>
              <DoneIcon />
            </IconButton>
          </>
        );
        break;
      case 2:
        return (
          <>
            <IconButton style={{ fontSize: '10px', color: '#fec404' }}>
              <CloseIcon />
            </IconButton>
          </>
        );
        break;

      case 3:
        return (
          <>
            <IconButton style={{ fontSize: '10px', color: '#0765b2' }}>
              <RemoveIcon />
            </IconButton>
          </>
        );
        break;
      default:
        return (
          <>
            <IconButton>
              {estado}
            </IconButton>
          </>
        );

        break;
    }

  };

  function obtenerNombreDia(numeroDia) {
    const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    return diasSemana[numeroDia];
  }

  function capitalize(frase) {
    return frase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  function calcularDiasMesArray(anio, mes) {

    const primerDiaDelMes = new Date(anio, mes - 1, 1);
    const ultimoDiaDelMes = new Date(anio, mes, 0);

    const diasMes = [];

    for (let dia = primerDiaDelMes.getDate(); dia <= ultimoDiaDelMes.getDate(); dia++) {
      const fecha = new Date(anio, mes - 1, dia);

      const nombreDia = obtenerNombreDia(fecha.getDay());
      const nDia = nombreDia[0];

      diasMes.push({
        dia,
        nDia,
      });

    }

    return diasMes;
  }

  const renderAsistenciaAlumnos = () => {
    const tablas = [];
    const columns = [];
    const rows = [];

    columns.push(
      {
        field: "id",
        headerName: "ID",
        width: 30,
        editable: false,
      },
      {
        field: "alumnos",
        headerName: "Alumnos",
        width: 250,
        editable: false,
      },
    );

    const diasDelMes = calcularDiasMesArray(sessionAnio, sessionMes);
    const cantidadDeDiasMes = diasDelMes.map((valor, index) => {

      const field = `${valor.dia}`;
      const headerName = `${valor.nDia}-${valor.dia}`;

      return {
        field: field,
        headerName: headerName,
        width: 60,
        editable: false,
        renderCell: (params) => (
          <>
            <EstadoAsistencia estadoDias={params.row} dia={field} />
          </>
        ),
      };
    });

    columns.push(...cantidadDeDiasMes);

    let indexAlumno = 1;
    let resultado = {};
    if (Array.isArray(listAsistenciaAlumnos)) {
      listAsistenciaAlumnos.map((infoAlumno, index) => {

        infoAsistenciaAlumno.map((infoDiaAsistencia) => {
          if (infoDiaAsistencia.id_nomina_alumno === infoAlumno.id_nomina_alumno) {
            resultado[infoDiaAsistencia.dia] = infoDiaAsistencia.estado;
          }

        });


        rows.push(
          {
            id: indexAlumno,
            alumnos: `${capitalize(infoAlumno.ape_paterno)} ${capitalize(infoAlumno.ape_materno)} ${capitalize(infoAlumno.nombres)}`,
            ...resultado,
          }
        );

        indexAlumno = indexAlumno + 1;
      });
    }


    const cantidadPagination = 40;
    tablas.push(
      <>
        <DataGrid
          autoHeight
          autoWidth
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: cantidadPagination,
              },
            },
          }}
          pageSizeOptions={[cantidadPagination]}
          checkboxSelection
          disableRowSelectionOnClick
          rowHeight={30} // Ajusta el tamaño de las celdas
          columnHeaderHeight={40} // Ajusta el tamaño de las cabeceras
        />
      </>
    );

    return tablas;
  }

  const handleOpenSessionAsistenciaAlumno = async (tipo_ensenanza, rbd, mes, anio, codigo, grado, letra) => {

    setStatusLoading(true);
    setSessionCursoLetra(tipo_ensenanza);
    await getAsistenciaAlumnos(rbd, mes, anio, codigo, grado, letra);
    setStatusLoading(false);
    setOpenViewAsistencia(true);
  };

  const renderResultadoUpload = () => {
    let msg_salida = [];
    if (Array.isArray(responseUploadAsistenciaAlumnoManual)) {
      //console.log(responseUploadAsistenciaAlumnoManual);

      if (responseUploadAsistenciaAlumnoManual.updates != 0) {
        msg_salida.push(
          <>
            <Alert variant="filled" severity="info">
              Fueron Actualizados {responseUploadAsistenciaAlumnoManual.updates} Registros.
            </Alert>
          </>
        );

      }

    }

    return msg_salida;

  }

  const OptionCurso = ({ tipo_ensenanza, rbd, mes, anio, codigo, grado, letra, handleOpenSessionAsistenciaAlumno }) => {

    return (
      <>
        <IconButton
          aria-label="Ver Asistencia Alumnos"
          onClick={() =>
            handleOpenSessionAsistenciaAlumno(tipo_ensenanza, rbd, mes, anio, codigo, grado, letra)
          }
        >
          <PeopleIconIcon />
        </IconButton>
      </>
    );
  };

  const renderListaCursos = () => {
    const tablas = [];

    const columns = [];
    const rows = [];

    const resultArray = sessionSeleccionadaTipoEnsenanza.map((valor, index) => {
      const headerName = `${valor}`;

      if (!columns[index]) {
        columns[index] = [];
      }

      if (!rows[index]) {
        rows[index] = [];
      }

      columns[index].push(
        {
          field: "id",
          headerName: headerName,
          width: 350,
          editable: false,
        },
        {
          field: 'options',
          headerName: 'Detalle de curso',
          width: 300,
          editable: false,
          renderCell: (params) => (
            <>
              <OptionCurso
                tipo_ensenanza={params.row.id}
                rbd={sessionRBD}
                mes={sessionMes}
                anio={sessionAnio}
                codigo={params.row.codigo}
                grado={params.row.grado}
                letra={params.row.letra}
                handleOpenSessionAsistenciaAlumno={handleOpenSessionAsistenciaAlumno}
              />
            </>
          ),
        },
      );
      for (const cursoKey in sessionSeleccionada.cursos) {
        if (sessionSeleccionada.cursos.hasOwnProperty(cursoKey)) {
          const curso = sessionSeleccionada.cursos[cursoKey];
          if (valor === `${curso.codigo} ${curso.tipo_ensenanza}`) {

            rows[index].push(
              {
                id: `${curso.nombre_curso} ${curso.letra}`,
                codigo: curso.codigo,
                grado: curso.grado,
                letra: curso.letra,
              }

            );
          }
        }

        //
      }

    });
    const flattenedColumns = columns.flat();

    if (Array.isArray(columns)) {
      columns.map((sessionTipoEnsenanza, index) => {

        tablas.push(
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{ m: "auto" }}
          >
            <DataGrid
              autoHeight
              autoWidth
              rows={rows[index]}
              columns={columns[index]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 3,
                  },
                },
              }}
              pageSizeOptions={[3]}
              checkboxSelection
              disableRowSelectionOnClick
              rowHeight={30}
              columnHeaderHeight={40}
            />
          </Grid>
        );

      });

    }

    return <Grid container spacing={2} >{tablas}</Grid>;
  };

  const handleFile = (e) => {

    const arrayDiasMes = obtenerDiasDelMes(anio, parseInt(mes));

    if (!arrayDiasMes) {
      return toast.error('Por favor, ingresa un año y un mes válidos.', {
        position: "bottom-left",
        autoClose: NOTIFICATIONTIME,
      });

    }

    setDiasMes(arrayDiasMes);

    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];

    let selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name.toLowerCase();
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        if (fileName.includes('asistencia_alumno')) {
          setTipoAsistenciaSubida('asistencia_alumnos');
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const jsonData = []
            const asistenciasExcel = [];
            var nombreCurso;
            // recorrer las pestañas
            for (const sheetName of workbook.SheetNames) {
              // PREGUNTAR SI NOMBRE CURSO EXISTE EN LA BD
              if (sheetName.length > 24) {
                nombreCurso = sheetName.substring(0, 24);
              } else {
                nombreCurso = sheetName.substring(0, sheetName.length - 2);
              }
              const letra = sheetName.charAt(sheetName.length - 1);

              if (colegio === undefined && colegio === '' && colegio === null) {
                console.error('DEBE ELEGIR UN COLEGIO');
              }
              else if (nombreCurso === undefined && nombreCurso === '' && nombreCurso === null) {
                console.error('NOMBRE DE CURSO VACIO EN LA PESTAÑA EXCEL');
              }
              else {
                const worksheet = workbook.Sheets[sheetName];
                const cursoJSON = {}
                cursoJSON['nombreCurso'] = nombreCurso;
                cursoJSON['letra'] = letra;
                cursoJSON['asistencias'] = XLSX.utils.sheet_to_json(worksheet);
                const asistencias = cursoJSON['asistencias'];
                // Verificar que hay al menos un elemento en asistencias antes de recorrer
                if (asistencias && asistencias.length > 0) {

                  // Recorrer los alumnos
                  // recorrer todos elementos menos el primero y los ultimos 7.
                  for (let j = 1; j < asistencias.length - 7; j++) {
                    const fila = asistencias[j];
                    asistenciasExcel.push(fila);
                  }
                }
                jsonData.push(cursoJSON);
              }
            }
            setSaveExcelData(jsonData);
            setExcelData(asistenciasExcel);
            setExcelFile(selectedFile);
          };
        } else {
          setTypeError("¡El nombre del archivo no es válido! Debe contener 'asistencia_alumno'");
          setExcelFile(null);
        }
      } else {
        setTypeError("¡No se ha seleccionado un archivo válido!");
        setExcelFile(null);
      }
    } else {
      setTypeError("¡Todavía no se ha seleccionado ningún archivo!");
      setExcelFile(null);
    }
  };

  // submit event
  const handleGuardarSubmit = async (e) => {
    e.preventDefault();
    setOpenViewUpload(false);

    let selectedIdColegio = null;
    if (Array.isArray(listaColegios)) {
      listaColegios.map((infoColegio) => {

        if (parseInt(infoColegio.rbd) === parseInt(colegio)) {
          selectedIdColegio = infoColegio.id_colegio;
        }
      });

    }

    await uploadAsistenciaAlumnoManual(selectedIdColegio, mes, anio, excelFile);

    setOpenViewUpload(true);
  };

  // Inicialización de valores de entrada
  useEffect(() => {
    getAllColegios();
    getSessionAsistenciaAlumnosSigeMineduc();
    getSessionEstadoSigeMineduc();
    const intervalId = setInterval(() => {
      getSessionAsistenciaAlumnosSigeMineduc();
    }, 30000);


    return () => clearInterval(intervalId);
  }, [listAsistenciaAlumnos]);

  //#region RENDER SELECT
  /** Render select colegio */

  const renderOptionsColegio = () => {
    const options = [];

    options.push(
      <option key="" value="">
      </option>
    );

    if (Array.isArray(listaColegios)) {
      listaColegios.map((infoColegio) => {
        options.push(
          <option key={infoColegio.id_colegio} value={infoColegio.rbd}>
            {infoColegio.nombre}
          </option>
        );
      });
    }
    return options;
  };

  const renderOptionsAnio = () => {
    const actualAnio = new Date().getFullYear();
    const iniciaAnio = 2023;
    const years = Array.from(
      { length: actualAnio - iniciaAnio + 1 },
      (_, index) => iniciaAnio + index
    ).reverse();

    const options = [];

    options.push(
      <option key="" value="">
      </option>
    );

    years.map((anio) => {
      options.push(
        <option key={"anio-" + anio} value={anio}>
          {anio}
        </option>
      );
    });

    return options;
  };

  const renderOptionsMes = () => {
    const months = [
      { value: 1, label: "Enero" },
      { value: 2, label: "Febrero" },
      { value: 3, label: "Marzo" },
      { value: 4, label: "Abril" },
      { value: 5, label: "Mayo" },
      { value: 6, label: "Junio" },
      { value: 7, label: "Julio" },
      { value: 8, label: "Agosto" },
      { value: 9, label: "Septiembre" },
      { value: 10, label: "Octubre" },
      { value: 11, label: "Noviembre" },
      { value: 12, label: "Diciembre" },
    ];

    const options = [];

    options.push(
      <option key="" value="">
      </option>
    );

    months.map((month) => {
      options.push(
        <option key={"mes-" + month.value} value={month.value}>
          {month.label}
        </option>
      );
    });

    return options;
  };
  /** Render select tipo asistencia */
  const [tipoAsistencia, setTipoAsistencia] = useState('asistenciaAlumno');
  //#endregion



  //#region GENERAR TEMPLATES
  var nomina = [];

  const redCellStyle = {
    fill: {
      fgColor: { rgb: "FF8080" } // Rojo claro
    }
  };

  const handleExportAsistenciaAlumnoTemplate = async () => {
    try {

      let colegioObj = null;
      if (Array.isArray(listaColegios)) {
        listaColegios.map((infoColegio) => {
          if (parseInt(infoColegio.rbd) === parseInt(colegio)) {
            colegioObj = infoColegio;
          }
        });
      }

      if (anio === null || anio === "" || mes === null || mes === "") {
        return toast.error('Por favor, ingresa un año y un mes válidos.', {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }


      const dataDias = obtenerDiasDelMes(anio, parseInt(mes));
      if (!dataDias) {
        return toast.error('Por favor, ingresa un año y un mes válidos.', {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }

      setDiasMes(dataDias);
      const nombreDiasSemana = dataDias.map(dia => dia.nombreDia);
      const numerosDelMes = dataDias.map(dia => dia.fecha);

      const nombreDias = ['', '', '', '', '', ...nombreDiasSemana];
      const header = ['Curso', 'Letra', 'Ap. Paterno', 'Ap. Materno', 'Nombres', ...numerosDelMes
      /*,'PRESENTE', 'AUSENTE', 'NO MATRICULADO', 'DIAS NO TRABAJADOS', '% PRESENTE', '% AUSENTE', 'ESTADO'*/];

      const NOMINA = await getNominaAlumnosDB(colegioObj.rbd, anio)
      nomina = NOMINA;
      const cursos = await separarPorDescripcionYLetra(nomina);

      const wb = XLSX.utils.book_new();
      for (var nivel in cursos) {

        const paralelos = cursos[nivel];
        for (const curso in paralelos) {
          const alumnos = paralelos[curso]
          const ws = XLSX.utils.aoa_to_sheet([nombreDias, header]); // Inicializa ws aquí

          const rowAsistenciaAlumno = [];
          for (var i = 0; i < alumnos.length; i++) {
            let asistencias = [];
            for (let j = 0; j < nombreDiasSemana.length; j++) {
              // Si el valor del día es domingo o sábado, establece el valor como '-' y aplica el estilo rojo a la celda
              if (nombreDiasSemana[j] === 'Domingo' || nombreDiasSemana[j] === 'Sábado') {
                // Aplica el estilo rojo a la celda
                const cellAddress = XLSX.utils.encode_cell({ c: j + 4, r: i + 2 }); // j + 4 porque las primeras 4 columnas son para descripción, apellidos y nombres
                ws[cellAddress] = { v: '0', s: redCellStyle };
                asistencias.push(0);
              } else {
                asistencias.push(1);
              }
            }
            rowAsistenciaAlumno.push([
              `${alumnos[i].descripcion}`,
              `${alumnos[i].letra}`,
              `${alumnos[i].ape_paterno}`,
              `${alumnos[i].ape_materno}`,
              `${alumnos[i].nombres}`,
              ...asistencias
              //...diasMes.map(dia => (dia.dia=='Domingo' || dia.dia=='Sábado') ? '-' : '')
            ]);
          }
          rowAsistenciaAlumno.push(['']);
          rowAsistenciaAlumno.push(['']);
          //rowAsistenciaAlumno.push(['']);
          rowAsistenciaAlumno.push(['Recuerda que debes marcar la asistencia de la siguiente manera:']);
          rowAsistenciaAlumno.push(['', '0: ', 'Dia no trabajado']);
          rowAsistenciaAlumno.push(['', '1: ', 'Alumno asistió a clases']);
          rowAsistenciaAlumno.push(['', '2: ', 'Alumno no asistió a clases']);
          rowAsistenciaAlumno.push(['', '3: ', 'Alumno retirado']);

          XLSX.utils.sheet_add_aoa(ws, rowAsistenciaAlumno, { origin: -1 }); // Agrega las filas de asistencia al worksheet
          if (nivel.length > 23) { nivel = nivel.substring(0, 23); }
          XLSX.utils.book_append_sheet(wb, ws, `${nivel} ${curso}`);
        }
      }
      XLSX.writeFile(wb, 'asistencia_alumnos.xlsx', { bookType: 'xlsx', type: 'blob' });
    }
    catch (error) {
      console.error('Error en la exportación de asistencia de alumnos:\n', error);
    }
  };


  function getColegioByRBD(rbd) {
    let colegio = "";
    if (Array.isArray(listaColegios)) {
      listaColegios.map((infoColegio) => {
        if (infoColegio.rbd === rbd) {
          colegio = infoColegio.nombre;
        }
      });
    }

    return colegio;
  }

  const handleOpenCursosAsistencia = (rbd, mes, anio, id) => {

    setOpenViewAsistencia(false);
    setSessionRBD(rbd);
    setSessionMes(mes);
    setSessionAnio(anio);

    let seleccionadaTipoEnsenanza = [];
    setOpenViewCursos(false);
    if (Object.keys(listaSessionAsistenciaAlumnosSigeMineduc).length > 0) {
      for (const item of listaSessionAsistenciaAlumnosSigeMineduc) {
        if (item.id_session_asistencia === id) {
          setSessionSeleccionada(item);
          for (const infoCurso of item.cursos) {
            if (!seleccionadaTipoEnsenanza.includes(infoCurso.codigo + " " + infoCurso.tipo_ensenanza)) {
              seleccionadaTipoEnsenanza.push(infoCurso.codigo + " " + infoCurso.tipo_ensenanza);
            }

          }
          break;
        }
      }
    }
    setSessionSeleccionadaTipoEnsenanza(seleccionadaTipoEnsenanza);

    setOpenViewCursos(true);
  };

  const BotonVerAsistenciaCurso = ({ rbd, mes, anio, id_session_asistencia, handleOpenCursosAsistencia }) => {

    return (
      <>
        <IconButton
          aria-label="Ver Asistencia curso"
          onClick={() =>
            handleOpenCursosAsistencia(rbd, mes, anio, id_session_asistencia)
          }
        >
          <FormatListBulletedIcon />
        </IconButton>
      </>
    );
  };

  const renderTablaSesiones = () => {
    const tablaSession = [];
    const rowsTablaSession = [];

    const columns = [
      { field: 'id', headerName: 'ID', width: 30 },
      {
        field: 'colegio',
        headerName: 'Colegio',
        width: 200,
        editable: false,
      },
      {
        field: 'mes',
        headerName: 'Mes',
        width: 100,
        editable: false,
        renderCell: (params) => (
          <>
            {getMesByNumeroMes(params.row.mes)}
          </>
        ),
      },

      {
        field: 'anio',
        headerName: 'Año',
        width: 70,
        editable: false,
      },
      {
        field: 'estado',
        headerName: 'Estado',
        width: 200,
        editable: false,
      },
      {
        field: 'creado',
        headerName: 'Creado',
        width: 200,
        editable: false,
      },
      {
        field: 'actualizado',
        headerName: 'Actualizado',
        width: 200,
        editable: false,
      },
      {
        field: 'opciones',
        headerName: 'Opciones',
        width: 100,
        editable: false,
        renderCell: (params) => (
          <>
            <BotonVerAsistenciaCurso rbd={params.row.rbd} mes={params.row.mes} anio={params.row.anio} id_session_asistencia={params.id} handleOpenCursosAsistencia={handleOpenCursosAsistencia} />
          </>
        ),
      },

    ];

    const rows = [];

    if (Object.keys(listaSessionAsistenciaAlumnosSigeMineduc).length > 0) {
      listaSessionAsistenciaAlumnosSigeMineduc.forEach((item) => {
        let createdAtFecha = new Date(item.createdAt);
        let updatedAtFecha = new Date(item.updatedAt);
        let dias = calcularDias(item.mes, item.anio);
        //let infoColegio = getColegioByRBD(item.rbd);

        let objTablaSessionAsistenciaCurso = {
          id: item.id_session_asistencia,
          rbd: item.rbd,
          colegio: item.infocolegio.nombre,
          mes: item.mes,
          anio: item.anio,
          estado: getSessionEstadoByCode(item.estado),
          creado: createdAtFecha.toLocaleString(),
          actualizado: updatedAtFecha.toLocaleString(),
          //registros_almacenados: item.registrosAlmacenados,

        };

        rows.push(objTablaSessionAsistenciaCurso);


      });
    }
    tablaSession.push(
      <>
        <DataGrid
          autoHeight
          autoWidth
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 3,
              },
            },
          }}
          pageSizeOptions={[3]}
          checkboxSelection
          disableRowSelectionOnClick
          rowHeight={30}
          columnHeaderHeight={40}
        />
      </>
    );
    return tablaSession;
  };
  function getMesByNumeroMes(numeroMes) {
    let mes = "";
    const meses = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo",
      "Junio", "Julio", "Agosto", "Septiembre", "Octubre",
      "Noviembre", "Diciembre"];

    if (numeroMes >= 1 && numeroMes <= 12) {
      mes = meses[numeroMes];
    }

    return mes;
  };

  function getSessionEstadoByCode(code) {
    let descripcion = "";
    if (Object.keys(listaSessionEstadoSigeMineduc).length > 0) {
      listaSessionEstadoSigeMineduc.forEach((item) => {
        if (item.estado === code) {
          descripcion = item.descripcion;
        }
      });
    }
    return descripcion;
  };

  /** TIPO ASISTENCIA*/
  const [tipoAsistenciaSubida, setTipoAsistenciaSubida] = useState(null)

  function getSessionEstadoByCode(code) {
    let descripcion = "";
    if (Object.keys(listaSessionEstadoSigeMineduc).length > 0) {
      listaSessionEstadoSigeMineduc.forEach((item) => {
        if (item.estado === code) {
          descripcion = item.descripcion;
        }
      });
    }
    return descripcion;
  };

  const handleSetColegio = (value) => {
    setColegio(value);
  };


  const handleSetMes = (value) => {
    setMes(value);
  };

  const handleSetAnio = (value) => {
    setAnio(value);
  };

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          background: "#ffffff",
          height: "auto",
          pt: "40px",
        }}
      >
        <ModulosBreadCrumbs menuItems={menuItems} />
        <Box>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: {
                lg: 30,
                md: 25,
                sm: 20,
                xs: 20,
              },
            }}
          >
            Asistencia Alumno (Manual)
          </Typography>

          <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />
          <Box sx={{ textAlign: "justify", m: "auto", pb: "20px" }}>
            <Typography
              variant="a"
              sx={{
                fontSize: {
                  xl: 16,
                  lg: 15,
                  md: 14,
                  sm: 14,
                  xs: 14,
                },
              }}
            >
              Esta nómina permite obtener los datos completos de identificación de todos los alumnos, curso, run, nombres, domicilio, fecha de nacimiento, fecha de incorporación, fecha de retiro, % de asistencia
              Para importar la nómina de los alumnos desde Mineduc SIGE seleccione año y el colegio a importar, luego haga click en el botón "Importar asistencia Alumno (Automatizado)" , para importar de forma manual presione "Importar asistencia Alumno (Manual)".
            </Typography>
          </Box>




          <Grid container spacing={2}>
            <Grid
              item
              xl={3}
              lg={3}
              md={3}
              sm={3}
              xs={12}
              sx={{ m: "auto" }}
            >
              <FormControl fullWidth>
                <InputLabel htmlFor="colegio">
                  Colegio
                </InputLabel>
                <NativeSelect
                  defaultValue={colegio}
                  size="small"
                  onChange={(e) => handleSetColegio(e.target.value)}
                  inputProps={{ name: "colegio", id: "colegio" }}
                >
                  {listaColegios && renderOptionsColegio()}
                </NativeSelect>
              </FormControl>
            </Grid>

            <Grid item
              xl={3}
              lg={3}
              md={3}
              sm={3}
              xs={12}
              sx={{ m: "auto" }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="mes">
                  Mes
                </InputLabel>
                <NativeSelect
                  defaultValue={mes}
                  size="small"
                  onChange={(e) => handleSetMes(e.target.value)}
                  inputProps={{ name: "mes", id: "mes" }}
                >
                  {renderOptionsMes()}
                </NativeSelect>
              </FormControl>
            </Grid>

            <Grid item
              xl={3}
              lg={3}
              md={3}
              sm={3}
              xs={12}
              sx={{ m: "auto" }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="anio">
                  Año
                </InputLabel>
                <NativeSelect
                  defaultValue={anio}
                  size="small"
                  onChange={(e) => handleSetAnio(e.target.value)}
                  inputProps={{ name: "anio", id: "anio" }}
                >
                  {renderOptionsAnio()}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item
              xl={3}
              lg={3}
              md={3}
              sm={3}
              xs={12}
              sx={{ m: "auto" }}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                type="button"
                onClick={handleExportAsistenciaAlumnoTemplate}
                disabled={tipoAsistencia !== 'asistenciaAlumno' || !colegio || colegio === ''}
                style={{ paddingLeft: isXsScreen ? '0px' : '', width: '100%', height: '100%' }}
              >
                Descargar Excel Asistencia Alumno
              </Button>
            </Grid>

            <Grid item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              sx={{ m: "auto" }}>
              <TextField
                style={{ width: '100%' }}
                id="archivoasistencia"
                name="archivoasistencia"
                type="file"
                label="Archivo"
                size="small"
                variant="standard"
                onChange={handleFile}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PostAddIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              sx={{ m: "auto" }}>
              <Button size="small" variant="contained" type="button" onClick={handleGuardarSubmit} disabled={!excelFile} fullWidth>
                Guardar
              </Button>
            </Grid>
          </Grid>


          {typeError && <Alert severity="warning" sx={{}}> {typeError} </Alert>}


          <Grid container spacing={2}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%", marginTop: "30px" }}
            >
              {renderTablaSesiones()}
            </Grid>
          </Grid>
          <>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%" }}
            >
              {responseUploadAsistenciaAlumnoManual && openViewUpload && (
                <>
                  {renderResultadoUpload()}
                </>
              )}

              {openViewCursos && listaSessionAsistenciaAlumnosSigeMineduc && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ position: 'relative', backgroundColor: '#fff', zIndex: 1, textAlign: 'left', mt: "30px" }} >
                    <span>{getColegioByRBD(sessionRBD)}: Asistencia de alumnos {getMesByNumeroMes(sessionMes)} {sessionAnio} </span>
                  </Typography>
                  {renderListaCursos()}
                </>
              )}

              {openViewAsistencia && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ position: 'relative', backgroundColor: '#fff', zIndex: 1, textAlign: 'left' }}>
                    <span>{getColegioByRBD(sessionRBD)}: {sessionCursoLetra} / {getMesByNumeroMes(sessionMes)} {sessionAnio} </span>
                  </Typography>
                  {renderAsistenciaAlumnos()}
                </>
              )}



            </Grid>
          </>

        </Box >
      </Container >

    </>
  );
}
// Función para separar objetos según el valor de las claves 'descripcion' y 'letra'
async function separarPorDescripcionYLetra(lista) {
  var resultados = {};

  for (let i = 0; i < lista.length; i++) {
    var descripcion = lista[i].descripcion;
    var letra = lista[i].letra;

    if (!letra || letra === undefined || letra === '' || letra === null) {
      console.log('letra no válida para:');
      console.log(lista[i]);
    } else if (!descripcion || descripcion === undefined || descripcion === '' || descripcion === null) {
      console.log('descripcion no válida para:');
      console.log(lista[i]);
    } else {
      // Inicializa el objeto de descripción si aún no existe
      if (!resultados[descripcion]) {
        resultados[descripcion] = {};
      }
      // Inicializa el array de letra si aún no existe
      if (!resultados[descripcion][letra]) {
        resultados[descripcion][letra] = [];
      }
      resultados[descripcion][letra].push(lista[i]);
    }
  }

  return resultados;
}

/**
 * 1. Seleccionar tipo asistencia a ingresar
 * 1.1 asistencia curso
 * 1.2 asistencia alumno
 * 2. Descargar formato de excel
 * 3. Subir archivo
 * 4. Visualizar
 * 5. Almacenar datos
 */
