import React, { useState } from 'react';

const ProgressBar = ({ cantidadPreguntas, preguntaActual, onAvanzar, onRetroceder }) => {

  const progreso = (preguntaActual / cantidadPreguntas) * 100;
    return (
        <div style={{ width: '100%', background: "#b0b0b0" }}>
            <div style={{ width: '100%' }}>
                <div
                    style={{
                        width: `${progreso}%`,
                        height: '4px',
                        backgroundColor: '#459bae',
                        transition: 'width 0.3s ease-in-out',
                    }}
                ></div>
            </div>

        </div>
    );
};

export default ProgressBar;
