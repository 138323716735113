import React, { useState, useContext, useEffect, useRef } from "react";
import { Alert, Box, Button, Container, Divider, FormControl, Grid, IconButton, InputLabel, Modal, NativeSelect, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SourceIcon from "@mui/icons-material/Source";
import Groups2Icon from '@mui/icons-material/Groups2';
import GroupsIcon from '@mui/icons-material/Groups';
import MailIcon from '@mui/icons-material/Mail';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import ModulosBreadCrumbs from "../Modulos/ModulosBreadCrumbs/ModulosBreadCrumbs";
import { MineducContext } from "../../contexts/MineducContext";
import { EncuestaContext } from "../../contexts/EncuestaContext";
import { PlanificaEncuestaContext } from "../../contexts/PlanificaEncuestaContext";

import "./PlanificaEncuesta.css";
import moment from "moment";

let menuItems = [
    {
        href: "/",
        text: "Home",
        componenteIcon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
        href: "/modulos",
        text: "Modulos",
        componenteIcon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
        href: "/modulo/gopme",
        text: "goPME",
        componenteIcon: <SourceIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
];

const style = {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "98%",
    maxHeight: "90vh",
    overflowY: "auto",
    padding: 2,
    border: "10px solid #d77b00",
};

const styleBoxModal = {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "70%",
    maxHeight: "90vh",
    overflowY: "auto",
    padding: 2,
    border: "10px solid #d77b00",
};

export default function PlanificaEncuesta() {

    const {
        getEncuestas,
        encuestaList,
    } = useContext(EncuestaContext);

    const {
        createPlanificacionEncuesta,
        allPlanificacionEncuesta,
        getPlanificacionEncuesta,
        getInfoGeneralParticipantes,
        listCountParticipante,
        enviarNotificacionEncuesta,
    } = useContext(PlanificaEncuestaContext);

    const {
        getAllColegios,
        listaColegios,
    } = useContext(MineducContext);


    /* openmodal lista de participantes tabla con directivos alumnos etc */
    const [openModalParticipantes, setOpenModalParticipantes] = useState(null);
    const [statusLoading, setStatusLoading] = useState(null);
    const handleOpenModalParticipantes = (id) => {

        setStatusLoading(true);
        getInfoGeneralParticipantes(id);
        setTimeout(function () {
            setOpenModalParticipantes(id);
            setStatusLoading(false);

        }, 500);
    };

    const handleCloseModalParticipantes = () => {
        setOpenModalParticipantes(null);
    };

    /* carga de datos inicial */
    const [cargaCompleta, setCargaCompleta] = useState(false);
    useEffect(() => {
        const cargarDatos = async () => {
            try {
                await getAllColegios();
                await getEncuestas();
                await getPlanificacionEncuesta();
                setCargaCompleta(true);
            } catch (error) {
                console.error('Error al cargar datos:', error);
            }
        };

        cargarDatos();
    }, []);


    /* guardar planificacion */
    const handleGuardarPlanificacion = (id_colegio, inicia_encuesta, finaliza_encuesta, id_encuesta) => {

        createPlanificacionEncuesta(id_colegio, inicia_encuesta, finaliza_encuesta, id_encuesta);

    };

    const fechaActual = new Date();
    const anioActual = fechaActual.getFullYear();

    /* tabla que cuenta participantes */

    const styleIcon = {
        button: {
            alignItems: 'center',
        },
        number: {
            marginRight: '8px', // Espacio entre el icono y el número
            fontSize: '12px', // Ajusta el tamaño de fuente según tus necesidades
            fontWeight: 'bold',
            marginTop: '4px'
        },
    };

    /* set metodo notificacion seleccionada */
    const [metodoNotificacionSelects, setMetodoNotificacionSelects] = useState({});
    const handleMetodoNotificacionSelectChange = (e, participante) => {
        const newMetodoNotificacionSelects = { ...metodoNotificacionSelects };
        newMetodoNotificacionSelects[participante] = e.target.value;
        setMetodoNotificacionSelects(newMetodoNotificacionSelects);
    };

    /* enviar notificacion encuesta */
    const handleEnviarNoticacionEncuesta = (id_planifica_encuesta, participante, metodo) => {

        console.log("id_planifica_encuesta:" + id_planifica_encuesta)
        console.log("participante:" + participante)
        console.log("metodo:" + metodo)
        enviarNotificacionEncuesta(id_planifica_encuesta, participante, metodo);

    };

    const renderCountParticipantes = (id_planifica_encuesta) => {
        const tablaCountParticipantes = [];

        if (listCountParticipante) {
            let countOption = 0;
            for (const tipoParticipante in listCountParticipante) {
                countOption = countOption + 1;
                const infoCountParticipante = listCountParticipante[tipoParticipante];
                console.log(infoCountParticipante)
                tablaCountParticipantes.push(
                    <TableRow
                        key={"row-" + tipoParticipante}
                        sx={{
                            "&:last-child td, &:last-child th": {
                                border: 0,
                            },
                        }}
                    >
                        <TableCell align="center"><b>{tipoParticipante}</b></TableCell>
                        <TableCell align="center">
                            <IconButton aria-label="Telefonos" style={styleIcon.button}>
                                <span style={styleIcon.number}>{infoCountParticipante.telefono}</span>
                                <PhoneEnabledIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell align="center">
                            <IconButton aria-label="Emails" style={styleIcon.button}>
                                <span style={styleIcon.number}>{infoCountParticipante.email}</span>
                                <MailIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell align="center">
                            <IconButton aria-label="Participantes" style={styleIcon.button}>
                                <span style={styleIcon.number}>{infoCountParticipante.totalNomina}</span>
                                <GroupsIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell align="center">
                            <FormControl>
                                {
                                    (infoCountParticipante.email !== 0 || infoCountParticipante.telefono !== 0) && (
                                        <>
                                            <InputLabel variant="standard" htmlFor="modo">
                                                Metodo
                                            </InputLabel>
                                            <NativeSelect
                                                defaultValue={0}
                                                size="small"
                                                inputProps={{
                                                    name: `modo-notificacion-${countOption}`,
                                                    id: `modo-notificacion-${countOption}`,
                                                }}
                                                onChange={(e) => handleMetodoNotificacionSelectChange(e, tipoParticipante)}
                                                value={metodoNotificacionSelects[tipoParticipante] || undefined}
                                            >
                                                <option key={"option-notificacion-null" + countOption} value=""></option>
                                                {infoCountParticipante.email !== 0 && (
                                                    <option key={"option-notificacion-email" + countOption} value="Email">
                                                        Email
                                                    </option>
                                                )}
                                                {infoCountParticipante.telefono !== 0 && (
                                                    <option key={"option-notificacion-telefono" + countOption} value="Teléfono">
                                                        Teléfono
                                                    </option>
                                                )}

                                            </NativeSelect>
                                        </>
                                    )
                                }
                            </FormControl>
                        </TableCell>
                        <TableCell align="center">
                            0
                        </TableCell>
                        <TableCell align="center">
                            <Button variant="contained" size="small" color="success" onClick={() =>
                                handleEnviarNoticacionEncuesta(id_planifica_encuesta, tipoParticipante, metodoNotificacionSelects[tipoParticipante])
                            } >Enviar</Button>
                        </TableCell>
                    </TableRow>
                );
            }
        } else {
            console.log("No hay datos de countParticipante.");
        }

        return tablaCountParticipantes;
    }

    /* options encuestas disponibles */
    const renderEncuestasDisponibles = (rbd) => {
        const options = [];

        options.push(<option key={rbd + "-"} value=""></option>);
        if (Object.keys(encuestaList).length > 0) {
            encuestaList.forEach((encuesta) => {

                if (!encuesta.status) {
                    options.push(
                        <option key={rbd + "-" + encuesta.id_encuesta} value={encuesta.id_encuesta}>
                            {encuesta.nombre}
                        </option>
                    );
                }

            });
        }

        return options;
    };

    /* retorna encuesta */
    function getInfoEncuesta(id_encuesta) {
        let infoEncuesta;
        if (Object.keys(encuestaList).length > 0) {
            encuestaList.forEach((item) => {
                if (item.id_encuesta === id_encuesta) {
                    infoEncuesta = item;
                }
            });
        }
        return infoEncuesta;
    };

    /* paginador #1 */
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const rowsPerPageOptions = [10, 20, 50];
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    let slicedDataPlanificaEncuesta = [];

    if (listaColegios) {
        slicedDataPlanificaEncuesta = listaColegios.slice(startIndex, endIndex);
    };

    /* paginador #2 */
    const [pageNotificador, setPageNotificador] = useState(0);
    const [rowsPerPageNotificador, setRowsPerPageNotificador] = useState(10);

    const handleChangePageNotificador = (event, newPage) => {
        setPageNotificador(newPage);
    };

    const handleChangeRowsPerPageNotificador = (event) => {
        setRowsPerPageNotificador(parseInt(event.target.value, 10));
        setPageNotificador(0);
    };

    const rowsPerPageOptionsNotificador = [10, 20, 50];
    const startIndexNotificador = pageNotificador * rowsPerPageNotificador;
    const endIndexNotificador = startIndexNotificador + rowsPerPageNotificador;
    let slicedDataPlanificaEncuestaNotificador = [];

    if (allPlanificacionEncuesta) {
        slicedDataPlanificaEncuestaNotificador = allPlanificacionEncuesta.slice(startIndexNotificador, endIndexNotificador);
    };

    /* set encuestas seleccionada */
    const [encuestaSelects, setEncuestaSelects] = useState({});
    const handleEncuestaSelectChange = (e, id_colegio) => {
        const newEncuestaSelects = { ...encuestaSelects };
        newEncuestaSelects[id_colegio] = e.target.value;
        setEncuestaSelects(newEncuestaSelects);
    };

    /* set fecha inicial seleccionada */
    const [fechasIniciaPE, setFechasIniciaPE] = useState({});
    const handleChangeFechaInicial = (fecha, id_colegio) => {
        setFechasIniciaPE({
            ...fechasIniciaPE,
            [id_colegio]: fecha,
        });
    };

    /* set fecha finaliza seleccionada */
    const [fechasFinalizaPE, setFechasFinalizaPE] = useState({});
    const handleChangeFechaFinaliza = (fecha, id_colegio) => {
        setFechasFinalizaPE({
            ...fechasFinalizaPE,
            [id_colegio]: fecha,
        });
    };


    return (
        <>
            <Container
                maxWidth={false}
                sx={{
                    background: "#ffffff",
                    height: "auto",
                    pt: "40px",
                }}
            >
                <ModulosBreadCrumbs menuItems={menuItems} />
                <Box>
                    <Typography
                        gutterBottom
                        variant="h4"
                        component="div"
                        sx={{
                            fontWeight: "bold",
                            fontSize: {
                                lg: 30,
                                md: 25,
                                sm: 20,
                                xs: 16,
                            },
                        }}
                    >
                        PLANIFICAR ENCUESTA
                    </Typography>

                    <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />

                    <Grid container spacing={2} sx={{ width: "100%" }}>
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{ m: "auto", width: "100%" }}
                        >
                            <Box sx={{ width: "100%", m: "auto" }}>
                                <Typography
                                    gutterBottom
                                    variant="body1"
                                    component="div"
                                    align="justify"
                                    sx={{
                                        fontSize: {
                                            lg: 16,
                                            md: 16,
                                            sm: 16,
                                            xs: 16,
                                        },
                                    }}
                                >

                                    Inicie la planificación de la encuesta para la lista de colegios habilitados para utilizar la aplicación <strong>GoPME</strong>. En esta etapa, seleccione las fechas, horas de inicio y fin de la encuesta. Además, tiene la opción de elegir el tipo de encuesta habilitada para el año en curso.
                                </Typography>
                            </Box>

                            <TableContainer sx={{ m: "auto", mt: "30px" }} component={Paper}>

                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">RBD</TableCell>
                                            <TableCell align="center">Colegio</TableCell>
                                            <TableCell align="center">Sostenedor</TableCell>
                                            <TableCell align="center">Director</TableCell>
                                            <TableCell align="center">GoPME</TableCell>
                                            <TableCell align="center">Inicia Encuesta</TableCell>
                                            <TableCell align="center">Finaliza Encuesta</TableCell>
                                            <TableCell align="center">Encuestas Disponibles</TableCell>
                                            <TableCell align="center">&nbsp;</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cargaCompleta &&
                                            slicedDataPlanificaEncuesta.map((colegio) =>
                                                colegio.apps.map((colegioapp) => {
                                                    if (colegioapp.id_app === 1) {
                                                        return (
                                                            <TableRow
                                                                key={colegio.id_colegio}
                                                                sx={{
                                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                                }}
                                                            >
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {colegio.rbd}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {colegio.nombre}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {colegio.sostenedor}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {colegio.director}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {colegioapp.estado === true ? <Alert severity="success">Activo</Alert> : <Alert severity="warning">Inactivo</Alert>}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    <input
                                                                        className="datapicker"
                                                                        type="datetime-local"
                                                                        value={fechasIniciaPE[colegio.id_colegio] || ''}
                                                                        onChange={(e) => handleChangeFechaInicial(e.target.value, colegio.id_colegio)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    <input
                                                                        className="datapicker"
                                                                        type="datetime-local"
                                                                        value={fechasFinalizaPE[colegio.id_colegio] || ''}
                                                                        onChange={(e) => handleChangeFechaFinaliza(e.target.value, colegio.id_colegio)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    <FormControl>
                                                                        <InputLabel variant="standard" htmlFor="encuestas">
                                                                            Encuesta
                                                                        </InputLabel>
                                                                        <NativeSelect
                                                                            defaultValue={0}
                                                                            size="small"
                                                                            inputProps={{
                                                                                name: `encuesta-disponible-${colegio.id_colegio}`,
                                                                                id: `encuesta-disponible-${colegio.id_colegio}`,
                                                                            }}
                                                                            onChange={(e) => handleEncuestaSelectChange(e, colegio.id_colegio)}
                                                                            value={encuestaSelects[colegio.id_colegio] || undefined}
                                                                        >
                                                                            {renderEncuestasDisponibles(colegio.rbd)}
                                                                        </NativeSelect>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    <Button variant="contained" size="small" color="success" onClick={() =>
                                                                        handleGuardarPlanificacion(colegio.id_colegio, fechasIniciaPE[colegio.id_colegio], fechasFinalizaPE[colegio.id_colegio], encuestaSelects[colegio.id_colegio])
                                                                    } >Guardar</Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    } else {
                                                        return null; // No renderizar nada si la condición no se cumple
                                                    }
                                                })
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {cargaCompleta && (
                                <TablePagination
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    component="div"
                                    count={listaColegios.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{ m: "auto", width: "100%" }}
                        >
                            <Typography
                                gutterBottom
                                variant="h4"
                                component="div"
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: {
                                        lg: 30,
                                        md: 25,
                                        sm: 20,
                                        xs: 16,
                                    },
                                }}
                            >
                                GESTIONAR CAMPAÑA DE NOTIFICACIÓN
                            </Typography>

                            <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />

                            <Box sx={{ width: "100%", m: "auto" }}>
                                <Typography
                                    gutterBottom
                                    variant="body1"
                                    component="div"
                                    align="justify"
                                    sx={{
                                        fontSize: {
                                            lg: 16,
                                            md: 16,
                                            sm: 16,
                                            xs: 16,
                                        },
                                    }}
                                >

                                    La característica consiste en la configuración de la planificación de encuestas, lo que implica la creación de campañas de notificación dirigidas a subclientes, así como la capacidad de listar y gestionar a los participantes de la encuesta y definir los medios de notificación que están habilitados. Esto permite un control integral sobre cómo se ejecutan las encuestas, quiénes son los destinatarios y a través de qué canales se les notificará, ofreciendo una gestión eficiente de las interacciones encuesta-subcliente.
                                </Typography>
                            </Box>
                            <TableContainer sx={{ m: "auto", mt: "30px" }} component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">RBD</TableCell>
                                            <TableCell align="center">Colegio</TableCell>
                                            <TableCell align="center">Sostenedor</TableCell>
                                            <TableCell align="center">Director</TableCell>
                                            <TableCell align="center">GoPME</TableCell>
                                            <TableCell align="center">Inicia Encuesta</TableCell>
                                            <TableCell align="center">Finaliza Encuesta</TableCell>
                                            <TableCell align="center">Encuesta</TableCell>
                                            <TableCell align="center">Participantes</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cargaCompleta &&
                                            slicedDataPlanificaEncuestaNotificador.map((colegioNotificador) =>
                                                colegioNotificador.apps.map((colegioappNotificador) => {
                                                    if (colegioappNotificador.id_app === 1) {
                                                        const inicia_encuesta = colegioNotificador.inicia_encuesta;
                                                        const fecha_inicia_encuesta = moment(inicia_encuesta).format("DD/MM/YYYY H:mm:ss");

                                                        const finaliza_encuesta = colegioNotificador.finaliza_encuesta;
                                                        const fecha_finaliza_encuesta = moment(finaliza_encuesta).format("DD/MM/YYYY H:mm:ss");
                                                        //console.log(colegioNotificador)
                                                        return (
                                                            <TableRow
                                                                key={"notifica-plan-" + colegioNotificador.id_planifica_encuesta}
                                                                sx={{
                                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                                }}
                                                            >
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {colegioNotificador.colegio[0].rbd}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {colegioNotificador.colegio[0].nombre}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {colegioNotificador.colegio[0].sostenedor}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {colegioNotificador.colegio[0].director}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {colegioappNotificador.estado === true ? <Alert severity="success">Activo</Alert> : <Alert severity="warning">Inactivo</Alert>}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {fecha_inicia_encuesta}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {fecha_finaliza_encuesta}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">
                                                                    {getInfoEncuesta(colegioNotificador.id_encuesta).nombre}
                                                                </TableCell>
                                                                <TableCell align="center" component="th" scope="row">

                                                                    <IconButton aria-label="Participantes" onClick={() =>
                                                                        handleOpenModalParticipantes(colegioNotificador.id_planifica_encuesta)
                                                                    }>
                                                                        <Groups2Icon />
                                                                    </IconButton>

                                                                    <div className={`modal ${openModalParticipantes ? "fade-in" : "fade-out"}`}>
                                                                        <Modal
                                                                            key={"modal-pe-" + colegioNotificador.id_planifica_encuesta}
                                                                            open={openModalParticipantes === colegioNotificador.id_planifica_encuesta}
                                                                            onClose={handleCloseModalParticipantes}
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                            }}
                                                                        >
                                                                            {listCountParticipante ? (
                                                                                <Box sx={{ ...styleBoxModal }}>
                                                                                    <Typography
                                                                                        gutterBottom
                                                                                        variant="h4"
                                                                                        component="div"
                                                                                        align="center"
                                                                                        sx={{
                                                                                            fontWeight: "bold",
                                                                                            fontSize: {
                                                                                                lg: 30,
                                                                                                md: 25,
                                                                                                sm: 20,
                                                                                                xs: 16,
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        NOTIFICACIÓN A PARTICIPANTES
                                                                                    </Typography>

                                                                                    <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />

                                                                                    <Typography
                                                                                        gutterBottom
                                                                                        variant="body1"
                                                                                        component="div"
                                                                                        align="justify"
                                                                                        sx={{
                                                                                            fontSize: {
                                                                                                lg: 16,
                                                                                                md: 16,
                                                                                                sm: 16,
                                                                                                xs: 16,
                                                                                            },
                                                                                            mb: "30px"
                                                                                        }}
                                                                                    >

                                                                                        Seleccione los modos de notificación que desee administrar, incluyendo opciones como teléfono, correo electrónico o redes sociales. Personalice su elección para adaptarla a sus preferencias y necesidades de comunicación. Esta flexibilidad le permite aprovechar al máximo las distintas vías de contacto, asegurando que se mantenga conectado de la manera que mejor le convenga.
                                                                                    </Typography>
                                                                                    <Typography
                                                                                        gutterBottom
                                                                                        variant="body1"
                                                                                        component="div"
                                                                                        align="left"
                                                                                        sx={{
                                                                                            fontSize: {
                                                                                                lg: 16,
                                                                                                md: 16,
                                                                                                sm: 16,
                                                                                                xs: 16,
                                                                                            },
                                                                                            mb: "30px"
                                                                                        }}
                                                                                    >
                                                                                        El <strong>{colegioNotificador.colegio[0].nombre}</strong>, bajo la dirección del Sostenedor <strong>{colegioNotificador.colegio[0].sostenedor}</strong>, se prepara para llevar a cabo una  proceso <strong>Encuesta de Estándares Indicativos de Desempeños para Establecimientos Educativos y sus sostenedores</strong> para el año <strong>{getInfoEncuesta(colegioNotificador.id_encuesta).anio}</strong>. Esta encuesta tiene programado su inicio en la fecha <strong>{fecha_inicia_encuesta}</strong> y su finalización en la fecha <strong>{fecha_finaliza_encuesta}</strong>.

                                                                                    </Typography>

                                                                                    <Stack direction="column" spacing={2}>
                                                                                        <TableContainer sx={{ m: "auto" }} component={Paper}>
                                                                                            <Table size="small" aria-label="a dense table">
                                                                                                <TableHead>
                                                                                                    <TableRow>
                                                                                                        <TableCell align="center">Participante</TableCell>
                                                                                                        <TableCell align="center">Teléfono</TableCell>
                                                                                                        <TableCell align="center">Email</TableCell>
                                                                                                        <TableCell align="center">Total Nomina</TableCell>
                                                                                                        <TableCell align="center">Notificación</TableCell>
                                                                                                        <TableCell align="center">Enviadas</TableCell>
                                                                                                        <TableCell align="center">&nbsp;</TableCell>
                                                                                                    </TableRow>
                                                                                                </TableHead>
                                                                                                <TableBody>
                                                                                                    {renderCountParticipantes(colegioNotificador.id_planifica_encuesta)}
                                                                                                </TableBody>
                                                                                            </Table>
                                                                                        </TableContainer>
                                                                                    </Stack>
                                                                                </Box>
                                                                            ) : (
                                                                                <Alert severity="warning">
                                                                                    ¡Todavía no se ha cargado participantes!
                                                                                </Alert>
                                                                            )}
                                                                        </Modal>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    } else {
                                                        return null; // No renderizar nada si la condición no se cumple
                                                    }
                                                })
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {cargaCompleta && (
                                <TablePagination
                                    rowsPerPageOptions={rowsPerPageOptionsNotificador}
                                    component="div"
                                    count={allPlanificacionEncuesta.length}
                                    rowsPerPage={rowsPerPageNotificador}
                                    page={pageNotificador}
                                    onPageChange={handleChangePageNotificador}
                                    onRowsPerPageChange={handleChangeRowsPerPageNotificador}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Box mt={2}>&nbsp;</Box>
                </Box>
            </Container>
        </>
    );
}
