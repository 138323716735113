import React, { useContext, useEffect, useState } from "react";
import * as SheetJS from "xlsx";
import * as XLS from "xlsjs";
import ModulosBreadCrumbs from "./Modulos/ModulosBreadCrumbs/ModulosBreadCrumbs";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PostAddIcon from "@mui/icons-material/PostAdd";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  CircularProgress,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  NativeSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { MineducContext } from "../contexts/MineducContext";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DataGrid } from '@mui/x-data-grid';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const NOTIFICATIONTIME = process.env.REACT_APP_NOTIFICATIONTIME;

const iconv = require('iconv-lite');
//const XLSX = require('xlsjs');


export default function NominaAlumnoManual() {
  const {
    isLoadUseEffect,
    getAllColegios,
    listaColegios,

    uploadNominaAlumno,
    responseUploadNominaAlumnoManual,

    getSessionNominaAlumno,
    listaSessionNominaAlumno,

    getSessionEstadoSigeMineduc,
    listaSessionEstadoSigeMineduc,

    getNominaAlumnos,
    listNominaAlumnos,
    getAllTipoEnsenanza,
    listTipoEnsenanza,
  } = useContext(MineducContext);

  const [rows, setRows] = useState([]);
  const [rowsDB, setRowsDB] = useState([]);
  const [previewNomina, setPreviewNomina] = useState(false);
  const [previewNominaDB, setPreviewNominaDB] = useState(false);
  const [habilitaPreviewNomina, setHabilitaPreviewNomina] = useState(false);

  const [sessionRBD, setSessionRBD] = useState(null);
  const [sessionAnio, setSessionAnio] = useState(null);

  function getTipoEnsenanzaByCode(codigo, grado) {
    let descripcion = "";
    if (Object.keys(listTipoEnsenanza).length > 0) {
      listTipoEnsenanza.forEach((item) => {
        if (item.codigo === codigo && item.grado === grado) {
          descripcion = item.descripcion;
        }
      });
    }
    return descripcion;
  }
  
  function getColegioByRBD(rbd) {
    let colegio = "";
    if (Array.isArray(listaColegios)) {
      listaColegios.map((infoColegio) => {
        if (infoColegio.rbd === rbd) {
          colegio = infoColegio.nombre;
        }
      });
    }

    return colegio;
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'anio',
      headerName: 'Año',
      width: 60,
      editable: false,
    },
    {
      field: 'rbd',
      headerName: 'RBD',
      width: 60,
      editable: false,
    },
    {
      field: 'cod_tipo_ensenanza',
      headerName: 'Cod. Tipo Enseñanza',
      width: 70,
      editable: false,
    },
    {
      field: 'cod_grado',
      headerName: 'Cod. Grado',
      width: 70,
      editable: false,
    },
    {
      field: 'desc_grado',
      headerName: 'Desc. Grado',
      width: 90,
      editable: false,
    },
    {
      field: 'letra_curso',
      headerName: 'Letra',
      width: 60,
      editable: false,
    },
    {
      field: 'run',
      headerName: 'Run',
      width: 100,
      editable: false,
    },
    {
      field: 'digito_ver',
      headerName: 'Digito Ver.',
      width: 60,
      editable: false,
    },
    {
      field: 'genero',
      headerName: 'Genero',
      width: 60,
      editable: false,
    },
    {
      field: 'nombres',
      headerName: 'Nombres',
      width: 150,
      editable: false,
    },
    {
      field: 'ape_paterno',
      headerName: 'Apellido Paterno',
      width: 150,
      editable: false,
    },
    {
      field: 'ape_materno',
      headerName: 'Apellido Materno',
      width: 150,
      editable: false,
    },
    {
      field: 'direccion',
      headerName: 'Dirección',
      width: 90,
      editable: false,
    },
    {
      field: 'comuna',
      headerName: 'Comuna',
      width: 150,
      editable: false,
    },
    {
      field: 'cod_comuna',
      headerName: 'Cod. Comuna',
      width: 90,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 90,
      editable: false,
    },
    {
      field: 'telefono',
      headerName: 'Teléfono',
      width: 90,
      editable: false,
    },
    {
      field: 'celular',
      headerName: 'Celular',
      width: 90,
      editable: false,
    },
    {
      field: 'fecha_nac',
      headerName: 'Fecha Nacimiento',
      width: 90,
      editable: false,
    },
    {
      field: 'cod_etnia',
      headerName: 'Cod. Etnia',
      width: 90,
      editable: false,
    },
    {
      field: 'fecha_inc_curso',
      headerName: 'Fecha Ing. Curso',
      width: 90,
      editable: false,
    },
    {
      field: 'fecha_retiro',
      headerName: 'Fecha Ret. Curso',
      width: 90,
      editable: false,
    },
    {
      field: 'asistencia',
      headerName: 'Asistencia',
      width: 90,
      editable: false,
    },
    {
      field: 'promedio_final',
      headerName: 'Promedio Final',
      width: 90,
      editable: false,
    },
  ];


  //#region LECTURA, MODAL MUESTRA Y SUBIDA DE ARCHIVO
  //const [excelData, setExcelData] = useState(null);
  const [statusLoading, setStatusLoading] = useState(null);
  /** Lectura */
  const handleFile = async (e) => {

    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];

    if (colegio === undefined || colegio === '' || colegio === null || !colegio) {
      setTypeError("Debe seleccionar un colegio asociado a la nomina de subida");
      setExcelFile(null);
      //setExcelData(null);
      e.target.value = null;
      return;
    }

    //console.log(e.target.files[0].type);
    let selectedFile = e.target.files[0];
    if (!selectedFile) {
      setTypeError("¡Todavía no se ha seleccionado ningún archivo!");
      setExcelFile(null);
      //setExcelData(null);
      e.target.value = null;
      return;
    }
    if (!fileTypes.includes(selectedFile.type)) {
      setTypeError("¡No se ha seleccionado un archivo válido!");
      setExcelFile(null);
      //setExcelData(null);
      e.target.value = null;
      return;
    }

    setTypeError(null);
    let reader = new FileReader();
    // Manejo de errores en la lectura del archivo
    reader.onerror = (error) => {
      console.error('Error al leer el archivo:', error);
      setTypeError("¡Error al leer el archivo!");
      setExcelFile(null);
      //setExcelData(null);
      e.target.value = null;
    };
    reader.readAsArrayBuffer(selectedFile);

    const fileType = selectedFile.type;
    const fileName = selectedFile.name.toLowerCase();
    if (fileName.includes('nomina')) {
      reader.onload = async (e) => {
        var decodedText, utf8Text, workbook, sheetName, worksheet, options, currentSheetData, headersMatch;
        var data = e.target.result;

        setExcelFile(data);
        //const data = e.target.result;
        switch (fileType) {
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            try {
              data = e.target.result;
              workbook = SheetJS.read(data, { type: 'binary' });
              sheetName = workbook.SheetNames[0];
              worksheet = workbook.Sheets[sheetName];
              options = {
                defval: null,
                dateNF: ['yyyy-mm-dd', 'mm/dd/yyyy', 'dd/mm/yyyy'],
                header: 1,
                raw: false,
              };
              currentSheetData = SheetJS.utils.sheet_to_json(worksheet, options);
              //console.log(currentSheetData)
              currentSheetData = currentSheetData.filter(row => Object.values(row).some(cellValue => cellValue !== null));
              headersMatch = cabecerasNominaAlumnos.every(header => currentSheetData[0].includes(header));

              // Forzar un error aquí
              if (!headersMatch) {
                throw new Error('Las cabeceras no coinciden');
              }
              //console.log('salgo try1')
            }
            catch (error) {
              console.log(`catch en xlsx: ahora xls ${error}`);
              decodedText = iconv.decode(new Uint8Array(data), 'ISO-8859-1');
              utf8Text = iconv.encode(decodedText, 'UTF-8').toString();
              workbook = XLS.read(utf8Text, { type: 'string' });
              sheetName = workbook.SheetNames[0];
              worksheet = workbook.Sheets[sheetName];
              options = {
                defval: null,
                dateNF: ['yyyy-mm-dd', 'mm/dd/yyyy', 'dd/mm/yyyy'],
                header: 1,
                raw: false,
              };
              currentSheetData = XLS.utils.sheet_to_json(worksheet, options);
              currentSheetData = currentSheetData.filter(row => Object.values(row).some(cellValue => cellValue !== null));
              var headersMatch = cabecerasNominaAlumnos.every(header => currentSheetData[0].includes(header));
            }
            break;

          case 'application/vnd.ms-excel':
            try {
              decodedText = iconv.decode(new Uint8Array(data), 'ISO-8859-1');
              utf8Text = iconv.encode(decodedText, 'UTF-8').toString();
              workbook = XLS.read(utf8Text, { type: 'string' });
              sheetName = workbook.SheetNames[0];
              worksheet = workbook.Sheets[sheetName];
              options = {
                defval: null,
                dateNF: ['yyyy-mm-dd', 'mm/dd/yyyy', 'dd/mm/yyyy'],
                header: 1,
                raw: false,
                //range: 1
              };
              currentSheetData = XLS.utils.sheet_to_json(worksheet, options);
              currentSheetData = currentSheetData.filter(row => Object.values(row).some(cellValue => cellValue !== null));
              var headersMatch = cabecerasNominaAlumnos.every(header => currentSheetData[0].includes(header));
              // Forzar un error aquí
              if (!headersMatch) {
                throw new Error('Las cabeceras no coinciden');
              }
              //console.log('salgo try2')
            } catch (error) {
              //console.log(`catch en xls: ahora xlsx ${error}`)

              data = e.target.result;
              workbook = SheetJS.read(data, { type: 'binary' });

              sheetName = workbook.SheetNames[0];
              worksheet = workbook.Sheets[sheetName];
              options = {
                defval: null,
                dateNF: ['yyyy-mm-dd', 'mm/dd/yyyy', 'dd/mm/yyyy'],
                header: 1,
                raw: false,
                //range: 1
              };
              currentSheetData = SheetJS.utils.sheet_to_json(worksheet, options);
              currentSheetData = currentSheetData.filter(row => Object.values(row).some(cellValue => cellValue !== null));
              var headersMatch = cabecerasNominaAlumnos.every(header => currentSheetData[0].includes(header));
            }
            break;
        }
        var nominasJSON = {}
        // Filtrar filas que tienen al menos una celda no nula


        // Verificar si los encabezados coinciden con cabecerasNominaAlumnos
        console.table(cabecerasNominaAlumnos);
        console.table(currentSheetData[0]);

        if (!headersMatch) {
          console.error('Los encabezados no coinciden con cabecerasNominaAlumnos');
          setTypeError(`Los encabezados se encuentran mal tipados. Recuerda que los encabezados son: \n ${cabecerasNominaAlumnos}`);
          setExcelFile(null);
          //setExcelData(null);
          e.target.value = null;
          return;
        } else {
          for (let i = 0; i < currentSheetData.length; i++) {
            //console.table(currentSheetData[i])
            for (let j = 0; j < currentSheetData[i].length; j++) {
              if (currentSheetData[i][j]) {
                if (currentSheetData[i][j].includes('A񯼯th>RBD')) {
                  // Actualizar los valores en el arreglo
                  currentSheetData[i][j] = 'Año';
                  // Agregar la segunda parte ('RBD') en la siguiente celda (puedes ajustar esto según la estructura de tus datos)
                  currentSheetData[i].splice(j + 1, 0, 'RBD');
                }
                else if (currentSheetData[i][j].includes('Ense a')) {
                  currentSheetData[i][j] = currentSheetData[i][j].replace('Ense a', 'Enseñanza');
                }
                else if (currentSheetData[i][j].includes('C󤩧o')) {
                  currentSheetData[i][j] = currentSheetData[i][j].replace('C󤩧o', 'Código');
                }
                else if (currentSheetData[i][j].includes('Transici󮠨)')) {
                  currentSheetData[i][j] = currentSheetData[i][j].replace('Transici󮠨)', 'Transición ');
                }
                else if (currentSheetData[i][j].includes('Incorporaci󮠃urso')) {
                  currentSheetData[i][j] = currentSheetData[i][j].replace('Incorporaci󮠃urso', 'Incorporación curso');
                }
                else if (currentSheetData[i][j].includes('Direcci')) {
                  // Dividir la celda en 'Año' y 'RBD'
                  const [ano, rbd] = currentSheetData[i][j].split('th>Comuna Residencia');

                  // Actualizar los valores en el arreglo
                  currentSheetData[i][j] = 'Dirección';
                  // Agregar la segunda parte ('RBD') en la siguiente celda (puedes ajustar esto según la estructura de tus datos)
                  currentSheetData[i].splice(j + 1, 0, 'Comuna Residencia');
                }
                else if (currentSheetData[i][j].includes('࠮ivel ')) {
                  currentSheetData[i][j] = currentSheetData[i][j].replace('࠮ivel ', '° nivel ');
                }
              }
            }
            //console.table(currentSheetData[i])
            //await sleep(5000);
            // Convertir las fechas a objetos de fecha JavaScript
            // aquí también convertir los demás campos a iso/utf8
            if (currentSheetData[i][18] && currentSheetData[i][18] !== null) {
              const fecha18 = new Date(currentSheetData[i][18]);
              // Aumentar en uno el día
              fecha18.setDate(fecha18.getDate() + 1);
              // Actualizar los valores de las fechas en el arreglo
              currentSheetData[i][18] = formatDate(fecha18);
            }
            if (currentSheetData[i][20] && currentSheetData[i][20] !== null) {
              const fecha20 = new Date(currentSheetData[i][20]);
              // Aumentar en uno el día
              fecha20.setDate(fecha20.getDate() + 1);
              currentSheetData[i][20] = formatDate(fecha20);
            }
            if (currentSheetData[i][21] && currentSheetData[i][21] !== null) {
              const fecha21 = new Date(currentSheetData[i][21]);
              // Aumentar en uno el día
              fecha21.setDate(fecha21.getDate() + 1);
              currentSheetData[i][21] = formatDate(fecha21);
            }
          }
          // Asignar los datos de la hoja actual al array nominasJSON
          nominasJSON = currentSheetData.slice(1);
          //console.log(`largo: ${nominasJSON.length}`);
          if (nominasJSON.length < 1) {
            console.error('El archivo está vacío');
            setTypeError(`El archivo no tiene nominas, agrege alumnos a la nomina`);
            setExcelFile(null);
            //setExcelData(null);
            e.target.value = null;
            return
          }
        }
        //console.log(currentSheetData)
        setExcelFile(selectedFile)
        //setExcelData(nominasJSON);
        //setRows(nominasJSON);
        let indexNomina = 1;
        currentSheetData.shift();
        currentSheetData.forEach(nomina => {
          //console.log(nomina);

          let objTablaNominaAlumno = {
            id: indexNomina,
            anio: nomina[0],
            rbd: nomina[1],
            cod_tipo_ensenanza: nomina[2],
            cod_grado: nomina[3],
            desc_grado: nomina[4],
            letra_curso: nomina[5],
            run: nomina[6],
            digito_ver: nomina[7],
            genero: nomina[8],
            nombres: nomina[9],
            ape_paterno: nomina[10],
            ape_materno: nomina[11],
            direccion: nomina[12],
            comuna: nomina[13],
            cod_comuna: nomina[14],
            email: nomina[15],
            telefono: nomina[16],
            celular: nomina[17],
            fecha_nac: nomina[18],
            cod_etnia: nomina[19],
            fecha_inc_curso: nomina[20],
            fecha_retiro: nomina[21],
            asistencia: nomina[22],
            promedio_final: nomina[23],
          };
          console.log(objTablaNominaAlumno);
          setRows((prevRows) => [...prevRows, objTablaNominaAlumno]);
          indexNomina = indexNomina + 1;
        });
        setHabilitaPreviewNomina(true);

        //console.log(cabecerasNominaAlumnos)
      };
    } else {
      setTypeError("¡El nombre del archivo no es válido! Debe contener 'nomina'");
      setExcelFile(null);
      //setExcelData(null);
      e.target.value = null;
    }
  };

  /** PUP UP MODAL DE MUESTRA */
  const handleOpenNominaFileModal = () => {
    setPreviewNomina(true);
    setPreviewNominaDB(false);
  };

  /** GUARDADO */
  const handleGuardarSubmit = async (e) => {
    e.preventDefault();
    const selectedOption = listaColegios.find(unColegio => unColegio.nombre === colegio);
    const selectedKey = selectedOption ? selectedOption.id_colegio : null;
    await uploadNominaAlumno(selectedKey, anio, excelFile);
  };
  //#endregion LECTURA, MODAL MUESTRA Y SUBIDA DE ARCHIVO

  useEffect(() => {
    getAllColegios();
    getSessionNominaAlumno();
    getSessionEstadoSigeMineduc();
    getAllTipoEnsenanza();
    const intervalId = setInterval(() => {
      // Aquí puedes realizar la petición para obtener los nuevos datos de la tabla
      // setTableData(respuestaDeLaPeticion);
      getSessionNominaAlumno();
    }, 9000); // Actualiza la tabla cada 5 segundos (puedes ajustar el tiempo según tus necesidades)

    // Limpia el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, [listNominaAlumnos]);

  const handleColegioChange = async (e) => {
    setColegio(e.target.value);
    if (e.target.value === '') {
      setColegio('');
      //setExcelData(null);
      setExcelFile(null);
    }
  };
  const handleExportNominaAlumnoTemplate = async () => {
    try {
      // Crear una hoja de cálculo
      const ws = XLS.utils.aoa_to_sheet([cabecerasNominaAlumnos]);
      // Crear un libro
      const wb = XLS.utils.book_new();
      XLS.utils.book_append_sheet(wb, ws, 'Cabeceras');
      XLS.writeFile(wb, 'nomina_alumnos.xls', { bookType: 'xls', type: 'string' });
    }
    catch (error) {
      console.error('Error en la exportación de asistencia de alumnos:', error);
    }
  };

  //#region RENDERIZADOS  
  const cabecerasNominaAlumnos = [
    'Año', 'RBD', 'Cod Tipo Enseñanza', 'Cod Grado', 'Desc Grado', 'Letra Curso', 'Run', 'Dígito Ver.', 'Genero', 'Nombres', 'Apellido Paterno',
    'Apellido Materno', 'Dirección', 'Comuna Residencia', 'Código Comuna Residencia', 'Email', 'Telefono', 'Celular', 'Fecha Nacimiento',
    'Código Etnia', 'Fecha Incorporación Curso', 'Fecha Retiro', '%Asistenca', 'Promedio Final'
  ];
  /** Render select colegio */
  const [colegio, setColegio] = useState('');
  const renderOptionsColegios = () => {
    const options = [];
    options.push(<option key='default-0' value=''></option>)
    if (Array.isArray(listaColegios)) {
      listaColegios.map((infoColegio) => {
        options.push(
          <option key={`infoColegio_${infoColegio.id_colegio}`} value={infoColegio.nombre}>
            {`${infoColegio.nombre}`}
          </option>
        );
      });
    }
    return options;
  };
  /** Render select año */
  const fechaActual = new Date();
  const [anio, setAnio] = useState(fechaActual.getFullYear());
  const actualAnio = new Date().getFullYear();
  const iniciaAnio = 2009;

  const renderOptionsAnios = () => {
    const options = [];
    options.push(<option key="option-anio-0" value="    "></option>);
    for (let anio = actualAnio; anio >= iniciaAnio; anio--) {
      options.push(
        <option key={`option-anio-${anio}`} value={anio}>
          {anio}
        </option>
      );
    }

    return options;
  };

  const renderFila = (str, i) => {
    const patron = /cargo/gi;
    const tableCell = [];
    let colorCell = {
      backgroundColor: "#eb5050",
    };
    if (str === '' || !str || str === undefined) {
      tableCell.push(
        <TableCell component="th" scope="row" key={i} sx={{ backgroundColor: 'gray', textAlign: 'center' }}>
          {str}
        </TableCell>
      );
    } else {
      tableCell.push(
        <TableCell component="th" scope="row" key={i} sx={{ textAlign: 'center' }}>
          {str}
        </TableCell>
      );
    }

    return tableCell;
  };

  const BotonVerNominaAlumno = ({ rbd, anio, id_session_nomina_alumno, getNominaAlumnosAndUpdate }) => {

    return (
      <>
        <IconButton
          aria-label="Ver Nomina Alumnos"
          onClick={() =>
            getNominaAlumnosAndUpdate(rbd, anio, id_session_nomina_alumno)
          }
        >
          <VisibilityIcon />
        </IconButton>
      </>
    );
  };

  const renderTablaSesiones = () => {

    const columns = [
      { field: 'id', headerName: 'ID', width: 30 },
      {
        field: 'colegio',
        headerName: 'Colegio',
        width: 200,
        editable: false,
      },

      {
        field: 'anio',
        headerName: 'Año',
        width: 70,
        editable: false,
      },
      {
        field: 'estado',
        headerName: 'Estado',
        width: 200,
        editable: false,
      },
      {
        field: 'creado',
        headerName: 'Creado',
        width: 200,
        editable: false,
      },
      {
        field: 'actualizado',
        headerName: 'Actualizado',
        width: 200,
        editable: false,
      },
      {
        field: 'registros_almacenados',
        headerName: 'Registros Almacenados',
        width: 200,
        editable: false,
      },
      {
        field: 'opciones',
        headerName: 'Opciones',
        width: 100,
        editable: false,
        renderCell: (params) => (
          <>
            <BotonVerNominaAlumno rbd={params.row.rbd} anio={params.row.anio} id_session_nomina_alumno={params.id} getNominaAlumnosAndUpdate={getNominaAlumnosAndUpdate} />
          </>
        ),
      },

    ];

    const rows = [];

    const tablaSession = [];
    const rowsTablaSession = [];
    if (Object.keys(listaSessionNominaAlumno).length > 0) {
      listaSessionNominaAlumno.forEach((item) => {
        let createdAtFecha = new Date(item.createdAt);
        let updatedAtFecha = new Date(item.updatedAt);

        let objTablaSessionNominaAlumno = {
          id: item.id_session_nomina_alumno,
          rbd: item.rbd,
          colegio: item.infocolegio.nombre,
          anio: item.anio,
          estado: getSessionEstadoByCode(item.estado),
          creado: createdAtFecha.toLocaleString(),
          actualizado: updatedAtFecha.toLocaleString(),
          registros_almacenados: item.registrosAlmacenados,

        };

        rows.push(objTablaSessionNominaAlumno);
      });
    }
    tablaSession.push(
      <>
        <DataGrid
          autoHeight
          autoWidth
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 3,
              },
            },
          }}
          pageSizeOptions={[3]}
          checkboxSelection
          disableRowSelectionOnClick
          rowHeight={30}
          columnHeaderHeight={40}
        />

      </>
    );
    return tablaSession;
  };

  //#region SESION

  function getSessionEstadoByCode(code) {
    let descripcion = "";
    if (Object.keys(listaSessionEstadoSigeMineduc).length > 0) {
      listaSessionEstadoSigeMineduc.forEach((item) => {
        if (item.estado === code) {
          descripcion = item.descripcion;
        }
      });
    }
    return descripcion;
  };

  const handleOpenNominaSesion = async (rbd, anio, id, updatedListNominaAlumnos) => {
    setStatusLoading(true);
    setPreviewNominaDB(true);
    setPreviewNomina(false);
    setRowsDB([]);

    if (Array.isArray(updatedListNominaAlumnos)) {

      let indexNomina = 1;
      let toma_muestra_anio = null;
      updatedListNominaAlumnos.forEach(nomina => {
        console.log(nomina);
        if (indexNomina == 1) { toma_muestra_anio = nomina.anio; }

        let objTablaNominaAlumno = {
          id: indexNomina,
          anio: nomina.anio,
          rbd: nomina.rbd,
          cod_tipo_ensenanza: nomina.codigo,
          cod_grado: nomina.grado,
          desc_grado: getTipoEnsenanzaByCode(nomina.codigo, nomina.grado),
          letra_curso: nomina.letra,
          run: nomina.run,
          digito_ver: nomina.digito_ve,
          genero: nomina.genero,
          nombres: nomina.nombres,
          ape_paterno: nomina.ape_paterno,
          ape_materno: nomina.ape_materno,
          direccion: nomina.direccion,
          comuna: nomina.comuna_residencia,
          //cod_comuna: nomina[14],
          email: nomina.email,
          telefono: nomina.telefono,
          //celular: nomina[17],
          fecha_nac: nomina.fecha_nacimiento,
          cod_etnia: nomina.etnia,
          fecha_inc_curso: nomina.fecha_incorporacion_curso,
          fecha_retiro: nomina.fecha_retiro,
          //asistencia: nomina.asistencia_final,
          promedio_final: nomina.promedio_final,
        };

        setRowsDB((prevRowsDB) => [...prevRowsDB, objTablaNominaAlumno]);
        indexNomina = indexNomina + 1;
      });

      setStatusLoading(false);
      console.log("anio : " + anio + "toma_muestra_anio : " + toma_muestra_anio);
      if (anio != toma_muestra_anio) {
        toast.info("Por favor, intente nuevamente.", {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }

    } else {
      toast.info("Por favor, intente nuevamente.", {
        position: "bottom-left",
        autoClose: NOTIFICATIONTIME,
      });
      console.log('updatedListNominaAlumnos Vacio');
      setStatusLoading(false);
    }
  };

  const getNominaAlumnosAndUpdate = async (rbd, anio, id) => {
    setSessionRBD(rbd);
    setSessionAnio(anio);
    setPreviewNominaDB(false);
    setPreviewNomina(false);
    await getNominaAlumnos(rbd, anio);

    setTimeout(() => {
      handleOpenNominaSesion(rbd, anio, id, listNominaAlumnos);
    }, 2000); // Ajusta el tiempo según sea necesario
  };

  //#endregion SESION

  const LoadingSpinner = () => {
    return (
      <Box
        sx={{
          position: "fixed", // Hace que el spinner sea absoluto en relación con la ventana del navegador
          top: "50%", // Centra verticalmente en la pantalla
          left: "50%", // Centra horizontalmente en la pantalla
          transform: "translate(-50%, -50%)", // Centra el contenido
          zIndex: 9999, // Establece una capa superior para que esté por delante de todos los componentes
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  let menuItems = [
    {
      href: "/",
      text: "Home",
      componenteIcon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulos",
      text: "Modulos",
      componenteIcon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulo/ilumen",
      text: "iLumen",
      componenteIcon: <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulo/ilumen/nomina_alumno",
      text: "Nómina de alumno",
      componenteIcon: <AutoStoriesIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));

  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);
  return (
    <>
      <Container maxWidth={false} sx={{ background: "#ffffff", height: "auto", pt: "40px", pb: '40px' }}>
        <ModulosBreadCrumbs menuItems={menuItems} />

        <Box>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: {
                lg: 30,
                md: 25,
                sm: 20,
                xs: 16,
              },
            }}
          >
            Nómina de Alumno (Manual)
          </Typography>
          <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />
          <Box sx={{ textAlign: "justify", m: "auto", pb: "20px" }}>
            <Typography
              variant="a"
              sx={{
                fontSize: {
                  xl: 16,
                  lg: 15,
                  md: 14,
                  sm: 14,
                  xs: 14,
                },
              }}
            >
              Esta sección permite obtener los datos completos de identificación de los alumnos alumnos del colegio seleccionado(curso, run, nombres, domicilio, fecha de nacimiento, fecha de incorporación, fecha de retiro, % de asistencia, etc). Para cargar la nómina de los alumnos, seleccione el año y el colegio a importar. Luego, haga clic en el botón <strong>DESCARGA EXCEL NOMINA ALUMNO</strong>, una vez descargado el archivo base, debe completarlo y <strong>Seleccionar archivo</strong>, luego <strong>VISUALIZAR</strong> para verificar datos de nomina, luego <strong>GUARDAR</strong>.
            </Typography>
          </Box>
          {isLoadUseEffect ? (
            <>
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ m: "auto" }}
                >
                  <Box sx={{ "& > :not(style)": { m: 1 } }}>


                    <Grid container spacing={2} style={{ margin: '0px', width: 'auto' }}>

                      <Grid item xs={12} sm={4} style={{ paddingLeft: '0' }}>
                        <FormControl style={{ width: '100%' }}>

                          <InputLabel variant="standard" htmlFor="colegio">
                            Colegio
                          </InputLabel>

                          <NativeSelect
                            defaultValue={0}
                            size="small"
                            inputProps={{
                              name: "colegio",
                              id: "colegio",
                            }}
                            onChange={handleColegioChange}
                          >
                            {renderOptionsColegios()}
                          </NativeSelect>

                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4} style={{ paddingLeft: isXsScreen ? '0px' : '' }}>
                        <FormControl style={{ width: '100%' }}>

                          <InputLabel variant="standard" htmlFor="anio">
                            Año
                          </InputLabel>

                          <NativeSelect
                            defaultValue={anio}
                            size="small"
                            inputProps={{
                              name: "anio",
                              id: "anio",
                            }}
                            onChange={(e) => setAnio(e.target.value)}
                          >
                            {renderOptionsAnios()}
                          </NativeSelect>

                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4} style={{ paddingLeft: isXsScreen ? '0px' : '' }}>

                        <Button
                          size="small"
                          variant="contained"
                          type="button"
                          onClick={handleExportNominaAlumnoTemplate}
                          disabled={!colegio || colegio === ''}
                          style={{ paddingLeft: isXsScreen ? '0px' : '', height: '100%', width: '100%' }}
                        >
                          Descargar Excel Nomina Alumno
                        </Button>

                      </Grid>

                    </Grid>

                    <Grid container colums={12} spacing={2} style={{ margin: '0px', width: 'auto' }}>

                      <Grid item xs={12} sm={6} style={{ paddingLeft: '0' }}>

                        <TextField
                          id="archivonomina"
                          name="archivonomina"
                          type="file"
                          label="Archivo"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PostAddIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          onChange={handleFile}
                          style={{ width: '100%' }}
                          disabled={!colegio || !anio}
                        />

                      </Grid>

                      <Grid item xs={12} sm={3} style={{ paddingLeft: isXsScreen ? '0px' : '' }}>

                        <Button
                          size="small"
                          variant="contained"
                          type="button"
                          onClick={handleOpenNominaFileModal}
                          style={{ height: '100%', width: '100%' }}
                          disabled={!habilitaPreviewNomina}
                        >
                          Visualizar
                        </Button>

                      </Grid>

                      <Grid item xs={12} sm={3} style={{ paddingLeft: isXsScreen ? '0px' : '' }}>

                        <Button
                          size="small"
                          variant="contained"
                          type="button"
                          onClick={handleGuardarSubmit}
                          style={{ height: '100%', width: '100%' }}
                          disabled={!habilitaPreviewNomina}
                        >
                          Guardar
                        </Button>

                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <LoadingSpinner />
          )}

          <Grid container spacing={2} sx={{ width: "100%" }}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%", marginTop: "30px" }}
            >
              {renderTablaSesiones()}
            </Grid>
            {previewNomina && (
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{ m: "auto", width: "100%" }}
              >
                {rows && rows.length > 0 && (
                  <>
                    <Typography variant="h6" gutterBottom sx={{ position: 'relative', backgroundColor: '#fff', zIndex: 1, textAlign: 'left' }}>
                      <span>{getColegioByRBD(sessionRBD)}: Archivo Nomina de alumnos {sessionAnio} </span>
                    </Typography>

                    <DataGrid
                      autoHeight
                      autoWidth
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10]}
                      checkboxSelection
                      disableRowSelectionOnClick
                    />

                  </>
                )}
              </Grid>
            )}

            {previewNominaDB && (
              <>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ m: "auto", width: "100%" }}
                >
                  {rowsDB && rowsDB.length > 0 && (
                    <>
                      <Typography variant="h6" gutterBottom sx={{ position: 'relative', backgroundColor: '#fff', zIndex: 1, textAlign: 'left' }}>
                        <span>{getColegioByRBD(sessionRBD)}: Nomina de alumnos {sessionAnio} </span>
                      </Typography>

                      <DataGrid
                        autoHeight
                        autoWidth
                        rows={rowsDB}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowHeight={30}
                        columnHeaderHeight={40}
                      />

                    </>
                  )}
                </Grid>
              </>
            )}
          </Grid>

        </Box>

        {/** MOSTRAR MENSAJE ERROR EN LA LECTURA DEL ARCHIVO */}
        {typeError && (
          <div className="alert alert-danger" role="alert">
            {typeError.split('\n').map((line, index) => (
              <div key={`alert-error-${index}`}>{`${index} - ${line}`}</div>
            ))}
          </div>)
        }

        {/** MOSTRAR ERRORES RETORNADOS POR LA API AL GUARDAR LOS DATOS DEL ARCHIVOS */}
        <Container sx={{ mt: '40px' }}>
          {
            (responseUploadNominaAlumnoManual && responseUploadNominaAlumnoManual.no_validos && responseUploadNominaAlumnoManual.no_validos.length > 0)
              ?
              <Accordion sx={{}}>

                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      fontSize: {
                        xl: 16,
                        lg: 15,
                        md: 14,
                        sm: 14,
                        xs: 14,
                      },
                    }}
                  >
                    Datos no subidos
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Container sx={{}}>
                    {
                      responseUploadNominaAlumnoManual.no_validos.map((index, data) => (
                        <Alert severity="error">{`${index} - La inserción del alumno con run ${data['run']} ha generado el siguiente error: ${data['error']['errors'][0]['message']}`}</Alert>
                      ))
                    }
                    { /*responseUploadNominaAlumnoManual.repetidos &&
                    responseUploadNominaAlumnoManual.repetidos.length >= 0 &&
                    responseUploadNominaAlumnoManual.repetidos.map((run, index) => (
                      <Alert severity="error">{`${index} - La inserción del alumno con run ${run} ya se encuentra insertado en la base de datos`}</Alert>
                    ))
                  */}
                  </Container>
                </AccordionDetails>
              </Accordion>
              : ''
          }
          {
            (responseUploadNominaAlumnoManual && responseUploadNominaAlumnoManual.actualizados && responseUploadNominaAlumnoManual.actualizados.length > 0)
              ?
              <Accordion sx={{}}>

                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      fontSize: {
                        xl: 16,
                        lg: 15,
                        md: 14,
                        sm: 14,
                        xs: 14,
                      },
                    }}
                  >
                    Datos actualizados
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Container sx={{}}>
                    {responseUploadNominaAlumnoManual.actualizados.map((data, index) => (
                      <Alert severity="success">{`${index} - La actualización del alumno con run ${data} se ha realizado correctamente`}</Alert>
                    ))}
                  </Container>
                </AccordionDetails>
              </Accordion>
              : ''
          }
        </Container>

      </Container>
    </>
  );
}

// Función para formatear la fecha a 'DD-MM-YYYY'
function formatDate(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}