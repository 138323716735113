import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./CardModules.css";

export default function CardModules(props) {
  const index = props.index;
  const link = props.link;
  const title = props.title;
  const image = props.image;
  const subcontenido = props.subcontenido;
  return (
    <>
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Card sx={{ boxShadow: 3 }}>
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "center", pb: "5px" }}>
              <Avatar
                sx={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexShrink: 0,
                  width: "60px",
                  height: "60px",
                  fontFamily:
                    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
                  lineHeight: 1,
                  borderRadius: "0px",
                  overflow: "hidden",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
                src={image}
              ></Avatar>
            </Box>
            <Typography
              sx={{
                fontFamily: '"Plus Jakarta Sans", sans-serif',
                fontWeight: "700",
                fontSize: "1.5rem",
                lineHeight: "1.2",
                textAlign: "center",
              }}
            >
              <Link className="clean-link" to={link}>{title}</Link>
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                align="justify"
                sx={{
                  fontSize: "0.8rem",
                  color: "#494c52",
                  mt: "10px",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: subcontenido }} />
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
