import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  NativeSelect,
  Stack,
  TextField,
} from "@mui/material";
import { emphasize, styled } from "@mui/material/styles";

import "react-toastify/dist/ReactToastify.css";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./TabsEncuesta.css";

import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

import { EncuestaContext } from "../../contexts/EncuestaContext";
import TablaSubDimension from "../TablaSubDimension";
import TablaDimension from "../TablaDimension";
import TablanPractica from "../TablanPractica";
import TablaPractica from "../TablaPractica";
import TablaDesarrollo from "../TablaDesarrollo/TablaDesarrollo";

import HomeIcon from "@mui/icons-material/Home";
import SourceIcon from "@mui/icons-material/Source";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ModulosBreadCrumbs from "../../views/Modulos/ModulosBreadCrumbs/ModulosBreadCrumbs";
import { Margin, RestaurantMenu } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function TabsEncuesta(props) {
  const {
    setIdEncuesta,
    dimensionList,
    getDimensiones,
    dimension,
    setDimension,
    createDimension,
    deleteDimension,
    subDimension,
    setSubDimension,
    createSubDimension,
    dimensionSelect,
    setDimensionSelect,
    getSubDimensiones,
    subDimensionList,
    npractica,
    setnPractica,
    setSubDimensionSelect,
    subDimensionSelect,
    createNPractica,
    nPracticaList,
    getnPracticas,
    practica,
    setPractica,
    nPracticaSelect,
    setNPracticaSelect,
    getPracticas,
    practicaList,
    createPractica,
    setPracticaSelect,
    practicaSelect,
    desarrollo, // FUNC DESARROLLO
    setDesarrollo,
    createDesarrollo,
    getNivelDesarrollo,
    createDesarrolloEncuesta,
    desarrolloEncuesta,
    setDesarrolloEncuesta,
    getDesarrolloEncuesta,
    getOpcionRespuesta,
    desarrolloEncuestaList,
  } = useContext(EncuestaContext);

  const encuestaId = props.encuestaId;
  const encuestaNombre = props.encuestaNombre;
  const encuestaTipo = props.encuestaTipo;
  const encuestaAnio = props.encuestaAnio;

  const [value, setValue] = useState(0);
  const [textEditorPractica, setTextEditorPractica] = useState("");
  const [infoPracticaTitle, setInfoPracticaTitle] = useState("");
  const [infoPracticaBody, setInfoPracticaBody] = useState("");
  const [infoNpractica, setInfoNpractica] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setIdEncuesta(encuestaId);
    getDimensiones(encuestaId);
    getSubDimensiones(encuestaId);
    getnPracticas(encuestaId);
    getPracticas(encuestaId);
    getNivelDesarrollo();
    getOpcionRespuesta();
    getDesarrolloEncuesta(encuestaId);


  }, [], desarrolloEncuestaList);

  function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  const renderOptionsSubDimension = () => {
    let contenido = subDimensionList;
    const options = [];

    if (Object.keys(contenido).length > 0) {
      contenido = contenido.Dimensions;
      contenido.forEach((dimension) => {
        let subdimension = dimension.SubDimensions;
        subdimension.forEach((infosubdimension) => {
          options.push(
            <option
              key={infosubdimension.id_subdimension}
              value={infosubdimension.id_subdimension}
            >
              {infosubdimension.nombre}
            </option>
          );
        });
      });
    }

    return options;
  };

  const renderOptionsNpractica = () => {
    let contenido = nPracticaList;
    const options = [];

    //console.log(contenido);

    if (Object.keys(contenido).length > 0) {
      contenido = contenido.Dimensions;
      contenido.forEach((dimension) => {
        let subdimension = dimension.SubDimensions;
        subdimension.forEach((infosubdimension) => {
          let numeropractica = infosubdimension.NumeroPracticas;
          numeropractica.forEach((infonumeropractica) => {
            options.push(
              <option
                key={infonumeropractica.id_numero_practica}
                value={infonumeropractica.id_numero_practica}
              >
                {infonumeropractica.nombre}
              </option>
            );
          });
        });
      });
    }

    return options;
  };

  function limpiarHTML(html) {
    const temporalDiv = document.createElement("div");
    temporalDiv.innerHTML = html;
    return temporalDiv.textContent || temporalDiv.innerText || "";
  }
  const renderOptionsPractica = () => {

    let contenido = practicaList;
    const options = [];

    if (Object.keys(contenido).length > 0) {
      contenido = contenido.Dimensions;
      contenido.forEach((dimension) => {
        let subdimension = dimension.SubDimensions;
        subdimension.forEach((infosubdimension) => {
          let numeropractica = infosubdimension.NumeroPracticas;
          numeropractica.forEach((infonumeropractica) => {
            let practica = infonumeropractica.Practicas;
            practica.forEach((infopractica) => {

              options.push(
                <option
                  key={infopractica.id_practica}
                  value={infopractica.id_practica}
                >
                  {"[" + infonumeropractica.nombre + "] " + limpiarHTML(infopractica.nombre)}
                </option>
              );
            });
          });
        });
      });
    }

    return options;
  };

  const handleSetSelectNPractica = (e) => {
    let contenido = nPracticaList;
    setNPracticaSelect(e.target.value);
    console.log("handleSetSelectNPractica:" + e.target.value);
    if (Object.keys(contenido).length > 0) {
      contenido = contenido.Dimensions;
      contenido.forEach((dimension) => {
        let subdimension = dimension.SubDimensions;
        subdimension.forEach((infosubdimension) => {
          let numeropractica = infosubdimension.NumeroPracticas;
          numeropractica.forEach((infonumeropractica) => {
            if (infonumeropractica.id_numero_practica == e.target.value) {
              setInfoNpractica(
                dimension.nombre +
                " / " +
                infosubdimension.nombre +
                " / " +
                infonumeropractica.nombre
              );
            }
          });
        });
      });
    }
  };


  const handleSetSelectPractica = (e) => {
    let contenido = practicaList;
    setPracticaSelect(e.target.value);

    if (!isNumeric(e.target.value)) {
      setInfoPracticaTitle("");
      setInfoPracticaBody("");
      return;
    }

    if (Object.keys(contenido).length > 0) {
      contenido = contenido.Dimensions;
      contenido.forEach((dimension) => {
        let subdimension = dimension.SubDimensions;
        subdimension.forEach((infosubdimension) => {
          let numeropractica = infosubdimension.NumeroPracticas;
          numeropractica.forEach((infonumeropractica) => {
            let practica = infonumeropractica.Practicas;
            practica.forEach((infopractica) => {
              if (infopractica.id_practica == e.target.value) {
                setInfoPracticaTitle(dimension.nombre + " / " + infosubdimension.nombre + " / " + infonumeropractica.nombre);
                setInfoPracticaBody(limpiarHTML(infopractica.nombre));
              }
            });
          });
        });
      });
    }
  };

  const handleCreateDesarrolloEncuesta = async (id_encuesta, practica_select, desarrollo_encuesta) => {
    await createDesarrolloEncuesta(id_encuesta, practica_select, desarrollo_encuesta);
    setPracticaSelect("");
    const selectPractica = document.getElementById("select-practica");
    if (selectPractica) {
      selectPractica.value = "";
      setInfoPracticaTitle("");
    }
    await getDesarrolloEncuesta(id_encuesta);
  };

  const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
      theme.palette.mode === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[800];
    return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      "&:hover, &:focus": {
        backgroundColor: emphasize(backgroundColor, 0.06),
      },
      "&:active": {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
    };
  });

  let menuItems = [
    {
      href: "/",
      text: "Home",
      componenteIcon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulos",
      text: "Modulos",
      componenteIcon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulo/gopme",
      text: "goPME",
      componenteIcon: <SourceIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          background: "#ffffff",
          height: "auto",
          pt: "0px",
        }}
      >
        <ModulosBreadCrumbs menuItems={menuItems} marginTop="-24px" marginLeft="-24px" />
        <Box
          minHeight="100vh"
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: 224,
            width: "100%",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="Dimensión"
              {...a11yProps(0)}
              onClick={(e) => getDimensiones(encuestaId)}
            />
            <Tab
              label="SubDimensión"
              {...a11yProps(1)}
              onClick={(e) => getSubDimensiones(encuestaId)}
            />
            <Tab
              label="N° de Practica"
              {...a11yProps(2)}
              onClick={(e) => getnPracticas(encuestaId)}
            />
            <Tab
              label="Práctica"
              {...a11yProps(3)}
              onClick={(e) => getPracticas(encuestaId)}
            />
            <Tab
              label="Desarrollo Encuesta"
              {...a11yProps(4)}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Typography variant="h5">
              {encuestaNombre} / {encuestaTipo} / {encuestaAnio}
            </Typography>
            <Typography variant="h6">Dimensión</Typography>
            <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />
            <Stack direction="row" spacing={2}>
              <TextField
                id="dimension"
                name="dimension"
                type="text"
                label="Dimensión"
                size="small"
                value={dimension}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DocumentScannerIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                onChange={(e) => setDimension(e.target.value)}
              />
              <Button
                size="small"
                variant="contained"
                type="button"
                onClick={(e) => createDimension(encuestaId, dimension)}
                sx={{ width: "80px" }}
              >
                Agregar
              </Button>
            </Stack>
            <TablaDimension
              tipo_contenido="Dimensión"
              contenido={dimensionList}
              genericDelete={deleteDimension}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography variant="h5">
              {encuestaNombre} / {encuestaTipo} / {encuestaAnio}
            </Typography>
            <Typography variant="h6">SubDimensión</Typography>
            <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />
            <Stack direction="column" spacing={2}>
              <FormControl>
                <InputLabel variant="standard" htmlFor="anio">
                  Dimensión
                </InputLabel>
                <NativeSelect
                  defaultValue={0}
                  size="small"
                  inputProps={{
                    name: "select-dimension",
                    id: "select-dimension",
                  }}
                  onChange={(e) => setDimensionSelect(e.target.value)}
                >
                  <option key="   " value="    "></option>
                  {dimensionList.map((dimension) => (
                    <option
                      key={dimension.id_dimension}
                      value={dimension.id_dimension}
                    >
                      {dimension.nombre}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
              <TextField
                id="subdimension"
                name="subdimension"
                type="text"
                label="SubDimensión"
                size="small"
                value={subDimension}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DocumentScannerIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                onChange={(e) => setSubDimension(e.target.value)}
              />
              <Button
                size="small"
                variant="contained"
                type="button"
                onClick={(e) =>
                  createSubDimension(
                    encuestaId,
                    parseInt(dimensionSelect),
                    subDimension
                  )
                }
                sx={{ width: "80px" }}
              >
                Agregar
              </Button>
            </Stack>
            <TablaSubDimension />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Typography variant="h5">
              {encuestaNombre} / {encuestaTipo} / {encuestaAnio}
            </Typography>
            <Typography variant="h6">N° de Práctica</Typography>
            <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />
            <Stack direction="column" spacing={2}>
              <FormControl>
                <InputLabel variant="standard" htmlFor="anio">
                  Sub Dimensión
                </InputLabel>
                <NativeSelect
                  defaultValue={0}
                  size="small"
                  inputProps={{
                    name: "select-subdimension",
                    id: "select-subdimension",
                  }}
                  onChange={(e) => setSubDimensionSelect(e.target.value)}
                >
                  <option key="   " value="    "></option>
                  {renderOptionsSubDimension()}
                </NativeSelect>
              </FormControl>
              <TextField
                id="npractica"
                name="npractica"
                type="text"
                label="N° Práctica"
                size="small"
                value={npractica}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DocumentScannerIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                onChange={(e) => setnPractica(e.target.value)}
              />
              <Button
                size="small"
                variant="contained"
                type="button"
                sx={{ width: "80px" }}
                onClick={(e) =>
                  createNPractica(
                    encuestaId,
                    parseInt(subDimensionSelect),
                    npractica
                  )
                }
              >
                Agregar
              </Button>
            </Stack>
            <TablanPractica />
          </TabPanel>

          <TabPanel value={value} index={3}>
            <Typography variant="h5">
              {encuestaNombre} / {encuestaTipo} / {encuestaAnio}
            </Typography>
            <Typography variant="h6">Práctica</Typography>
            <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />
            <Stack direction="column" spacing={2}>
              <FormControl>
                <InputLabel variant="standard" htmlFor="anio">
                  N° Práctica
                </InputLabel>
                <NativeSelect
                  defaultValue={0}
                  size="small"
                  inputProps={{
                    name: "select-npractica",
                    id: "select-npractica",
                  }}
                  onChange={(e) => handleSetSelectNPractica(e)}
                >
                  <option key="   " value="    "></option>
                  {renderOptionsNpractica()}
                </NativeSelect>
              </FormControl>
              <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                  label="Práctica"
                  icon={<DocumentScannerIcon fontSize="small" />}
                />
              </Breadcrumbs>
              <ReactQuill
                value={practica}
                onChange={(value) => setPractica(value)}
              />
              <Button
                size="small"
                variant="contained"
                type="button"
                sx={{ width: "80px" }}
                onClick={(e) =>
                  createPractica(
                    encuestaId,
                    parseInt(nPracticaSelect),
                    practica
                  )
                }
              >
                Agregar
              </Button>
            </Stack>
            {infoNpractica.length > 0 && (
              <>
                <br />
                <Alert severity="info" sx={{ textAlign: "initial" }}>
                  {infoNpractica}
                </Alert>
                <br />
              </>
            )}
            <TablaPractica />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Typography variant="h5">
              {encuestaNombre} / {encuestaTipo} / {encuestaAnio}
            </Typography>
            <Typography variant="h6">Desarrollo Encuesta</Typography>
            <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />
            <Stack direction="column" spacing={2}>
              <FormControl>
                <InputLabel variant="standard" htmlFor="">
                  Práctica
                </InputLabel>
                <NativeSelect
                  defaultValue={0}
                  size="small"
                  inputProps={{
                    name: "select-practica",
                    id: "select-practica",
                  }}
                  onChange={(e) => handleSetSelectPractica(e)}
                >
                  <option key="   " value="    "></option>
                  {renderOptionsPractica()}
                </NativeSelect>
              </FormControl>
              <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                  label="Desarrollo Encuesta"
                  icon={<DocumentScannerIcon fontSize="small" />}
                />
              </Breadcrumbs>
              <ReactQuill
                value={desarrolloEncuesta}
                onChange={(value) => setDesarrolloEncuesta(value)}
              />
              <Button
                size="small"
                variant="contained"
                type="button"
                sx={{ width: "80px" }}
                onClick={(e) =>
                  handleCreateDesarrolloEncuesta(
                    encuestaId,
                    parseInt(practicaSelect),
                    desarrolloEncuesta,
                  )
                }
              >
                Agregar
              </Button>
            </Stack>
            {infoPracticaTitle.length > 0 && (
              <>
                <br />
                <Alert severity="info" sx={{ textAlign: "initial" }}>
                  <AlertTitle>{infoPracticaTitle}</AlertTitle>
                  {infoPracticaBody}
                </Alert>
              </>
            )}
            <TablaDesarrollo id_encuesta={encuestaId} />
          </TabPanel>
        </Box>
      </Container>
    </>
  );
}
