import { Box, Typography } from "@mui/material";
import React from "react";

export default function AgregarCuenta() {
  return (
    <Box sx={{ height:"100vh", pt:"64px", background: "#ccc" }}>
        <Typography>GESTION CUENTAS</Typography>
      </Box>
  );
}
