import React, { useState, useContext, useEffect, useRef } from "react";
import {
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputLabel,
    Modal,
    NativeSelect,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Zoom,
} from "@mui/material";
import { emphasize, styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

import { EncuestaContext } from "../../contexts/EncuestaContext";
import ReactQuill from "react-quill";

import { confirmAlert } from 'react-confirm-alert';
import { limpiarHTML } from "../../utils/utils";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

const checkboxContainer = {
    display: 'table-cell',
    alignItems: 'center', // Alinea verticalmente al centro
}

const style = {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "98%",
    maxHeight: "90vh",
    overflowY: "auto",
    padding: 2,
    border: "10px solid #d77b00",
};

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        "&:hover, &:focus": {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        "&:active": {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const OpcionesConfig = ({ params, dimension, subdimension, npractica, practica, desarrollo_encuesta, id_encuesta, id_desarrollo, id_desarrollo_encuesta, renderTableRownDesarrolloEncuesta }) => {
    const { 
        getCargos,
        getPreguntaEncuesta,
        deleteDesarrolloEncuesta,
        pregunta,
        setPregunta,
        setOpcionRespuesta,
        opcionRespuesta,
        setPreguntaCondicional,
        preguntaCondicional,
        asignaCargoPreguntaEncuesta,
        createPreguntaEncuesta,
        encuestaPreguntaList,
        opcionRespuestaList,
        deletePreguntaCondicionalEncuesta,
        cargoList,
        asignaCargoIdList,
        deletePreguntaEncuesta, desarrolloEncuestaList, getDesarrolloEncuesta, desarrolloList } = useContext(EncuestaContext);

    const [openModal, setOpenModal] = useState(null);
    const [idDesarrolloEncuesta, setIdDesarrolloEncuesta] = useState(null);

    const [updateDesarrolloEncuesta, setUpdateDesarrolloEncuesta] = useState(false);
    /*
console.log("params : ");
console.log(params);*/

    useEffect(() => {
        getCargos();
        if (updateDesarrolloEncuesta) {
            console.log("OpcionesConfig - useEffect - getDesarrolloEncuesta ");
            getDesarrolloEncuesta(id_encuesta);
            setUpdateDesarrolloEncuesta(false);
        }
    }, [updateDesarrolloEncuesta]);


    const renderOptionsPreguntaCondicional = () => {
        const optionsPreguntas = [];
        if (Object.keys(encuestaPreguntaList).length > 0) {
            encuestaPreguntaList.forEach((item) => {
                let pregunta = limpiarHTML(item.pregunta);
                optionsPreguntas.push(
                    <option
                        key={item.id_encuesta_pregunta}
                        value={item.id_encuesta_pregunta}
                    >
                        {pregunta}
                    </option>
                );
            });
        }

        return optionsPreguntas;
    };

    const renderOptionsOpcionRespuesta = () => {
        const options = [];

        if (Object.keys(opcionRespuestaList).length > 0) {
            opcionRespuestaList.forEach((item) => {
                let strOpcionRespuesta =
                    item.verdadero + "/" + item.falso + "/" + item.sn;
                options.push(
                    <option
                        key={item.id_opcion_respuesta}
                        value={item.id_opcion_respuesta}
                    >
                        {strOpcionRespuesta}
                    </option>
                );
            });
        }

        return options;
    };

    const handleEliminaPreguntaEncuesta = (id_desarrollo_encuesta, id_encuesta_pregunta) => {
        confirmAlert({
            title: '¿Estás seguro?',
            message: '¿Seguro que desea eliminar la pregunta de encuesta?',
            buttons: [
                {
                    label: 'Sí, eliminar',
                    onClick: () => {
                        deletePreguntaEncuesta(id_desarrollo_encuesta, id_encuesta_pregunta);
                    }
                },
                {
                    label: 'Cancelar',
                    onClick: () => {
                        // El usuario canceló la acción
                        // Puedes realizar otra acción o simplemente no hacer nada
                    }
                }
            ]
        });
    };

    const setCheckBoxStatus = (id_encuesta_pregunta, id_cargo) => {
        //console.log("arrays asignaCargoIdList:" + asignaCargoIdList.length);
        let statuschecked = false;

        asignaCargoIdList.map((CargoEncuestaPregunta, index) => {
            let infoCargoIdList = CargoEncuestaPregunta.CargoEncuestaPregunta;
            infoCargoIdList.map((infoCargoId, index) => {
                if (
                    infoCargoId.id_encuesta_pregunta === id_encuesta_pregunta &&
                    infoCargoId.id_cargo === id_cargo
                ) {

                    if (infoCargoId.estado) {
                        statuschecked = true;
                        return;
                    }
                }
            });
        });

        return statuschecked;
    };

    const handleEliminaPreguntaCondicional = (id_desarrollo_encuesta, id_encuesta_pregunta_condicional) => {
        confirmAlert({
            title: '¿Estás seguro?',
            message: '¿Seguro que desea eliminar la pregunta condicional?',
            buttons: [
                {
                    label: 'Sí, eliminar',
                    onClick: () => {
                        deletePreguntaCondicionalEncuesta(id_desarrollo_encuesta, id_encuesta_pregunta_condicional);
                    }
                },
                {
                    label: 'Cancelar',
                    onClick: () => {
                        // El usuario canceló la acción
                        // Puedes realizar otra acción o simplemente no hacer nada
                    }
                }
            ]
        });
    };

    const getOptionRespuesta = (id_opcion_respuesta) => {
        let opcion_respuesta = "";
        if (Object.keys(opcionRespuestaList).length > 0) {
            opcionRespuestaList.forEach((item) => {
                if (item.id_opcion_respuesta === id_opcion_respuesta) {
                    opcion_respuesta = item.verdadero + "/" + item.falso + "/" + item.sn;
                }
            });
        }

        return opcion_respuesta;
    };

    const checkboxRefs = useRef({});

    const handleGuardaCargoEncuestaPregunta = (
        event,
        id_cargo,
        id_encuesta_pregunta
    ) => {

        let idCheckBox = id_cargo + "_" + id_encuesta_pregunta;

        asignaCargoPreguntaEncuesta(
            event.target.checked,
            id_cargo,
            id_encuesta_pregunta
        );
        checkboxRefs.current[idCheckBox] = event.target.checked;
        
        handleCloseModal();
        setTimeout(function () { handleOpenModal(idDesarrolloEncuesta); }, 400);


    };
    
    const renderListaPreguntas = () => {
        const tablaPreguntas = [];

        //console.log("renderListaPreguntas");

        const spanStyle = {
            color: "darkred",
            fontSize: "16px",
            fontWeight: "bold",
        };

        if (Object.keys(encuestaPreguntaList).length > 0) {
            encuestaPreguntaList.forEach((item) => {
                let preguntasCondicional = item.EncuestaPreguntaCondicionals;
                let htmlPreguntas = [];
                let htmlPreguntaCondicional = [];

                if (preguntasCondicional.length !== 0) {
                    //console.log("existen preguntas condicionales");

                    preguntasCondicional.forEach((preguntaCondicional) => {
                        htmlPreguntaCondicional.push(
                            <>
                                <li>
                                    {limpiarHTML(preguntaCondicional.pregunta) +
                                        " (" +
                                        limpiarHTML(
                                            getOptionRespuesta(
                                                preguntaCondicional.id_opcion_respuesta
                                            ) + ")"
                                        )}{" "}
                                    <span
                                        style={spanStyle}
                                        onClick={() =>
                                            handleEliminaPreguntaCondicional(
                                                item.id_desarrollo_encuesta,
                                                preguntaCondicional.id_encuesta_pregunta_condicional
                                            )
                                        }
                                    >
                                        &#x2716;
                                    </span>
                                </li>
                            </>
                        );
                    });
                }
                //console.log("no existen preguntas condicionales");

                htmlPreguntas.push(
                    <>
                        <ul>
                            <li>
                                {limpiarHTML(item.pregunta)}
                                <ul>{htmlPreguntaCondicional}</ul>
                            </li>
                        </ul>
                    </>
                );

                let formCheckBox = [];

                <div sx={{ ...checkboxContainer }}>
                    {cargoList.map((itemcargo, index) => {
                        console.log(cargoList);
                        let setCheckedComponent = setCheckBoxStatus(item.id_encuesta_pregunta, itemcargo.id_cargo);

                        let idCheckBox = itemcargo.id_cargo + "_" + item.id_encuesta_pregunta;
                        checkboxRefs.current[idCheckBox] = setCheckedComponent;

                        formCheckBox.push(
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        size="small"
                                        id={idCheckBox}
                                        checked={checkboxRefs.current[idCheckBox]}
                                        onClick={(e) =>
                                            handleGuardaCargoEncuestaPregunta(
                                                e,
                                                itemcargo.id_cargo,
                                                item.id_encuesta_pregunta
                                            )
                                        }
                                    />
                                }
                                label={itemcargo.cargo}
                            />
                        );
                    })}
                </div>

                tablaPreguntas.push(
                    <>
                        <TableRow
                            key={item.id_encuesta_pregunta}
                            sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {htmlPreguntas}

                                {/*}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: strPreguntaCondicional,
                      }}
                    ></div>
                    {*/}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {limpiarHTML(getOptionRespuesta(item.id_opcion_respuesta))}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Stack direction="row">
                                    <Tooltip
                                        TransitionComponent={Zoom}
                                        title="Eliminar"
                                        placement="top"
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() =>
                                                handleEliminaPreguntaEncuesta(
                                                    item.id_desarrollo_encuesta,
                                                    item.id_encuesta_pregunta
                                                )
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {/*item.id_encuesta_pregunta*/}
                                <FormGroup sx={{display:"flow"}}>
                                    {/*asignaCargoCheckBox(item.id_encuesta_pregunta)*/}
                                    {formCheckBox}
                                </FormGroup>
                            </TableCell>
                        </TableRow>
                    </>
                );
            });
        }

        return tablaPreguntas;
    };

    const handleEliminaDesarrolloEncuesta = async (id_encuesta, id_desarrollo_encuesta) => {
        confirmAlert({
            title: '¿Estás seguro?',
            message: '¿Deseas eliminar el desarrollo de la encuesta?',
            buttons: [
                {
                    label: 'Sí, eliminar',
                    onClick: async () => {
                        await deleteDesarrolloEncuesta(id_encuesta, id_desarrollo_encuesta);
                        setUpdateDesarrolloEncuesta(true);
                        renderTableRownDesarrolloEncuesta();
                    }
                },
                {
                    label: 'Cancelar',
                    onClick: () => {
                    }
                }
            ]
        });
    };

    const getDesarrollo = (id_desarrollo) => {
        let nivel_desarrollo = "";
        if (Object.keys(desarrolloList).length > 0) {
            desarrolloList.forEach((item) => {

                if (item.id_desarrollo === id_desarrollo) {
                    nivel_desarrollo = item.nombre;
                }
            });
        }

        return nivel_desarrollo;
    };

    const handleOpenModal = (id) => {
        setIdDesarrolloEncuesta(id);
        getPreguntaEncuesta(id);
        setOpenModal(id);
    };

    const handleCloseModal = () => {
        setOpenModal(null);
    };

    return (
        <>
            <Stack direction="row" spacing={2}>
                <IconButton
                    aria-label="Preguntas"
                    onClick={() =>
                        handleOpenModal(id_desarrollo_encuesta)
                    }
                >
                    <SettingsIcon />
                </IconButton>
                <IconButton
                    aria-label="delete"
                    onClick={() =>
                        handleEliminaDesarrolloEncuesta(id_encuesta, id_desarrollo_encuesta)
                    }
                >
                    <DeleteIcon />
                </IconButton>
            </Stack>
            <div className={`modal ${openModal ? 'fade-in' : 'fade-out'}`}>
                <Modal
                    key={`modal-${id_desarrollo_encuesta}`}
                    open={openModal === id_desarrollo_encuesta}
                    onClose={handleCloseModal}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{ ...style }}>
                        <TableContainer
                            sx={{ m: "auto", mt: "30px" }}
                            component={Paper}
                        >
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Dimensión</TableCell>
                                        <TableCell>SubDimensión</TableCell>
                                        <TableCell>N° de Práctica</TableCell>
                                        <TableCell>Práctica</TableCell>
                                        <TableCell>Desarrollo Encuesta</TableCell>
                                        <TableCell>Nivel de Desarrollo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        key={`detalle-${id_desarrollo_encuesta}`}
                                        sx={{
                                            "&:last-child td, &:last-child th": {
                                                border: 0,
                                            },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {dimension}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {subdimension}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {npractica}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: practica,
                                                }}
                                            ></div>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: desarrollo_encuesta,
                                                }}
                                            ></div>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            {getDesarrollo(id_desarrollo)}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack direction="column" spacing={2}>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                sx={{ mt: "15px" }}
                            >
                                <StyledBreadcrumb
                                    label="Lista de Preguntas"
                                    icon={<DocumentScannerIcon fontSize="small" />}
                                />
                            </Breadcrumbs>
                            <TableContainer
                                sx={{ m: "auto", mt: "30px" }}
                                component={Paper}
                            >
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Pregunta</TableCell>
                                            <TableCell>Tipo Respuesta</TableCell>
                                            <TableCell>Opciones</TableCell>
                                            <TableCell>Asignación de Cargo</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{renderListaPreguntas()}</TableBody>
                                </Table>
                            </TableContainer>
                            <Breadcrumbs aria-label="breadcrumb">
                                <StyledBreadcrumb
                                    label="Crear Pregunta"
                                    icon={<DocumentScannerIcon fontSize="small" />}
                                />
                            </Breadcrumbs>
                            <FormControl>
                                <InputLabel variant="standard" htmlFor="">
                                    Pregunta condicional (Si)
                                </InputLabel>
                                <NativeSelect
                                    defaultValue={0}
                                    size="small"
                                    inputProps={{
                                        name: `select-pregunta-condicional-${id_desarrollo_encuesta}`,
                                        id: `select-pregunta-condicional-${id_desarrollo_encuesta}`,
                                    }}
                                    onChange={(e) =>
                                        setPreguntaCondicional(e.target.value)
                                    }
                                >
                                    <option key="null" value="null">
                                        Sin pregunta condicional
                                    </option>
                                    {renderOptionsPreguntaCondicional()}
                                </NativeSelect>
                            </FormControl>
                            <FormControl>
                                <InputLabel variant="standard" htmlFor="">
                                    Tipo Respuesta
                                </InputLabel>
                                <NativeSelect
                                    defaultValue={0}
                                    size="small"
                                    inputProps={{
                                        name: `select-opcion-respuesta-${id_desarrollo_encuesta}`,
                                        id: `select-opcion-respuesta-${id_desarrollo_encuesta}`,
                                    }}
                                    onChange={(e) =>
                                        setOpcionRespuesta(e.target.value)
                                    }
                                >
                                    <option key="   " value="    "></option>
                                    {renderOptionsOpcionRespuesta()}
                                </NativeSelect>
                            </FormControl>
                            <ReactQuill
                                value={pregunta}
                                onChange={(value) => setPregunta(value)}
                            />

                            <Button
                                size="small"
                                variant="contained"
                                type="button"
                                onClick={(e) =>
                                    createPreguntaEncuesta(
                                        id_desarrollo_encuesta,
                                        parseInt(preguntaCondicional),
                                        opcionRespuesta,
                                        pregunta
                                    )
                                }
                                sx={{ width: "80px", mt: "15px" }}
                            >
                                Agregar
                            </Button>
                        </Stack>

                    </Box>
                </Modal>
            </div>
        </>
    );
};

export default OpcionesConfig;