import React, { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ContestaEncuestaContext = createContext();
export const NOTIFICATIONTIME = process.env.REACT_APP_NOTIFICATIONTIME;

const API_ADMIN_PROTO = process.env.REACT_APP_API_ADMIN_PROTO;
const API_ADMIN_HOST = process.env.REACT_APP_API_ADMIN_HOST;
const API_ADMIN_PORT = process.env.REACT_APP_API_ADMIN_PORT;

export const ContestaEncuestaProvider = ({ children }) => {

  /* BEGIN GET CARGO */
  const [infoPregunta, setInfoPregunta] = useState([]);
  const getPreguntaEncuesta = async () => {
    try {
      const response = await axios.get(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/contesta_encuesta`,{ withCredentials: true });
      if (response.data.success) {
        setInfoPregunta(response.data.infoPregunta);
        //setCargoList(response.data.cargo);
        //console.log(response.data.cargo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* END GET CARGO */


  const contextValues = {
    getPreguntaEncuesta,
    infoPregunta,
  };

  return (
    <ContestaEncuestaContext.Provider value={contextValues}>
      {children}
    </ContestaEncuestaContext.Provider>
  );
};
