import React, { useState, useContext, useEffect, useRef } from "react";
import HomeIcon from "@mui/icons-material/Home";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ModulosBreadCrumbs from "./Modulos/ModulosBreadCrumbs/ModulosBreadCrumbs";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  NativeSelect,
  Typography,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { IlumenContext } from "../contexts/IlumenContext";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { capitalize, fontSize, fontSizeTitle, generateLargeRandomNumber } from "../utils/utils";
export const NOTIFICATIONTIME = process.env.REACT_APP_NOTIFICATIONTIME;

export default function AccesoIlumen() {
  const { getUsuariosIlumen, infoUsuarioIlumen, cambiarEstadoIlumen, tipoEnsenanza } = useContext(IlumenContext);

  let menuItems = [
    {
      href: "/",
      text: "Home",
      componenteIcon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulos",
      text: "Modulos",
      componenteIcon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulo/ilumen",
      text: "iLumen",
      componenteIcon: <AutoStoriesIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];

  const [statusLoading, setStatusLoading] = useState(null);
  const [rowsColegios, setRowsColegios] = useState([]);
  const [rowsDirectivo, setRowsDirectivo] = useState([]);
  const [rowsProfesores, setRowsProfesores] = useState([]);


  useEffect(() => {
    getUsuariosIlumen();

    const intervalId = setInterval(() => {
      getUsuariosIlumen();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const LoadingSpinner = () => {
    return (
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  const getCursoTipoEnsenanza = (codigo, grado) => {
    var infoTipoEnsenanza = tipoEnsenanza.filter(tipo_ensenanza => tipo_ensenanza.codigo == codigo && tipo_ensenanza.grado == grado );
    infoTipoEnsenanza = infoTipoEnsenanza[0];
    return `${infoTipoEnsenanza.descripcion} (${infoTipoEnsenanza.glosa})`;
  };

  const handleChangeEstadoIlumen = async (id_usuario, estado) => {
    await cambiarEstadoIlumen(id_usuario, estado);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'nombre',
      headerName: 'Nombre',
      width: 200,
      editable: false,
    },

    {
      field: 'run',
      headerName: 'Run',
      width: 100,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      editable: false,
    },
    {
      field: 'telefono',
      headerName: 'Telefono',
      width: 200,
      editable: false,
    },
    {
      field: 'estado_ilumen',
      headerName: 'Estado iLumen',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row.estado_ilumen ? (
            <Alert severity="success">Habilitado</Alert>
          ) : (
            <Alert severity="error">No Habilitado</Alert>
          )}
        </>
      ),
    },
    {
      field: 'cambiar_acceso',
      headerName: 'Cambiar Acceso',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <>
          {params.row.estado_ilumen ? (
            <Button variant="contained" size="small" onClick={() => { handleChangeEstadoIlumen(params.row.id_usuario, 0); }} >
              Deshabilitar
            </Button>
          ) : (
            <Button variant="contained" size="small" onClick={() => { handleChangeEstadoIlumen(params.row.id_usuario, 1); }} >
              Habilitar
            </Button>
          )}
        </>
      ),
    },
    {
      field: 'ver_colegios',
      headerName: 'Ver Colegios',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <>
          {params.row.colegios && params.row.colegios.length > 0 && (
            <Button variant="contained" size="small" onClick={() => { handleVerColegiosUsuario(params.row.id_usuario); }} >
              Ver Colegios
            </Button>
          )}
        </>
      ),
    },

  ];


  const columnsColegios = [
    { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'colegio',
      headerName: 'Colegio',
      width: 200,
      editable: false,
    },

    {
      field: 'anio',
      headerName: 'Año',
      width: 70,
      editable: false,
    },
    {
      field: 'rbd',
      headerName: 'RBD',
      width: 60,
      editable: false,
    },
    {
      field: 'comuna',
      headerName: 'Comuna',
      width: 90,
      editable: false,
    },
    {
      field: 'telefono',
      headerName: 'Teléfono',
      width: 90,
      editable: false,
    },
    {
      field: 'perfil_sige',
      headerName: 'Perfil SIGE',
      width: 90,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row.perfil_sige ? (
            <Alert severity="success">SI</Alert>
          ) : (
            <Alert severity="error">NO</Alert>
          )}
        </>
      ),
    },
    {
      field: 'correo',
      headerName: 'Correo Electrónico',
      width: 230,
      editable: false,
    },
    {
      field: 'ver_directivos',
      headerName: 'Ver Directivos',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <>
          {params.row.directivo && params.row.directivo.length > 0 && (
            <Button variant="contained" size="small" onClick={() => { handleVerDirectivosColegio(params.row.id_colegio); }} >
              Ver Directivos
            </Button>
          )}
        </>
      ),
    },
    {
      field: 'ver_profesores',
      headerName: 'Ver Profesores',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <>
          {params.row.profesor_jefe && params.row.profesor_jefe.length > 0 && (
            <Button variant="contained" size="small" onClick={() => { handleVerProfesorJefeColegio(params.row.id_colegio); }} >
              Ver Profesor Jefe
            </Button>
          )}
        </>
      ),
    },

  ];

  const columnsDirectivos = [
    { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'nombre',
      headerName: 'Nombre',
      width: 200,
      editable: false,
    },

    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      editable: false,
    },
    {
      field: 'cargo',
      headerName: 'Cargo',
      width: 200,
      editable: false,
    },
  ];

  const columnsProfesorJefe = [
    { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'nombre',
      headerName: 'Nombre',
      width: 200,
      editable: false,
    },

    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      editable: false,
    },
    {
      field: 'curso',
      headerName: 'Curso',
      width: 200,
      editable: false,
    },
    {
      field: 'telefono',
      headerName: 'Teléfono',
      width: 200,
      editable: false,
    },
  ];

  const rows = [];


  if (Object.keys(infoUsuarioIlumen).length > 0) {
    infoUsuarioIlumen.forEach((item) => {

      let objeto_usuario = {
        id: `id_usuario-${item.id_usuario}`,
        id_usuario: item.id_usuario,
        nombre: item.nombre,
        run: item.run,
        email: item.email,
        telefono: item.telefono,
        estado_ilumen: item.ilumen,
        colegios: item.colegios,
      };

      rows.push(objeto_usuario);

    });
  }

  //setRowsColegios([]);
  const handleVerColegiosUsuario = async (id_usuario) => {
    var rows = [];
    setRowsDirectivo([]);
    if (Object.keys(infoUsuarioIlumen).length > 0) {
      var infoColegio = infoUsuarioIlumen.filter(info_usuario => info_usuario.id_usuario == id_usuario);

      if (infoColegio.length !== 0) {
        infoColegio = infoColegio[0];
        infoColegio.colegios.forEach((item) => {

          let objeto_colegio = {
            id: `id_colegio-${item.id_colegio}`,
            id_colegio: item.id_colegio,
            colegio: capitalize(item.nombre),
            anio: item.anio,
            comuna: capitalize(item.comuna),
            rbd: `${item.rbd}-${item.rbddv}`,
            telefono: `${item.telefono}`,
            correo: `${item.email}`,
            directivo: item.directivo,
            profesor_jefe: item.profesor_jefe,
            perfil_sige: item.perfil_sige,
          };

          rows.push(objeto_colegio);

        });
        setRowsColegios(rows);
      }
    }
  };

  const handleVerDirectivosColegio = async (id_colegio) => {
    var rows = [];
    setRowsDirectivo([]);

    if (Object.keys(rowsColegios).length > 0) {
      var infoColegio = rowsColegios.filter(info_colegio => info_colegio.id_colegio == id_colegio);
      infoColegio = infoColegio[0];
      
      if (infoColegio.directivo.length !== 0) {
        var infoDirectivo = infoColegio.directivo;

        infoDirectivo.forEach((item) => {

          let objeto_directivo = {
            id: `id_directivo-${generateLargeRandomNumber(100000)}`,
            id_colegio: item.id_colegio,
            nombre: capitalize(item.nombre),
            email: item.email,
            cargo: item.cargo,
            telefono: item.telefono,
          };

          rows.push(objeto_directivo);

        });
        setRowsDirectivo(rows);
      }
    }
  };

  const handleVerProfesorJefeColegio = async (id_colegio) => {
    var rows = [];
    setRowsProfesores([]);
    
    if (Object.keys(rowsColegios).length > 0) {
      var infoColegio = rowsColegios.filter(info_colegio => info_colegio.id_colegio == id_colegio);
      infoColegio = infoColegio[0];
      
      if (infoColegio.profesor_jefe.length !== 0) {
        var infoProfesor = infoColegio.profesor_jefe;
        
        infoProfesor.forEach((item) => {
          
          let objeto_profesor_jefe = {
            id: `id_profesor_jefe-${generateLargeRandomNumber(100000)}`,
            id_colegio: item.id_colegio,
            nombre: capitalize(item.nombre),
            email: item.email,
            curso: `${getCursoTipoEnsenanza(item.codigo, item.grado)}/${item.letra}`,
            telefono: item.telefono_celular,
          };

          rows.push(objeto_profesor_jefe);

        });
        setRowsProfesores(rows);
      }
    }
  };


  const hiddenColumns = ['id'];
  return (
    <>
      <Container maxWidth={false} sx={{ background: "#ffffff", height: "auto", pt: "40px", pb: '40px' }}>
        <ModulosBreadCrumbs menuItems={menuItems} />
        <Box>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: fontSizeTitle,
            }}
          >
            LISTA DE USUARIOS ILUMEN
          </Typography>
          <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />
          <Box sx={{ textAlign: "justify", m: "auto", pb: "20px" }}>
            <Typography
              variant="a"
              sx={{
                fontSize: fontSize,
              }}
            >
              Visualización de información de usuarios registrados y asociados al sistema iLumen y su habilitación.
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%", marginTop: "30px" }}
            >
              <>
                <DataGrid
                  autoHeight
                  autoWidth
                  rows={rows}
                  columns={columns.filter((col) => !hiddenColumns.includes(col.field))}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 15,
                      },
                    },
                    sorting: {
                      sortModel: [{ field: 'anio', sort: 'desc' }], // Ordenar por 'anio' de forma descendente
                    },
                  }}
                  pageSizeOptions={[15]}
                  disableRowSelectionOnClick
                  rowHeight={30}
                  columnHeaderHeight={40}
                />
              </>

            </Grid>
          </Grid>


          <>
            <React.Fragment key="tabla-colegios">

              <Box sx={{ textAlign: "center", m: "auto", pt: "20px" }}>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    fontSize: fontSizeTitle,
                  }}
                >
                  LISTA DE COLEGIOS
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ m: "auto", width: "100%", marginTop: "5px" }}
                >

                  <DataGrid
                    autoHeight
                    autoWidth
                    rows={rowsColegios}
                    columns={columnsColegios.filter((col) => !hiddenColumns.includes(col.field))}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 15,
                        },
                      },
                      sorting: {
                        sortModel: [{ field: 'anio', sort: 'desc' }], // Ordenar por 'anio' de forma descendente
                      },
                    }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    rowHeight={30}
                    columnHeaderHeight={40}
                  />


                </Grid>
              </Grid>
            </React.Fragment>
          </>

          <Grid container spacing={2}>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%", marginTop: "5px" }}
            >

              <>
                <React.Fragment key="tabla-directivos">
                  <Box sx={{ textAlign: "center", m: "auto", pt: "20px" }}>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      sx={{
                        fontWeight: "bold",
                        fontSize: fontSizeTitle,
                      }}
                    >
                      LISTA DE DIRECTIVOS
                    </Typography>
                  </Box>



                  <DataGrid
                    autoHeight
                    autoWidth
                    rows={rowsDirectivo}
                    columns={columnsDirectivos.filter((col) => !hiddenColumns.includes(col.field))}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 15,
                        },
                      },
                      sorting: {
                        sortModel: [{ field: 'anio', sort: 'desc' }], // Ordenar por 'anio' de forma descendente
                      },
                    }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    rowHeight={30}
                    columnHeaderHeight={40}
                  />


                </React.Fragment>
              </>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%", marginTop: "5px" }}
            >
              <>
                <React.Fragment key="tabla-profesores">
                  <Box sx={{ textAlign: "center", m: "auto", pt: "20px" }}>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="div"
                      sx={{
                        fontWeight: "bold",
                        fontSize: fontSizeTitle,
                      }}
                    >
                      LISTA DE PROFESORES
                    </Typography>
                  </Box>



                  <DataGrid
                    autoHeight
                    autoWidth
                    rows={rowsProfesores}
                    columns={columnsProfesorJefe.filter((col) => !hiddenColumns.includes(col.field))}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 15,
                        },
                      },
                      sorting: {
                        sortModel: [{ field: 'anio', sort: 'desc' }], // Ordenar por 'anio' de forma descendente
                      },
                    }}
                    pageSizeOptions={[15]}
                    disableRowSelectionOnClick
                    rowHeight={30}
                    columnHeaderHeight={40}
                  />


                </React.Fragment>
              </>
            </Grid>

          </Grid>

        </Box >
      </Container >
    </>
  );
}
