import { Box } from "@mui/material";
import React from "react";

export default function Perfil() {
  return (
    <Box sx={{ height:"100vh", pt:"64px", background: "#ccc" }}>
        <div>PROFILE</div>
      </Box>
  );
}
