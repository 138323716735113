import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {
    Button,
    Container,
    Divider,
    Grid,
    LinearProgress,
    Stack,
    Typography,
} from "@mui/material";

import { Mail, Fingerprint } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//import { AuthContext } from "../contexts/AuthContext";

import imagenMain from "../../assets/logoMain.png";

export default function AuthEncuesta() {
    //const { isAuthenticated, login } = useContext(AuthContext);
    const { isAuthenticated, setIsAuthenticated } = useState(false);

    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
    });

    const { email, password } = inputValue;

    const handleOnChange = (e) => {

        const { name, value } = e.target;
        setInputValue({
            ...inputValue,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            toast.error("Debe ingresar todos los datos solicitados.", {
                position: "bottom-left",
                autoClose: 1000,
            });

            return;
        }

        try {
            //login(inputValue);
        } catch (error) {
            toast.error(error, {
                position: "bottom-left",
                autoClose: 1000,
            });
        }

        /*
        setInputValue({
          ...inputValue,
          email: "",
          password: "",
        });*/
    };

    if (isAuthenticated) {
        // Renderiza un estado de carga mientras se realiza la validación de la sesión
        //navigate("/");
        //navigate(0);
        /*
        return (
          <>
            <Container
              maxWidth="lg"
              sx={{
                marginTop: "100px",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            </Container>
            
          </>
          
        );*/
    }

    return (
        <>
            <Box
                component="img"
                sx={{
                    width: "100%",
                    //maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 200, md: 200, lg: 250, xl: 250 },
                }}
                alt="Khrono Latam EdTech"
                src={imagenMain}
            />
            <Divider sx={{ mb: "20px", borderWidth: "2px", borderColor: "#459bae" }} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 2,
                    //border: 1,
                    //borderColor: "primary.main",
                    //borderRadius: 2,
                    //boxShadow: 1,
                    width: "90%",
                    margin: "auto",
                    background: "white",
                    borderRadius: "10px",
                    pt: 5,
                    pb: 5,
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                }}
            >
                <Typography
                    gutterBottom
                    variant="a"
                    component="p"
                    align="justify" 
                    sx={{
                        fontSize: {
                            lg: 16,
                            md: 16,
                            sm: 16,
                            xs: 16,
                        },
                        color: "#313131",
                    }}
                >
                    Para iniciar la encuesta, los usuarios deben iniciar sesión utilizando su RUT.
                </Typography>
                <Typography
                    gutterBottom
                    variant="a"
                    component="p"
                    align="justify" 
                    sx={{
                        fontSize: {
                            lg: 16,
                            md: 16,
                            sm: 16,
                            xs: 16,
                        },
                        mb: "35px",
                        color: "#313131",
                    }}
                >
                    El RUT actúa como una identificación única que permite a los usuarios acceder y completar la encuesta de manera personalizada y segura
                </Typography>
                <Grid container spacing={2} >
                    <Grid item xl={3} lg={3} md={4} sm={8} sx={{ m: "auto", width: "100%" }}>
                        {/*}<form onSubmit={handleSubmit}>{*/}
                        <Box sx={{ "& > :not(style)": { m: "auto" } }}>
                            <Stack direction="column" spacing={2}>
                                <TextField
                                    type="text"
                                    id="rut"
                                    name="rut"
                                    label="Rut"
                                    placeholder="16456741k"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Fingerprint />
                                            </InputAdornment>
                                        ),

                                    }}
                                    variant="standard"
                                    onChange={handleOnChange}
                                />
                            </Stack>
                        </Box>
                        <br />
                        <Box sx={{ display: "inline-block", mt: "10px" }}>
                            <Link to="/contestar_encuesta/init">
                                <Button variant="contained" type="submit">
                                    Ingresar
                                </Button>
                            </Link>
                        </Box>
                        {/*}</form>{*/}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
