import React from "react";
import FormLogin from "./components/FormLogin"
import { Box, Container } from "@mui/material";

export default function Login() {
  return (
    <>
      <Container maxWidth="xs" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <FormLogin />
      </Container>
    </>
  );
}
