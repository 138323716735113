import React, { useState, useContext, useEffect } from "react";
import HomeIcon from "@mui/icons-material/Home";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/RemoveCircle';

import ModulosBreadCrumbs from "./Modulos/ModulosBreadCrumbs/ModulosBreadCrumbs";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  NativeSelect,
  Typography,
} from "@mui/material";

import PeopleIconIcon from "@mui/icons-material/People";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import { MineducContext } from "../contexts/MineducContext";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { capitalize, fontSize, fontSizeTitle } from "../utils/utils";

function calcularDiasMesArray(anio, mes) {

  const primerDiaDelMes = new Date(anio, mes - 1, 1);
  const ultimoDiaDelMes = new Date(anio, mes, 0);

  const diasMes = [];

  for (let dia = primerDiaDelMes.getDate(); dia <= ultimoDiaDelMes.getDate(); dia++) {
    const fecha = new Date(anio, mes - 1, dia);

    const nombreDia = obtenerNombreDia(fecha.getDay());
    const nDia = nombreDia[0];

    diasMes.push({
      dia,
      nDia,
    });

  }

  return diasMes;
}

function obtenerNombreDia(numeroDia) {
  const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  return diasSemana[numeroDia];
}

export default function AsistenciaEscolar() {
  const {
    isLoadUseEffect,
    getAllColegios,
    listaColegios,
    getSessionEstadoSigeMineduc,
    listaSessionEstadoSigeMineduc,

    getSessionAsistenciaAlumnosSigeMineduc,
    listaSessionAsistenciaAlumnosSigeMineduc,

    getAsistenciaAlumnos,
    listAsistenciaAlumnos,
    infoAsistenciaAlumno,

    CallBotsAsistencias,

  } = useContext(MineducContext);

  let menuItems = [
    {
      href: "/",
      text: "Home",
      componenteIcon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulos",
      text: "Modulos",
      componenteIcon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulo/ilumen",
      text: "iLumen",
      componenteIcon: <AutoStoriesIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];

  const [openViewCursos, setOpenViewCursos] = useState(null);
  const [openViewAsistencia, setOpenViewAsistencia] = useState(null);
  const [statusLoading, setStatusLoading] = useState(null);
  const [sessionSeleccionada, setSessionSeleccionada] = useState({});
  const [sessionSeleccionadaTipoEnsenanza, setSessionSeleccionadaTipoEnsenanza] = useState([]);

  const [sessionRBD, setSessionRBD] = useState(null);
  const [sessionMes, setSessionMes] = useState(null);
  const [sessionAnio, setSessionAnio] = useState(null);
  const [sessionCursoLetra, setSessionCursoLetra] = useState(null);

  const [extAsistenciaAlumnosRBD, setExtAsistenciaAlumnosRBD] = useState();
  const [extAsistenciaAlumnosMES, setExtAsistenciaAlumnosMES] = useState(new Date().getMonth());
  const [extAsistenciaAlumnosANIO, setExtAsistenciaAlumnosANIO] = useState(new Date().getFullYear());


  useEffect(() => {
    getAllColegios();
    getSessionAsistenciaAlumnosSigeMineduc();
    getSessionEstadoSigeMineduc();
    const intervalId = setInterval(() => {
      getSessionAsistenciaAlumnosSigeMineduc();
    }, 30000);


    return () => clearInterval(intervalId);
  }, [listAsistenciaAlumnos]);


  const LoadingSpinner = () => {
    return (
      <Box
        sx={{
          position: "fixed", // Hace que el spinner sea absoluto en relación con la ventana del navegador
          top: "50%", // Centra verticalmente en la pantalla
          left: "50%", // Centra horizontalmente en la pantalla
          transform: "translate(-50%, -50%)", // Centra el contenido
          zIndex: 9999, // Establece una capa superior para que esté por delante de todos los componentes
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  const renderAsistenciaAlumnos = () => {
    const tablas = [];
    const columns = [];
    const rows = [];

    columns.push(
      {
        field: "id",
        headerName: "ID",
        width: 30,
        editable: false,
      },
      {
        field: "alumnos",
        headerName: "Alumnos",
        width: 250,
        editable: false,
      },
    );

    const diasDelMes = calcularDiasMesArray(sessionAnio, sessionMes);
    const cantidadDeDiasMes = diasDelMes.map((valor, index) => {

      const field = `${valor.dia}`;
      const headerName = `${valor.nDia}-${valor.dia}`;

      return {
        field: field,
        headerName: headerName,
        width: 60,
        editable: false,
        renderCell: (params) => (
          <>
            <EstadoAsistencia estadoDias={params.row} dia={field} />
          </>
        ),
      };
    });

    columns.push(...cantidadDeDiasMes);

    let indexAlumno = 1;
    let resultado = {};
    if (Array.isArray(listAsistenciaAlumnos)) {
      listAsistenciaAlumnos.map((infoAlumno, index) => {

        infoAsistenciaAlumno.map((infoDiaAsistencia) => {
          if (infoDiaAsistencia.id_nomina_alumno === infoAlumno.id_nomina_alumno) {
            resultado[infoDiaAsistencia.dia] = infoDiaAsistencia.estado;
          }

        });


        rows.push(
          {
            id: indexAlumno,
            alumnos: `${capitalize(infoAlumno.ape_paterno)} ${capitalize(infoAlumno.ape_materno)} ${capitalize(infoAlumno.nombres)}`,
            ...resultado,
          }
        );

        indexAlumno = indexAlumno + 1;
      });
    }


    const cantidadPagination = 40;
    tablas.push(
      <>
        <DataGrid
          autoHeight
          autoWidth
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: cantidadPagination,
              },
            },
          }}
          pageSizeOptions={[cantidadPagination]}
          checkboxSelection
          disableRowSelectionOnClick
          rowHeight={30} // Ajusta el tamaño de las celdas
          columnHeaderHeight={40} // Ajusta el tamaño de las cabeceras
        />
      </>
    );

    return tablas;
  }

  const handleOpenSessionAsistenciaAlumno = async (tipo_ensenanza, rbd, mes, anio, codigo, grado, letra) => {

    setStatusLoading(true);
    setSessionCursoLetra(tipo_ensenanza);
    await getAsistenciaAlumnos(rbd, mes, anio, codigo, grado, letra);
    setStatusLoading(false);
    setOpenViewAsistencia(true);
  };
  const handleOpenCursosAsistencia = (rbd, mes, anio, id) => {

    setOpenViewAsistencia(false);
    setSessionRBD(rbd);
    setSessionMes(mes);
    setSessionAnio(anio);

    let seleccionadaTipoEnsenanza = [];
    setOpenViewCursos(false);
    if (Object.keys(listaSessionAsistenciaAlumnosSigeMineduc).length > 0) {
      for (const item of listaSessionAsistenciaAlumnosSigeMineduc) {
        if (item.id_session_asistencia === id) {
          setSessionSeleccionada(item);
          for (const infoCurso of item.cursos) {
            if (!seleccionadaTipoEnsenanza.includes(infoCurso.codigo + " " + infoCurso.tipo_ensenanza)) {
              seleccionadaTipoEnsenanza.push(infoCurso.codigo + " " + infoCurso.tipo_ensenanza);
            }

          }
          break;
        }
      }
    }
    setSessionSeleccionadaTipoEnsenanza(seleccionadaTipoEnsenanza);

    setOpenViewCursos(true);
  };

  const OptionCurso = ({ tipo_ensenanza, rbd, mes, anio, codigo, grado, letra, handleOpenSessionAsistenciaAlumno }) => {

    return (
      <>
        <IconButton
          aria-label="Ver Asistencia Alumnos"
          onClick={() =>
            handleOpenSessionAsistenciaAlumno(tipo_ensenanza, rbd, mes, anio, codigo, grado, letra)
          }
        >
          <PeopleIconIcon />
        </IconButton>
      </>
    );
  };

  const EstadoAsistencia = ({ estadoDias, dia }) => {
    dia = parseInt(dia);
    let estado = null;

    for (const forDia in estadoDias) {
      const estadoDia = estadoDias[forDia];
      if (parseInt(forDia) === dia) {
        estado = estadoDia;
      }
    }

    switch (estado) {
      case 0:
        return (
          <>
            <IconButton style={{ fontSize: '10px', color: '#ca252f' }}>
              <CancelIcon />
            </IconButton>
          </>
        );
        break;

      case 1:
        return (
          <>
            <IconButton style={{ fontSize: '10px', color: '#17984f' }}>
              <DoneIcon />
            </IconButton>
          </>
        );
        break;
      case 2:
        return (
          <>
            <IconButton style={{ fontSize: '10px', color: '#fec404' }}>
              <CloseIcon />
            </IconButton>
          </>
        );
        break;

      case 3:
        return (
          <>
            <IconButton style={{ fontSize: '10px', color: '#0765b2' }}>
              <RemoveIcon />
            </IconButton>
          </>
        );
        break;
      default:
        return (
          <>
            <IconButton>
              {estado}
            </IconButton>
          </>
        );

        break;
    }

  };


  const renderListaCursos = () => {
    const tablas = [];

    const columns = [];
    const rows = [];

    const resultArray = sessionSeleccionadaTipoEnsenanza.map((valor, index) => {
      const headerName = `${valor}`;

      if (!columns[index]) {
        columns[index] = [];
      }

      if (!rows[index]) {
        rows[index] = [];
      }

      columns[index].push(
        {
          field: "id",
          headerName: headerName,
          width: 350,
          editable: false,
        },
        {
          field: 'options',
          headerName: 'Detalle de curso',
          width: 300,
          editable: false,
          renderCell: (params) => (
            <>
              <OptionCurso
                tipo_ensenanza={params.row.id}
                rbd={sessionRBD}
                mes={sessionMes}
                anio={sessionAnio}
                codigo={params.row.codigo}
                grado={params.row.grado}
                letra={params.row.letra}
                handleOpenSessionAsistenciaAlumno={handleOpenSessionAsistenciaAlumno}
              />
            </>
          ),
        },
      );
      for (const cursoKey in sessionSeleccionada.cursos) {
        if (sessionSeleccionada.cursos.hasOwnProperty(cursoKey)) {
          const curso = sessionSeleccionada.cursos[cursoKey];
          if (valor === `${curso.codigo} ${curso.tipo_ensenanza}`) {

            rows[index].push(
              {
                id: `${curso.nombre_curso} ${curso.letra}`,
                codigo: curso.codigo,
                grado: curso.grado,
                letra: curso.letra,
              }

            );
          }
        }

        //
      }

    });
    const flattenedColumns = columns.flat();

    if (Array.isArray(columns)) {
      columns.map((sessionTipoEnsenanza, index) => {

        tablas.push(
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{ m: "auto" }}
          >
            <DataGrid
              autoHeight
              autoWidth
              rows={rows[index]}
              columns={columns[index]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 3,
                  },
                },
              }}
              pageSizeOptions={[3]}
              checkboxSelection
              disableRowSelectionOnClick
              rowHeight={30}
              columnHeaderHeight={40}
            />
          </Grid>
        );

      });

    }

    return <Grid container spacing={2} >{tablas}</Grid>;
  };

  function getMesByNumeroMes(numeroMes) {
    let mes = "";
    const meses = ["", "Enero", "Febrero", "Marzo", "Abril", "Mayo",
      "Junio", "Julio", "Agosto", "Septiembre", "Octubre",
      "Noviembre", "Diciembre"];

    if (numeroMes >= 1 && numeroMes <= 12) {
      mes = meses[numeroMes];
    }

    return mes;
  };


  function getSessionEstadoByCode(code) {
    let descripcion = "";
    if (Object.keys(listaSessionEstadoSigeMineduc).length > 0) {
      listaSessionEstadoSigeMineduc.forEach((item) => {
        if (item.estado === code) {
          descripcion = item.descripcion;
        }
      });
    }
    return descripcion;
  };

  const renderOptionsColegios = () => {
    const options = [];

    options.push(
      <option key="" value="">
      </option>
    );

    if (Array.isArray(listaColegios)) {
      listaColegios.map((infoColegio) => {
        options.push(
          <option key={infoColegio.id_colegio} value={infoColegio.rbd}>
            {infoColegio.nombre}
          </option>
        );
      });

    }

    return options;
  };


  function getColegioByRBD(rbd) {
    let colegio = "";
    if (Array.isArray(listaColegios)) {
      listaColegios.map((infoColegio) => {
        if (infoColegio.rbd === rbd) {
          colegio = infoColegio.nombre;
        }
      });
    }

    return colegio;
  }

  const renderOptionsMonths = () => {
    const months = [
      { value: 1, label: "Enero" },
      { value: 2, label: "Febrero" },
      { value: 3, label: "Marzo" },
      { value: 4, label: "Abril" },
      { value: 5, label: "Mayo" },
      { value: 6, label: "Junio" },
      { value: 7, label: "Julio" },
      { value: 8, label: "Agosto" },
      { value: 9, label: "Septiembre" },
      { value: 10, label: "Octubre" },
      { value: 11, label: "Noviembre" },
      { value: 12, label: "Diciembre" },
    ];

    const options = [];

    options.push(
      <option key="" value="">
      </option>
    );

    months.map((month) => {
      options.push(
        <option key={"mes-" + month.value} value={month.value}>
          {month.label}
        </option>
      );
    });

    return options;
  };

  const renderOptionsAnios = () => {
    const actualAnio = new Date().getFullYear();
    const iniciaAnio = 2023;
    const years = Array.from(
      { length: actualAnio - iniciaAnio + 1 },
      (_, index) => iniciaAnio + index
    ).reverse();

    const options = [];

    options.push(
      <option key="" value="">
      </option>
    );

    years.map((anio) => {
      options.push(
        <option key={"anio-" + anio} value={anio}>
          {anio}
        </option>
      );
    });

    return options;
  };

  const BotonVerAsistenciaCurso = ({ rbd, mes, anio, id_session_asistencia, handleOpenCursosAsistencia }) => {

    return (
      <>
        <IconButton
          aria-label="Ver Asistencia curso"
          onClick={() =>
            handleOpenCursosAsistencia(rbd, mes, anio, id_session_asistencia)
          }
        >
          <FormatListBulletedIcon />
        </IconButton>
      </>
    );
  };

  const renderTablaSesiones = () => {
    const tablaSession = [];
    const rowsTablaSession = [];

    const columns = [
      { field: 'id', headerName: 'ID', width: 30 },
      {
        field: 'colegio',
        headerName: 'Colegio',
        width: 200,
        editable: false,
      },
      {
        field: 'mes',
        headerName: 'Mes',
        width: 100,
        editable: false,
        renderCell: (params) => (
          <>
          {getMesByNumeroMes(params.row.mes)}
          </>
        ),
      },

      {
        field: 'anio',
        headerName: 'Año',
        width: 70,
        editable: false,
      },
      {
        field: 'estado',
        headerName: 'Estado',
        width: 200,
        editable: false,
      },
      {
        field: 'creado',
        headerName: 'Creado',
        width: 200,
        editable: false,
      },
      {
        field: 'actualizado',
        headerName: 'Actualizado',
        width: 200,
        editable: false,
      },
      {
        field: 'opciones',
        headerName: 'Opciones',
        width: 100,
        editable: false,
        renderCell: (params) => (
          <>
            <BotonVerAsistenciaCurso rbd={params.row.rbd} mes={params.row.mes} anio={params.row.anio} id_session_asistencia={params.id} handleOpenCursosAsistencia={handleOpenCursosAsistencia} />
          </>
        ),
      },

    ];

    const rows = [];

    if (Object.keys(listaSessionAsistenciaAlumnosSigeMineduc).length > 0) {
      listaSessionAsistenciaAlumnosSigeMineduc.forEach((item) => {
        let createdAtFecha = new Date(item.createdAt);
        let updatedAtFecha = new Date(item.updatedAt);
        
        let objTablaSessionAsistenciaCurso = {
          id: item.id_session_asistencia,
          rbd: item.rbd,
          colegio: capitalize(item.infocolegio.nombre),
          mes: item.mes,
          anio: item.anio,
          estado: getSessionEstadoByCode(item.estado),
          creado: createdAtFecha.toLocaleString(),
          actualizado: updatedAtFecha.toLocaleString(),
          //registros_almacenados: item.registrosAlmacenados,

        };

        rows.push(objTablaSessionAsistenciaCurso);

      });
    }

    tablaSession.push(
      <>
        <DataGrid
          autoHeight
          autoWidth
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
            sorting: {
              sortModel: [{ field: 'anio', sort: 'desc' }], // Ordenar por 'anio' de forma descendente
            },
          }}
          pageSizeOptions={[15]}
          checkboxSelection
          disableRowSelectionOnClick
          rowHeight={30}
          columnHeaderHeight={40}
        />
      </>
    );
    return tablaSession;
  };

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          background: "#ffffff",
          height: "auto",
          pt: "40px",
        }}
      >
        <ModulosBreadCrumbs menuItems={menuItems} />
        <Box>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: fontSizeTitle,
            }}
          >
            ASISTENCIA DE ALUMNO
          </Typography>
          <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />
          <Box sx={{ textAlign: "justify", m: "auto", pb: "20px" }}>
            <Typography
              variant="a"
              sx={{
                fontSize: fontSize,
              }}
            >
              Esta asistencia escolar permite obtener los datos completos de identificación de todos los alumnos, % de asistencia. Para importar la asistencia escolar de los alumnos desde Mineduc SIGE seleccione año y el colegio a importar, luego haga click en el botón "Importar Asistencia Escolar (Automatizado)", para importar de forma manual presione "Importar Asistencia Escolar (Manual)".
            </Typography>
          </Box>
          {isLoadUseEffect ? (
            <>
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ m: "auto" }}
                >
                  {statusLoading && <LoadingSpinner />}
                  <Box sx={{ "& > :not(style)": { m: 1 } }}>
                    <Grid container columns={10} spacing={2} style={{ margin: '0px', width: 'auto' }}>

                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2}>
                        <FormControl fullWidth>
                          <InputLabel variant="standard" htmlFor="colegio">
                            Colegio
                          </InputLabel>
                          <NativeSelect
                            defaultValue={0}
                            size="small"
                            inputProps={{
                              name: "colegio",
                              id: "colegio",
                            }}
                            onChange={(e) =>
                              setExtAsistenciaAlumnosRBD(e.target.value)
                            }
                          >
                            {renderOptionsColegios()}
                          </NativeSelect>
                        </FormControl>
                      </Grid>
                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2}>
                        <FormControl fullWidth>
                          <InputLabel variant="standard" htmlFor="mes">
                            Mes
                          </InputLabel>
                          <NativeSelect
                            defaultValue={extAsistenciaAlumnosMES}
                            size="small"
                            inputProps={{
                              name: "mes",
                              id: "mes",
                            }}
                            onChange={(e) => setExtAsistenciaAlumnosMES(e.target.value)}
                          >
                            {renderOptionsMonths()}
                          </NativeSelect>
                        </FormControl>
                      </Grid>
                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2}>
                        <FormControl fullWidth>
                          <InputLabel variant="standard" htmlFor="anio">
                            Año
                          </InputLabel>
                          <NativeSelect
                            defaultValue={extAsistenciaAlumnosANIO}
                            size="small"
                            inputProps={{
                              name: "anio",
                              id: "anio",
                            }}
                            onChange={(e) => setExtAsistenciaAlumnosANIO(e.target.value)}
                          >
                            {renderOptionsAnios()}
                          </NativeSelect>
                        </FormControl>
                      </Grid>
                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2}>
                        <Button
                          fullWidth
                          size="small"
                          variant="contained"
                          type="button"
                          onClick={() =>
                            CallBotsAsistencias(
                              extAsistenciaAlumnosRBD,
                              extAsistenciaAlumnosMES,
                              extAsistenciaAlumnosANIO
                            )
                          }
                          sx={{
                            fontSize: {
                              xl: 11,
                              lg: 11,
                              md: 10,
                              sm: 10,
                              xs: 10,
                            },
                            whiteSpace: "pre-line",
                          }}
                        >
                          Importar Asistencia Alumno
                          <br />
                          (Automatizado)
                        </Button>
                      </Grid>
                      <Grid item xs={10} sm={2} md={2} lg={2} xl={2}>
                        <Link to="/modulo/ilumen/asistencia_escolar/manual">

                          <Button
                            fullWidth
                            size="small"
                            variant="contained"
                            type="button"
                            sx={{
                              fontSize: {
                                xl: 11,
                                lg: 11,
                                md: 10,
                                sm: 10,
                                xs: 10,
                              },
                              whiteSpace: "pre-line",
                            }}
                          >
                            Importar Asistencia Alumno
                            <br />
                            (Manual)
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <LoadingSpinner />
            </>
          )}
          <Grid container spacing={2}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%", marginTop: "30px" }}
            >
              {renderTablaSesiones()}
            </Grid>
          </Grid>
          <>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%" }}
            >
              {openViewCursos && listaSessionAsistenciaAlumnosSigeMineduc && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ position: 'relative', backgroundColor: '#fff', zIndex: 1, textAlign: 'left', mt: "30px" }} >
                    <span>{getColegioByRBD(sessionRBD)}: Asistencia de alumnos {getMesByNumeroMes(sessionMes)} {sessionAnio} </span>
                  </Typography>
                  {renderListaCursos()}
                </>
              )}

              {openViewAsistencia && (
                <>
                  <Typography variant="h6" gutterBottom sx={{ position: 'relative', backgroundColor: '#fff', zIndex: 1, textAlign: 'left' }}>
                    <span>{getColegioByRBD(sessionRBD)}: {sessionCursoLetra} / {getMesByNumeroMes(sessionMes)} {sessionAnio} </span>
                  </Typography>
                  {renderAsistenciaAlumnos()}
                </>
              )}



            </Grid>
          </>

        </Box >
      </Container >
    </>
  );
}
