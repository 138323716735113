import { createTheme } from '@mui/material';

const drawerWidth = 240;

const theme_main = createTheme({
    palette: {
        background: {
            default: '#f4f4f4'  // Un color de fondo general para tu aplicación
        }
    },
    typography: {
        allVariants: {
            color: '#333'  // Define un color de texto general para todos los elementos de texto
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    color: '#333',  // Define explícitamente el color del texto del cuerpo
                    backgroundColor: '#f4f4f4'  // Puedes también definir el color de fondo del cuerpo aquí
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    textDecoration: 'none', // Removes text decoration from all Links
                    color: 'inherit', // Ensures the Link color inherits from the parent
                },
            },
        },
    }
});

export default theme_main;