import React, { useState, useContext, useEffect, useRef } from "react";
import { Container, Grid } from "@mui/material";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { IlumenContext } from "../contexts/IlumenContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const labels = ["Usuarios Registrados", "Colegios iLumen", "Directivos iLumen", "Profesores iLumen", "Perfiles SIGE iLumen"];
const barColors = [
  'rgba(255, 99, 132, 0.8)',   // Rojo
  'rgba(54, 162, 235, 0.8)',   // Azul
  'rgba(255, 206, 86, 0.8)',   // Amarillo
  'rgba(75, 192, 192, 0.8)',   // Verde
  'rgba(153, 102, 255, 0.8)'   // Púrpura
];



const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        padding: 20,
        generateLabels: (chart) => {
          return chart.data.labels.map((label, index) => ({
            text: label,
            fillStyle: barColors[index],
            strokeStyle: barColors[index],
            lineWidth: 0.5,
            hidden: false,
            index: index
          }));
        }
      }
    },
    title: {
      display: true,
      text: "Información Khronolatam",
    },
  },
  layout: {
    padding: {
      top: 30
    }
  }
};



export default function Dashboard() {

  const { getInfoGeneralDashboard , infoGeneralDashboard} = useContext(IlumenContext);

  useEffect( () => {
    getInfoGeneralDashboard();

    /* const intervalId = setInterval(async () => {
      await getInfoGeneralDashboard();
    }, 5000);

    return () => clearInterval(intervalId); */
  }, []);

  var set_data_informacion_general = [];
  if ( infoGeneralDashboard ) {
    set_data_informacion_general = [infoGeneralDashboard.usuarios, infoGeneralDashboard.colegios, infoGeneralDashboard.directivos, infoGeneralDashboard.profesor_jefe, infoGeneralDashboard.perfiles_sige,  ]
  }
  var midata = {
    labels: labels,
    datasets: [
      {
        label: 'Cantidad',
        data: set_data_informacion_general,
        backgroundColor: barColors,
        borderColor: barColors.map(color => color),
        hoverBackgroundColor: barColors.map(color => color),
        borderWidth: 1,
      },
    ]
  };

  return (
    <Container maxWidth={false} sx={{ background: "#ffffff", height: "auto", pt: "64px" }}>
      <Grid container spacing={2} >
        <Grid item
          xl={8}
          lg={12}
          md={12}
          sm={12}
          xs={12} sx={{ margin: "auto" }}>
          <Bar data={midata} options={options} plugins={[ChartDataLabels]} />
        </Grid>
      </Grid>
    </Container>
  );
}
