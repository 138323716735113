import React, { useContext } from "react";
import { EncuestaContext } from "../contexts/EncuestaContext";
import { IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Zoom } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

function TablaDimension() {
    const { dimensionList, deleteDimension } = useContext(EncuestaContext);
    const contenido = dimensionList;

  return (
    <>
      <TableContainer sx={{ m: "auto", mt: "30px" }} component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Dimensión</TableCell>
              <TableCell>Configuración</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contenido.map((item) => (
              <TableRow
                key={item.id_dimension}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {item.nombre}
                </TableCell>
                <TableCell>
                  <Stack direction="row">
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Eliminar"
                      placement="top"
                    >
                      <IconButton
                        aria-label="delete"
                        onClick={() => deleteDimension(item.id_dimension)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TablaDimension;
