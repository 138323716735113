import { Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import TabsEncuesta from "../components/TabsEncuesta/TabsEncuesta";

const API_ADMIN_PROTO = process.env.REACT_APP_API_ADMIN_PROTO;
const API_ADMIN_HOST = process.env.REACT_APP_API_ADMIN_HOST;
const API_ADMIN_PORT = process.env.REACT_APP_API_ADMIN_PORT;

export default function SettingEncuesta() {
  const { id } = useParams();
  const [encuestaId, setEncuestaId] = useState(null);
  const [encuestaNombre, setEncuestaNombre] = useState(null);
  const [encuestaTipo, setEncuestaTipo] = useState(null);
  const [encuestaAnio, setEncuestaAnio] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getEncuestaById(id);
  }, [id]);

  const getEncuestaById = async (id) => {
    try {
      const response = await axios.get(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/encuestas/${id}`,{ withCredentials: true });
      if (response.data.success) {
        //setEncuestaList(response.data.encuesta);
        //console.log(response.data.encuesta);
        setEncuestaId(response.data.encuesta.id_encuesta);
        setEncuestaNombre(response.data.encuesta.nombre);
        setEncuestaTipo(response.data.encuesta.TipoEncuestum.nombre);
        setEncuestaAnio(response.data.encuesta.anio);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return <>&nbsp;</>; // Mostrar un mensaje de carga mientras isLoading sea true
  }

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          background: "#ffffff",
          height: "auto",
          pt: "64px",
        }}
      >
        <TabsEncuesta
          encuestaId={encuestaId}
          encuestaNombre={encuestaNombre}
          encuestaTipo={encuestaTipo}
          encuestaAnio={encuestaAnio}
        />
      </Container>
    </>
  );
}
