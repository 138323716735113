export const obtenerDiasDelMes = (anio, mes) => {

  if (!validarEntrada(anio, mes)) {
    return false;
  } else {

    const diasDelMes = [];
    const nombreDiaSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

    const primerDiaDelMes = new Date(anio, mes - 1, 1);
    const ultimoDiaDelMes = new Date(anio, mes, 0).getDate();

    for (let dia = 1; dia <= ultimoDiaDelMes; dia++) {
      const fecha = new Date(anio, mes - 1, dia);
      // Obtenemos los componentes de la fecha
      const diaFormat = fecha.getDate().toString().padStart(2, '0');
      const mesFormat = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const anioFormat = fecha.getFullYear()
      // Formateamos la fecha en el estilo deseado
      const fechaFormateada = `${diaFormat}-${mesFormat}-${anioFormat}`;
      const nombreDia = nombreDiaSemana[fecha.getDay()];
      diasDelMes.push({ numeroDia: dia, nombreDia: nombreDia, fecha: fechaFormateada });
    }

    return diasDelMes;
  }
};



export const nombreMes = [
  'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
  'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];

export const limpiarHTML = (html) => {
  const temporalDiv = document.createElement("div");
  temporalDiv.innerHTML = html;
  return temporalDiv.textContent || temporalDiv.innerText || "";
}

export const validarEntrada = (anio, mes) => {
  if (isNaN(anio) || isNaN(mes) || anio < 0 || mes < 1 || mes > 12) {
    return false;
  } else {
    return true;
  }
};

export const capitalize = (frase) => {
  if (typeof frase !== 'string') {
      // Puedes devolver un valor predeterminado o lanzar un error
      return ''; // Valor predeterminado
      // O lanzar un error:
      // throw new Error('La función capitalize espera una cadena como argumento');
  }
  return frase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}

export const fontSize = {
  xl: "16px",
  lg: "16px",
  md: "16px",
  sm: "15px",
  xs: "15px",
}

export const fontSizeTitle = {
  xl: "19px",
  lg: "19px",
  md: "18px",
  sm: "16px",
  xs: "16px",
}

export const fontSizeTable = {
  xl: "12px",
  lg: "12px",
  md: "12px",
  sm: "12px",
  xs: "11px",
}

export const fontSizeDialog = {
  xl: "14px",
  lg: "14px",
  md: "14px",
  sm: "14px",
  xs: "14px",
}


export const  generateLargeRandomNumber = (length) => {
  let result = '';
  for (let i = 0; i < length; i++) {
      result += Math.floor(Math.random() * 10); // Genera un dígito aleatorio de 0 a 9
  }
  return result;
}

