import React, { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const MineducContext = createContext();
export const NOTIFICATIONTIME = process.env.REACT_APP_NOTIFICATIONTIME;

const API_ADMIN_PROTO = process.env.REACT_APP_API_ADMIN_PROTO;
const API_ADMIN_HOST = process.env.REACT_APP_API_ADMIN_HOST;
const API_ADMIN_PORT = process.env.REACT_APP_API_ADMIN_PORT;

const API_SIGE_PROTO = process.env.REACT_APP_API_SIGE_PROTO;
const API_SIGE_HOST = process.env.REACT_APP_API_SIGE_HOST;
const API_SIGE_PORT = process.env.REACT_APP_API_SIGE_PORT;

export const MineducProvider = ({ children }) => {
  const [isLoadUseEffect, setIsLoadUseEffect] = useState(null);

  //#region /*BEGIN LLAMA LA INFORMACION BASICA DE TODOS LOS COLEGIOS*/
  const [listaColegios, setListaColegios] = useState(false);
  const getAllColegios = async () => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/colegios/`,{ withCredentials: true }
      );
      if (response.data.success) {
        setListaColegios(response.data.colegios);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /* END LLAMA LA INFORMACION BASICA DE TODOS LOS COLEGIOS */
  //#endregion

  //#region /*BEGIN LLAMA LA INFORMACION BASICA DEL COLEGIO*/
  const getColegioByRBD = async (rbd) => {
    const formbyrbd = { rbd: rbd };
    try {
      const response = await axios.post(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/colegio/`,
        formbyrbd,{ withCredentials: true }
      );
      if (response.data.success) {
        return response.data.colegio;
      }
    } catch (error) {
      console.error(error);
    }
  };
  /* END LLAMA LA INFORMACION BASICA DEL COLEGIO */
  //#endregion

  //#region /*BEGIN LLAMA LA EJECUCION DE EXTRACCION AUTOMATICA NOMINA ALUMNO*/
  const [estadoCallBot, setEstadoCallBot] = useState(false);
  const callBotNominaAlumno = async (rbd, anio) => {
    const formNomina = { rbd: rbd, anio: anio };
    try {
      toast.success("Inicia Extración Automatizada Mineduc Sige.", {
        position: "bottom-left",
        autoClose: NOTIFICATIONTIME,
      });
      const response = await axios.post(
        `${API_SIGE_PROTO}://${API_SIGE_HOST}:${API_SIGE_PORT}/sige/nomina_alumno/download`,
        formNomina,{ withCredentials: true }
      );
      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  /* END LLAMA LA EJECUCION DE EXTRACCION AUTOMATICA NOMINA ALUMNO */
  //#endregion

  
  //const [estadoCallBot, setEstadoCallBot] = useState(false);
  const callBotAsistenciaAlumnos = async (rbd, mes, anio) => {
    const formAsistencia = { rbd: rbd, mes: mes, anio: anio };
    try {
      toast.success("Inicia Extración Automatizada Mineduc Sige.", {
        position: "bottom-left",
        autoClose: NOTIFICATIONTIME,
      });
      const response = await axios.post(
        `${API_SIGE_PROTO}://${API_SIGE_HOST}:${API_SIGE_PORT}/asistencia/alumnomensual`,
        formAsistencia,{ withCredentials: true }
      );
      if (response.data.success) {
        if (response.data.message === "Sistema Cerrado por Declaración de Asistencia..."){
          toast.error(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        }else{
          toast.success(response.data.message, {
            position: "bottom-left",
            autoClose: NOTIFICATIONTIME,
          });
        }
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const callBotAsistenciaCursos = async (rbd, mes) => {
  //   const formAsistencia = { rbd: rbd, mes: mes };    
  //   try {
  //     toast.success("Inicia Extración Automatizada Mineduc Sige.", {
  //       position: "bottom-left",
  //       autoClose: NOTIFICATIONTIME,
  //     });
  //     const response = await axios.post(
  //       `${API_SIGE_PROTO}://${API_SIGE_HOST}:${API_SIGE_PORT}/asistencia/resumenmensual`,
  //       formAsistencia,{ withCredentials: true }
  //     );
  //     if (response.data.success) {
  //       if (response.data.message === "Sistema Cerrado por Declaración de Asistencia..."){
  //         toast.error(response.data.message, {
  //           position: "bottom-left",
  //           autoClose: NOTIFICATIONTIME,
  //         });
  //       }else{
  //         toast.success(response.data.message, {
  //           position: "bottom-left",
  //           autoClose: NOTIFICATIONTIME,
  //         });
  //       }
  //     } else {
  //       toast.error(response.data.message, {
  //         position: "bottom-left",
  //         autoClose: NOTIFICATIONTIME,
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  const CallBotsAsistencias = async (rbd, mes, anio) => {
    callBotAsistenciaAlumnos( rbd, mes, anio );
    //callBotAsistenciaCursos( rbd, mes );
  }

  //#region /*BEGIN LISTA DE SESIONES PARA CARGA MANUAL */
  const [listaSessionNominaAlumno, setListaSessionNominaAlumno] = useState({});
  const getSessionNominaAlumno = async () => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/session/nomina_alumno/lista`,{ withCredentials: true }
      );
      setListaSessionNominaAlumno(response.data.sesiones);
      setIsLoadUseEffect(true);
    } catch (error) {
      console.error(error);
    }
  };
  //#endregion LISTA DE SESIONES PARA CARGA MANUAL


  //#region /*BEGIN LISTA DE SESIONES DE ASISTENCIA ALUMNOS */
  const [listaSessionAsistenciaAlumnosSigeMineduc, setListaSessionAsistenciaAlumnosSigeMineduc] = useState({});
  const getSessionAsistenciaAlumnosSigeMineduc = async () => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/session/asistencia_alumno/lista`,{ withCredentials: true }
      );
      setListaSessionAsistenciaAlumnosSigeMineduc(response.data.sesiones);
      setIsLoadUseEffect(true);
    } catch (error) {
      console.error(error);
    }
  };
  /* END LLAMA LA EJECUCION DE EXTRACCION AUTOMATICA NOMINA ALUMNO */
  //#endregion

  //#region /*BEGIN LISTA DE SESIONES DE ASISTENCIA ALUMNOS */
  const [listaSessionAsistenciaAlumnosManual, setListaSessionAsistenciaAlumnosManual] = useState({});
  const getSessionAsistenciaAlumnosManual = async () => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/session/manual_asistencia/lista`,{ withCredentials: true }
      );
      setListaSessionAsistenciaAlumnosManual(response.data.sesiones);
      setIsLoadUseEffect(true);
    } catch (error) {
      console.error(error);
    }
  };
  /* END LLAMA LA EJECUCION DE EXTRACCION AUTOMATICA NOMINA ALUMNO */
  //#endregion

  //#region /*BEGIN LISTA DE SESIONES DE ESTADO */
  const [listaSessionEstadoSigeMineduc, setListaSessionEstadoSigeMineduc] =
    useState({});
  const getSessionEstadoSigeMineduc = async () => {
    try {
      const response = await axios.get(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/session/sige/estados`,{ withCredentials: true }
      );
      setListaSessionEstadoSigeMineduc(response.data.estado);
    } catch (error) {
      console.error(error);
    }
  };

  
  const [listNominaAlumnos, setListNominaAlumnos] = useState(null);

  const getNominaAlumnos = async (rbd, anio) => {
    const formNomina = { rbd: rbd, anio: anio };
    try {
      const response = await axios.post(
        `${API_SIGE_PROTO}://${API_SIGE_HOST}:${API_SIGE_PORT}/nomina_alumno/read`,
        formNomina,
        {
          withCredentials: true,
        }
      );

      if (response.data.success) {
        setListNominaAlumnos(response.data.nominaalumnos);
      } else {
        toast.error("Por favor, intente nuevamente.", {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const getNominaAlumnosDB = async (rbd, anio) => {
    const formNomina = { rbd: rbd, anio: anio };
    
    try {
      const response = await axios.post(
        `${API_SIGE_PROTO}://${API_SIGE_HOST}:${API_SIGE_PORT}/nomina_ensenanza/colegio`,
        formNomina,
        {
          withCredentials: true,
          timeout: 90000 // 10 segundos de tiempo de espera
        }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
        return response.data.nomina;
      } else {
        toast.error("Por favor, intente nuevamente.", {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });        
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });        
      } else {
        toast.error("Ha ocurrido un error.", {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });  
      }
    }
  };
  /* END EXTRACCION AUTOMATICA NOMINA ALUMNO VISUALIZAR  */
  //#endregion

  //#region /*BEGIN EXTRACCION AUTOMATICA ASISTENCIA ALUMNO VISUALIZAR */
  const [listAsistenciaAlumnos, setListAsistenciaAlumnos] = useState(null);
  const [infoAsistenciaAlumno, setInfoAsistenciaAlumno] = useState(null);
  /*
  const [slicedData, setSlicedData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);*/

  const getAsistenciaAlumnos = async (rbd, mes, anio, codigo, grado, letra) => {
    const pagina = 1;
    const limite = 10;
    const formAsistencia = { rbd, mes, anio, codigo, grado, letra };
    try {
      const response = await axios.post(
        `${API_SIGE_PROTO}://${API_SIGE_HOST}:${API_SIGE_PORT}/asistencia/readAlumnosMensual`,
        formAsistencia,{ withCredentials: true }
      );

      if (response.data.success) {
        setListAsistenciaAlumnos(response.data.nominaAlumnos); // nomina de alumnos
        setInfoAsistenciaAlumno(response.data.asistencias); // asistencias segun curso
      } else {
        toast.error("Por favor, intente nuevamente.", {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [responseUploadAsistenciaAlumnoManual, setResponseUploadAsistenciaAlumnoManual] = useState(null);
  const uploadAsistenciaAlumnoManual = async (id_colegio, mes, anio, data) => {
    const formAsistencia = { id_colegio: id_colegio, _mes:mes, _anio:anio, File: data };
    try {

      toast.success("Inicia proceso de almacenamiento.", {
        position: "bottom-left",
        autoClose: NOTIFICATIONTIME,
      });

      const response = await axios.post(
        `${API_SIGE_PROTO}://${API_SIGE_HOST}:${API_SIGE_PORT}/asistencia_alumno/uploadFile`,
        formAsistencia,{ withCredentials: true, headers: { 'Content-Type': 'multipart/form-data' } }
      );
      
      if (response.data.success) {
        setResponseUploadAsistenciaAlumnoManual(response.data);
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [responseUploadNominaAlumnoManual, setResponseUploadNominaAlumnoManual] = useState(null);
  const uploadNominaAlumno = async (id_colegio, anio, data) => {
    const formAsistencia = { id_colegio: id_colegio, File: data, anio: anio };
    console.log(formAsistencia);
    try {
      const response = await axios.post(
        `${API_SIGE_PROTO}://${API_SIGE_HOST}:${API_SIGE_PORT}/nomina_alumno/uploadFile`,
        formAsistencia,{ withCredentials: true, headers: { 'Content-Type': 'multipart/form-data' } }
      );
      console.log(response);
      if (response.data.success) {
        setResponseUploadNominaAlumnoManual(response.data);
        toast.success(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
        
      } else {
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });        
      } else {
        toast.error("Ha ocurrido un error.", {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });  
      }
    }
  };

  const [listTipoEnsenanza, setListTipoEnsenanza] = useState(null);
  const getAllTipoEnsenanza = async () => {
    try {
      const response = await axios.get(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/tipo_ensenanza`,{ withCredentials: true });
      setListTipoEnsenanza(response.data.tipo_ensenanza);
    } catch (error) {
      console.error(error);
    }
  };
  

  /* END LLAMA LA EJECUCION DE EXTRACCION AUTOMATICA NOMINA ALUMNO */
  const contextValues = {
    isLoadUseEffect,
    getAllColegios,
    listaColegios,

    callBotNominaAlumno,

    getSessionNominaAlumno,
    listaSessionNominaAlumno,

    getSessionEstadoSigeMineduc,
    listaSessionEstadoSigeMineduc,

    getNominaAlumnos,
    getNominaAlumnosDB,

    listNominaAlumnos,
    setListNominaAlumnos,
    
    uploadNominaAlumno,
    responseUploadNominaAlumnoManual,
    setResponseUploadNominaAlumnoManual,

    //Asistencia
    callBotAsistenciaAlumnos,
    getSessionAsistenciaAlumnosSigeMineduc,
    getSessionAsistenciaAlumnosManual,

    setListaSessionAsistenciaAlumnosSigeMineduc,
    listaSessionAsistenciaAlumnosSigeMineduc,
    setListaSessionAsistenciaAlumnosManual,
    listaSessionAsistenciaAlumnosManual,
    CallBotsAsistencias,

    getAsistenciaAlumnos,
    listAsistenciaAlumnos,
    
    infoAsistenciaAlumno,
    
    setListAsistenciaAlumnos,
    
    setInfoAsistenciaAlumno,
    
    
    uploadAsistenciaAlumnoManual,
    responseUploadAsistenciaAlumnoManual,
    getAllTipoEnsenanza,
    listTipoEnsenanza
  };

  return (
    <MineducContext.Provider value={contextValues}>
      {children}
    </MineducContext.Provider>
  );
};
