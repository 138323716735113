import {
  Box,
  FormControl,
  InputLabel,
  LinearProgress,
  NativeSelect,
} from "@mui/material";

import React, { useState, useContext, useEffect, useRef } from "react";



import { EncuestaContext } from "../../contexts/EncuestaContext";
import './TablaDesarrollo.css';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { DataGrid } from "@mui/x-data-grid";
import OpcionesConfig from "./OpcionesConfig";
import { limpiarHTML } from "../../utils/utils";

const API_ADMIN_PROTO = process.env.REACT_APP_API_ADMIN_PROTO;
const API_ADMIN_HOST = process.env.REACT_APP_API_ADMIN_HOST;
const API_ADMIN_PORT = process.env.REACT_APP_API_ADMIN_PORT;

const style = {
  backgroundColor: "#fff",
  width: "100%",
  maxWidth: "98%",
  maxHeight: "90vh",
  overflowY: "auto",
  padding: 2,
  border: "10px solid #d77b00",
};



const SelectNivelDesarrollo = ({ id_encuesta, id_desarrollo, id_desarrollo_encuesta, tableRownPractica, renderOptionsDesarrollo }) => {

  const { setPracticaDesarrollo, getDesarrolloEncuesta } = useContext(EncuestaContext);

  const handlerSetDesarrolloPractica = async (id_encuesta, id_desarrollo_encuesta, id_desarrollo) => {
    console.error(id_encuesta, id_desarrollo_encuesta, id_desarrollo);
    await setPracticaDesarrollo(id_desarrollo_encuesta, id_desarrollo);
    await getDesarrolloEncuesta(id_encuesta);
    /*
    await deletePractica(idEncuesta, id_practica);
    if (typeof tableRownPractica === 'function') {
      tableRownPractica();
    }*/
  };

  return (
    <FormControl>
      <InputLabel variant="standard" htmlFor="">
        Desarrollo
      </InputLabel>
      <NativeSelect
        defaultValue={id_desarrollo}
        size="small"
        inputProps={{
          name: `select-desarrollo-${id_desarrollo_encuesta}`,
          id: `select-desarrollo-${id_desarrollo_encuesta}`,
        }}
        onChange={(e) =>
          handlerSetDesarrolloPractica(
            id_encuesta,
            id_desarrollo_encuesta,
            e.target.value
          )
        }
      >
        <option key="   " value="    "></option>
        {renderOptionsDesarrollo()}
      </NativeSelect>
    </FormControl>
  );
};

const TablaDesarrollo = (props) => {
  const propsIdEncuesta = props.id_encuesta;

  const {
    desarrolloList,
    desarrolloEncuestaList,
  } = useContext(EncuestaContext);


  const renderOptionsDesarrollo = () => {
    const options = [];
    //console.log("renderOptionsDesarrollo:" + id_desarrollo);
    if (Object.keys(desarrolloList).length > 0) {
      desarrolloList.forEach((item) => {
        //console.log(item);

        options.push(
          <option key={item.id_desarrollo} value={item.id_desarrollo}>
            {item.nombre}
          </option>
        );
      });
    }

    return options;
  };

  const [rows, setRows] = useState([]);

  useEffect(() => {
//    console.log("TablaDesarrollo")
    renderTableRownDesarrolloEncuesta();
  }, [desarrolloEncuestaList]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'npractica',
      headerName: 'Numero Práctica',
      width: 200,
      editable: false,
    },
    {
      field: 'practica',
      headerName: 'Práctica',
      width: 100,
      editable: false,
    },
    {
      field: 'desarrollo_encuesta',
      headerName: 'Desarrollo Encuesta',
      width: 600,
      editable: false,
    },
    {
      field: 'nivel_desarrollo',
      headerName: 'Nivel de Desarrollo',
      width: 160,
      editable: false,
      renderCell: (params) => (
        <>
          <SelectNivelDesarrollo
            id_encuesta={params.row.id_encuesta}
            id_desarrollo={params.row.id_desarrollo}
            id_desarrollo_encuesta={params.id}
            renderOptionsDesarrollo={renderOptionsDesarrollo}
          />
        </>
      ),
    },/*
    {
      field: 'countPreguntas',
      headerName: 'Preguntas',
      width: 160,
      editable: false,
      renderCell: (params) => (
        <>
          {params.row.countPreguntas}
        </>
      ),
    },*/
    {
      field: 'configuracion',
      headerName: 'Configuración',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <>
          <OpcionesConfig params={params} 
          dimension={params.row.dimension}
          subdimension={params.row.subdimension}
          npractica={params.row.npractica}
          practica={params.row.practica_html}
          desarrollo_encuesta={params.row.desarrollo_encuesta_html}
          id_encuesta={params.row.id_encuesta} 
          id_desarrollo={params.row.id_desarrollo}
          id_desarrollo_encuesta={params.id} 
          renderTableRownDesarrolloEncuesta={renderTableRownDesarrolloEncuesta} />
        </>
      )
    },
  ];

  function countPreguntas(objPregunta) {
    let cantidadPreguntas = 0;
    cantidadPreguntas = parseInt(objPregunta.length);
    return cantidadPreguntas;
  }

  const renderTableRownDesarrolloEncuesta = () => { // recuperar datos
    let contenido = desarrolloEncuestaList;
    const idEncuesta = contenido.id_encuesta;
    setRows([]);
    //console.log(contenido);
    if (Object.keys(contenido).length > 0) {
      contenido = contenido.Dimensions;
      contenido.forEach((dimension) => {
        let subdimension = dimension.SubDimensions;
        subdimension.forEach((infosubdimension) => {
          let numeropractica = infosubdimension.NumeroPracticas;
          numeropractica.forEach((infonumeropractica) => {
            let practica = infonumeropractica.Practicas;
            practica.forEach((infopractica) => {
              let desarrolloEncuesta = infopractica.DesarrolloEncuesta;
              desarrolloEncuesta.forEach((infoDesarrolloEncuesta) => {
                //console.error(infoDesarrolloEncuesta);
                let objTablaDesarrolloEncuesta = {
                  id: infoDesarrolloEncuesta.id_desarrollo_encuesta,
                  id_encuesta: idEncuesta,
                  dimension: dimension.nombre,
                  subdimension: infosubdimension.nombre,
                  npractica: infonumeropractica.nombre,
                  practica_html: infopractica.nombre,
                  practica: limpiarHTML(infopractica.nombre),
                  id_desarrollo: infoDesarrolloEncuesta.id_desarrollo,
                  desarrollo_encuesta_html: infoDesarrolloEncuesta.nombre,
                  desarrollo_encuesta: limpiarHTML(infoDesarrolloEncuesta.nombre),
                  countPreguntas: countPreguntas(infoDesarrolloEncuesta.EncuestaPregunta)
                };

                //console.log(objTablaDesarrolloEncuesta);
                setRows((prevRows) => [...prevRows, objTablaDesarrolloEncuesta]);

              });
            });
          });
        });
      });
    }

  };

  if (rows.length == 0) {
    return (
      <>
        <Box
          sx={{
            width: "100%",
            maxWidth: { xs: 200, md: 200, lg: 250, xl: 250 },
          }}
        />
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </>

    );
  }

  return (
    <>
      {rows && rows.length > 0 && (

        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 6,
              },
            },
          }}
          pageSizeOptions={[6]}
          checkboxSelection
          disableRowSelectionOnClick
          rowHeight={50}
          columnHeaderHeight={40}
        />

      )}
    </>
  );
};

export default TablaDesarrollo;
