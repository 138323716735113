import {
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Zoom,
} from "@mui/material";
import React, { useEffect, useContext, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

import { EncuestaContext } from "../contexts/EncuestaContext";

const EliminaNpractica = ({ id_numero_practica, tableRownNPractica }) => {
  const { deleteNPractica, idEncuesta } = useContext(EncuestaContext);

  const handleDeleteNPractica = async () => {
    await deleteNPractica(idEncuesta, id_numero_practica);
    if (typeof tableRownNPractica === 'function') {
      tableRownNPractica();
    }
  };

  return (
    <Stack direction="row">
      <Tooltip TransitionComponent={Zoom} title="Eliminar" placement="top">
        <IconButton aria-label="delete" onClick={handleDeleteNPractica}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

function TablanPractica() {
  const { nPracticaList, idEncuesta } = useContext(EncuestaContext);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    renderTableRownNPractica();
  }, [nPracticaList]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'dimension',
      headerName: 'Dimensión',
      width: 200,
      editable: false,
    },
    {
      field: 'subdimension',
      headerName: 'SubDimensión',
      width: 300,
      editable: false,
    },
    {
      field: 'npractica',
      headerName: 'N° de Práctica',
      width: 300,
      editable: false,
    },
    {
      field: 'configuracion',
      headerName: 'Configuración',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <EliminaNpractica id_numero_practica={params.id} tableRownNPractica={renderTableRownNPractica} />
      )
    },
  ];

  const renderTableRownNPractica = () => {
    let contenido = nPracticaList;
    setRows([]);
    if (Object.keys(contenido).length > 0) {
      contenido = contenido.Dimensions;
      contenido.forEach((dimension) => {
        let subdimension = dimension.SubDimensions;
        subdimension.forEach((infosubdimension) => {
          let numeropractica = infosubdimension.NumeroPracticas;
          numeropractica.forEach((infonumeropractica) => {

            let objTablaNPractica = {
              id: infonumeropractica.id_numero_practica,
              dimension: dimension.nombre,
              subdimension: infosubdimension.nombre,
              npractica: infonumeropractica.nombre,
            };

            setRows((prevRows) => [...prevRows, objTablaNPractica]);

          });
        });
      });
    }

  };

  if (rows.length == 0) {
    return (
      <>
        <Box
          sx={{
            width: "100%",
            maxWidth: { xs: 200, md: 200, lg: 250, xl: 250 },
          }}
        />
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </>

    );
  }

  console.log(rows);

  return (
    <>
      {rows && rows.length > 0 && (
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[15]}
          checkboxSelection
          disableRowSelectionOnClick
          rowHeight={50}
          columnHeaderHeight={40}
        />

      )}
    </>
  );
}

export default TablanPractica;
