import { Container, Grid } from "@mui/material";
import React from "react";
import CardModules from "../../components/Dashboard/CardModules/CardModules";
import logoGoPME from "../../assets/modules/app-gopme.png";
import logoIlumen from "../../assets/modules/app-ilumen.png";
import ModulosBreadCrumbs from "./ModulosBreadCrumbs/ModulosBreadCrumbs";
import HomeIcon from "@mui/icons-material/Home";


export default function Modulos() {
  let menuItems = [
    {
      href: "/",
      text: "Home",
      componenteIcon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          background: "#ffffff",
          height: "auto",
          pt: "40px",
        }}
      >
        <ModulosBreadCrumbs menuItems={menuItems} />

        <Grid container spacing={2} sx={{ mb: "20px" }}>
          <CardModules
            index="1"
            link="/modulo/gopme"
            title="goPME"
            image={logoGoPME}
            color="#4e9dff"
            subcontenido="Generación de Encuestas y Carga de Asistencia Escolar es una herramienta integral diseñada para apoyar la gestión educativa en instituciones escolares. Este módulo combina tres funciones clave para mejorar la calidad de la educación y la toma de decisiones informadas."
          />
          <CardModules
            index="2"
            link="/modulo/ilumen"
            title="iLumen"
            image={logoIlumen}
            color="#4e9dff"
            subcontenido="Este módulo brinda a las instituciones educativas una plataforma centralizada para administrar y monitorear diversos aspectos relacionados con los estudiantes. Al automatizar y optimizar estos procesos, se mejora la eficiencia operativa, la toma de decisiones basada en datos y la calidad de la experiencia educativa."
          />
        </Grid>
      </Container>
    </>
  );
}
