import { Container, Grid } from "@mui/material";
import React from "react";
import CardModules from "../components/Dashboard/CardModules/CardModules";
import logoPME from "../assets/modules/pme_encuesta.png";
import imgPlanEid from "../assets/modules/planificacion_eid.png";


import HomeIcon from "@mui/icons-material/Home";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ModulosBreadCrumbs from "./Modulos/ModulosBreadCrumbs/ModulosBreadCrumbs";

export default function ModuloPME() {
  let menuItems = [
    {
      href: "/",
      text: "Home",
      componenteIcon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulos",
      text: "Modulos",
      componenteIcon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          background: "#ffffff",
          height: "auto",
          pt: "40px",
        }}
      >
        <ModulosBreadCrumbs menuItems={menuItems} />
        <Grid container spacing={2} sx={{ mb: "20px" }}>
          <CardModules
            index="1"
            link="/modulo/gopme/encuesta"
            title="Diagnostico EID"
            image={logoPME}
            color="#4e9dff"
            subcontenido="Gestión y creación de encuestas orientadas a la evaluación docente o escolar es una herramienta diseñada para facilitar la creación y administración de encuestas utilizadas en el ámbito educativo. Este módulo permite a los usuarios crear encuestas personalizadas, configurar preguntas y respuestas, y recopilar y analizar los datos obtenidos."
          />
          <CardModules
            index="1"
            link="/modulo/gopme/planificar_encuesta"
            title="Planificación de encuestas EID"
            image={imgPlanEid}
            color="#4e9dff"
            subcontenido="El panel de planificación de encuestas ofrece un listado de colegios y brinda la capacidad de notificar y configurar el inicio de encuestas, además de proporcionar una visualización de las analíticas relacionadas con dichas encuestas."
          />

          
        </Grid>
      </Container>
    </>
  );
}
