import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {
    Button,
    Container,
    Divider,
    Grid,
    LinearProgress,
    Paper,
    Stack,
    Typography,
} from "@mui/material";

import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import { Mail, Fingerprint } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ContestaEncuestaContext } from "../../contexts/ContestaEncuestaContext";

import imagenMain from "../../assets/logoMain.png";
import ProgressBar from "../../components/ProgressBar";
import BoxEncuesta from "../../components/ContestarEncuesta/BoxEncuesta";

const steps = [
    {
        id_pregunta_encuesta: 996,
        titulo: 'Gestión Pedagógica',
        pregunta: `¿El sostenedor no establece definiciones claras, que orientan su quehacer educativo, por medio del PEI ajustado a la normativa vigente?`,
        si: "Si",
        no: "No",
        sn: "No sabe",
    },
    {
        id_pregunta_encuesta: 997,
        titulo: 'Liderazgo',
        pregunta: `¿El sostenedor no da cuenta pública anual sobre el desempeño general de los establecimientos a su cargo?`,
        si: "Si",
        no: "No",
        sn: "No lo conoce",
    },
    {
        id_pregunta_encuesta: 996,
        titulo: 'Formación y convivencia',
        pregunta: `En el caso que dé cuenta anual, ¿la cuenta tiene resultados incorrectos o tergiversados?`,
        si: "Si monitorea",
        no: "No monitorea",
        sn: "No sabe",
    },
    {
        id_pregunta_encuesta: 995,
        titulo: 'Gestión de recursos',
        pregunta: `En el caso que dé cuenta anual, ¿la cuenta tiene resultados incorrectos o tergiversados?`,
        si: "Si promueve",
        no: "No promueve",
        sn: "No sabe",
    },
];

export default function IniciaEncuesta() {
    const { infoPregunta, getPreguntaEncuesta } = useContext(ContestaEncuestaContext);
    const { isAuthenticated, setIsAuthenticated } = useState(false);

    const cantidadPreguntas = 4; // Cambia esto al número total de preguntas

    const [preguntaActual, setPreguntaActual] = useState(1);
    const [currentPregunta, setCurrentPregunta] = useState(0);
    const [selectedValue, setSelectedValue] = useState("");

    useEffect(() => {
        getPreguntaEncuesta();
        console.log(infoPregunta);
      }, [getPreguntaEncuesta]);
    
    const avanzar = () => {
        if (preguntaActual < cantidadPreguntas) {
            setPreguntaActual(preguntaActual + 1);
            if (currentPregunta < steps.length - 1) {
                setCurrentPregunta(currentPregunta + 1);
                console.log("id pregunta : " + steps[currentPregunta].id_pregunta_encuesta);
                console.log("enviar : " + selectedValue);
                setSelectedValue("");
            }
        }

    };

    const retroceder = () => {
        if (preguntaActual > 1) {
            setPreguntaActual(preguntaActual - 1);
            if (currentPregunta > 0) {
                setCurrentPregunta(currentPregunta - 1);
              }
        }
    };

    console.log(steps[currentPregunta]);



    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
    });

    const { email, password } = inputValue;

    const handleOnChange = (e) => {

        const { name, value } = e.target;
        setInputValue({
            ...inputValue,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            toast.error("Debe ingresar todos los datos solicitados.", {
                position: "bottom-left",
                autoClose: 1000,
            });

            return;
        }

        try {
            //login(inputValue);
        } catch (error) {
            toast.error(error, {
                position: "bottom-left",
                autoClose: 1000,
            });
        }

        /*
        setInputValue({
          ...inputValue,
          email: "",
          password: "",
        });*/
    };

    if (isAuthenticated) {
        // Renderiza un estado de carga mientras se realiza la validación de la sesión
        //navigate("/");
        //navigate(0);
        /*
        return (
          <>
            <Container
              maxWidth="lg"
              sx={{
                marginTop: "100px",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            </Container>
            
          </>
          
        );*/
    }

    return (
        <>
            <Box
                component="img"
                sx={{
                    width: "100%",
                    //maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 200, md: 200, lg: 250, xl: 250 },
                }}
                alt="Khrono Latam EdTech"
                src={imagenMain}
            />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: "auto" }}>
                <ProgressBar cantidadPreguntas={cantidadPreguntas} preguntaActual={preguntaActual} />

            </div>
            <BoxEncuesta 
                titulo={steps[currentPregunta].titulo} 
                pregunta={steps[currentPregunta].pregunta} 
                respuestaSi={steps[currentPregunta].si} 
                respuestaNo={steps[currentPregunta].no} 
                respuestaSN={steps[currentPregunta].sn} 
                radioSelected={selectedValue} 
                setRadioSelected={setSelectedValue}
            />
            <Button
                variant="contained" size="medium" style={{ margin: "15px" }}
                onClick={retroceder}
            >
                Volver
            </Button>
            <Button
                variant="contained" size="medium" style={{ margin: "15px" }}
                onClick={avanzar}
            >
                Siguiente
            </Button>
        </>
    );
}
