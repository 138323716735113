import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_ADMIN_PROTO = process.env.REACT_APP_API_ADMIN_PROTO;
const API_ADMIN_HOST = process.env.REACT_APP_API_ADMIN_HOST;
const API_ADMIN_PORT = process.env.REACT_APP_API_ADMIN_PORT;

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  //const [cookie, setCookie] = useState(null);

  const login = async (credentials) => {
    try {
      // Realizamos la petición de autenticación a la API utilizando Axios
      const response = await axios.post(
        `${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/login`,
        { ...credentials },
        { withCredentials: true }
      );
      //console.log("mensaje:"+response.data.message+"-"+response.status);
      // Si la respuesta es exitosa y contiene el token de acceso
      if (response.status === 200 && response.data.success) {
        // Guardamos el usuario autenticado en el estado
        setIsAuthenticated(response.data.success);
        // Guardamos el token de acceso en el almacenamiento local (localStorage o sessionStorage)
        //localStorage.setItem("token", response.data.token);
        window.location.href = "/";
      } else {
        console.log("AuthContext login:"+response.data.message);
        toast.error(response.data.message, {
          position: "bottom-left",
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error(error, {
        position: "bottom-left",
        autoClose: 1000,
      });
    }
  };

  const logout = async () => {
    // Limpiamos el estado y eliminamos el token de acceso del almacenamiento local
    try {
      const { response } = await axios.get(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/logout`, {
        withCredentials: true,
      });

      if (response.data.success) {
        Cookies.remove("token");
        //localStorage.removeItem("token");
      }
    } catch (error) {
      toast.error(error, {
        position: "bottom-left",
        autoClose: 1000,
      });
    }

    setIsAuthenticated(null);
    //localStorage.removeItem("token");
    Cookies.remove("token");
    window.location.href = "/";
  };

  // Función para verificar la sesión
  const checkSession = async () => {
    //const localSessionToken = localStorage.getItem("token");
    //console.log(localSessionToken);
    //const formtoken = {token: localSessionToken};
    const formtoken = {};
    try {
      const { data } = await axios.post(`${API_ADMIN_PROTO}://${API_ADMIN_HOST}:${API_ADMIN_PORT}/verify`,formtoken, { withCredentials: true });
      setIsAuthenticated(data.status);

      if ( !data.status ) {
        setIsAuthenticated(false);
        //window.location.href = '/';
      }

    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    // Verificar la sesión al cargar la aplicación
    checkSession();

    // Configurar el temporizador para verificar la sesión periódicamente (cada 5 minutos en este ejemplo)
    const sessionTimer = setInterval(() => {
      checkSession();
    }, 60 * 1000);

    return () => {
      // Limpiar el temporizador al desmontar el componente
      clearInterval(sessionTimer);
    };
  }, []);

  const authContextValue = {
    isAuthenticated,
    setIsAuthenticated,
    checkSession,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
