import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import {
  styled,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";

import { Link, Outlet } from "react-router-dom";

import logo from "../assets/logoMain.png";
import { Tooltip, Zoom } from "@mui/material";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import HomeIcon from "@mui/icons-material/Home";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";


import { AuthContext } from "../contexts/AuthContext";

const themeMenu = createTheme({
  palette: {
    mode: "dark", // Modo oscuro
    background: {
      default: "#ffffff", // Color de fondo oscuro
    },
    text: {
      primary: "#374151", // Color de texto claro
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif", // Fuente clara
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          margin: 0;
          padding: 0;
          font-family: 'Roboto, sans-serif';
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      `,
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#fff", // Iconos claros
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#fff", // Enlaces claros
          textDecoration: "none", // Sin decoración
          fontSize: "14px",
        },
      },
    },
  },
});

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MenuDashboardSec() {
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    // Eliminar la cookie de token
    //removeCookie('token');
    logout();

    // Otras acciones de logout, como redireccionar a la página de inicio de sesión
    // o limpiar el estado de tu aplicación
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={themeMenu}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Box component="img" sx={{ height: 54 }} alt="Logo" src={logo} />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <Tooltip
              TransitionComponent={Zoom}
              title="Perfil"
              placement="right"
            >
              <Link to="/perfil" onClick={handleDrawerClose}>
                <ListItem key="Perfil" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Perfil" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Tooltip>
          </List>
          <List>
            <Tooltip TransitionComponent={Zoom} title="Home" placement="right">
              <Link to="/" onClick={handleDrawerClose}>
                <ListItem key="Home" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Tooltip>
          </List>
          {/*}
          <List>
            <Tooltip
              TransitionComponent={Zoom}
              title="Encuesta"
              placement="right"
            >
              <Link to="/encuesta">
                <ListItem key="Encuesta" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <SourceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Encuesta" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Tooltip>
              </List>{*/}
          <List>
            <Tooltip
              TransitionComponent={Zoom}
              title="Agregar Cargo"
              placement="right"
            >
              <Link to="/cargo_cliente" onClick={handleDrawerClose}>
                <ListItem key="Agregar Cargo" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <AddReactionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Agregar Cargo" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Tooltip>
          </List>
          <List>
            <Tooltip
              TransitionComponent={Zoom}
              title="Agregar usuario"
              placement="right"
            >
              <Link to="/agregar_cuenta" onClick={handleDrawerClose}>
                <ListItem key="Agregar usuario" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <PersonAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Agregar usuario" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Tooltip>
          </List>
          <List onClick={handleLogout}>
            <Tooltip TransitionComponent={Zoom} title="Salir" placement="right">
              <Link to="/agregar_cuenta">
                <ListItem key="Salir" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Salir" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Tooltip>
          </List>
          <Divider />
          <List>
            <Tooltip
              TransitionComponent={Zoom}
              title="Modulos"
              placement="right"
            >
              <Link to="/modulos" onClick={handleDrawerClose}>
                <ListItem key="Modulos" disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <ViewModuleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Modulos" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </Tooltip>
          </List>
        </Drawer>
        <Main open={open}>
          <Outlet />
        </Main>
      </Box>
    </ThemeProvider>
  );
}
