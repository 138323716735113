// DashboardApp.jsx
import React, { useState, useContext } from 'react';

import {
  ThemeProvider, createTheme, CssBaseline, Box, AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem,
  ListItemIcon, ListItemText, Divider, Hidden, Collapse, Avatar, MenuItem, Menu, ListItemAvatar, ListItemSecondaryAction
} from '@mui/material';
import {
  Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, Dashboard as DashboardIcon, ShoppingBasket as ShoppingBasketIcon,
  People as PeopleIcon, BarChart as BarChartIcon, Layers as LayersIcon, ExpandLess, ExpandMore, StarBorder, AccountCircle
} from '@mui/icons-material';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import HomeIcon from "@mui/icons-material/Home";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";

import SourceIcon from '@mui/icons-material/Source';

import logoApp from "../assets/logoMain.png";

import { AuthContext } from "../contexts/AuthContext";

import { Link } from "react-router-dom";

const drawerWidth = 240;

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          //height: '50px', // Uncomment these if you want to enforce a fixed height
          //minHeight: '50px',
          backgroundColor: '#333', // Example of adding a dark background color
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#333', // Dark color for Drawer
          color: '#fff', // White text for dark backgrounds
          marginTop: '64px', // Adjust the margin to the height of the AppBar if needed
          width: drawerWidth, // You can set the width for the Drawer here as well
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '40px',
          color: 'rgba(255, 255, 255, 0.54)', // Adjust icon color opacity
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.875rem', // Smaller text for Drawer items
          // Add additional styles here if needed
        },
      },
    },
    // If you want to remove text decoration from Links globally you can add:
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none', // Removes text decoration from all Links
          color: 'inherit', // Ensures the Link color inherits from the parent
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'rgba(255, 255, 255, 0.54)', // Adjust icon color opacity
        },
      },
    },
  },
});


function DashboardApp() {

  const { logout } = useContext(AuthContext);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuItemClick = () => {
    // Aquí puedes agregar más lógica si es necesario
    // Por ejemplo, cambiar el componente de contenido principal
    handleDrawerClose();
  };

  const { perfilUsuario, perfilImagen } = useContext(AuthContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickCollapse = () => {
    setOpenCollapse(!openCollapse);
  };


  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };


  const handleLogout = async () => {
    await logout();
  };

  const drawer = (
    <div>
      <IconButton onClick={handleDrawerClose} >
        <ChevronLeftIcon />
      </IconButton>
      <Divider />
      <List>

        <ListItem button component={Link} to="/" onClick={handleDrawerClose} >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/cargo_cliente" onClick={handleDrawerClose} >
          <ListItemIcon>
            <AddReactionIcon />
          </ListItemIcon>
          <ListItemText primary="Agregar Cargo" />
        </ListItem>
        {/* <ListItem button onClick={handleClickCollapse}>
          <ListItemIcon>
            <ShoppingBasketIcon />
          </ListItemIcon>
          <ListItemText primary="Projects" />
          {openCollapse ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button sx={{ pl: 4 }}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Current" />
            </ListItem>
            <ListItem button sx={{ pl: 4 }}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Archive" />
            </ListItem>
          </List>
        </Collapse> */}

        <ListItem button component={Link} to="/agregar_cuenta" onClick={handleDrawerClose} >
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary="Agregar usuario" />
        </ListItem>

        <ListItem button component={Link} to="/modulos" onClick={handleDrawerClose} >
          <ListItemIcon>
            <ViewModuleIcon />
          </ListItemIcon>
          <ListItemText primary="Modulos" />
        </ListItem>

        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Salir" />
        </ListItem>
      </List>
    </div>
  );

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
    </Menu>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerOpen}
              sx={{ mr: 2, display: isDrawerOpen ? 'none' : 'block' }}>
              <MenuIcon />
            </IconButton>
            <Box component="img" sx={{ height: 54 }} alt="Logo" src={logoApp} />
          </Toolbar>
        </AppBar>


        <Drawer
          variant="temporary"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            display: { xs: 'block', sm: 'block' }, // Adjust based on your needs
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}

export default DashboardApp;
