import React, { useState, useContext, useEffect, useRef } from "react";
import HomeIcon from "@mui/icons-material/Home";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ModulosBreadCrumbs from "./Modulos/ModulosBreadCrumbs/ModulosBreadCrumbs";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  NativeSelect,
  Typography,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { MineducContext } from "../contexts/MineducContext";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { capitalize, fontSize, fontSizeTitle } from "../utils/utils";
export const NOTIFICATIONTIME = process.env.REACT_APP_NOTIFICATIONTIME;

export default function NominaAlumno() {
  const {
    isLoadUseEffect,
    getAllColegios,
    listaColegios,
    callBotNominaAlumno,
    getSessionNominaAlumno,
    listaSessionNominaAlumno,
    getSessionEstadoSigeMineduc,
    listaSessionEstadoSigeMineduc,
    getNominaAlumnos,
    listNominaAlumnos,
    getAllTipoEnsenanza,
    listTipoEnsenanza
  } = useContext(MineducContext);

  let menuItems = [
    {
      href: "/",
      text: "Home",
      componenteIcon: <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulos",
      text: "Modulos",
      componenteIcon: <AccountTreeIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      href: "/modulo/ilumen",
      text: "iLumen",
      componenteIcon: <AutoStoriesIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];

  const [statusLoading, setStatusLoading] = useState(null);
  const [previewNominaDB, setPreviewNominaDB] = useState(false);
  const [rowsDB, setRowsDB] = useState([]);

  const [extNominaAlumnosRBD, setExtNominaAlumnosRBD] = useState();
  const [extNominaAlumnosANIO, setExtNominaAlumnosANIO] = useState();

  const [sessionRBD, setSessionRBD] = useState(null);
  const [sessionAnio, setSessionAnio] = useState(null);

  useEffect(() => {
    getAllColegios();
    getSessionNominaAlumno();
    getSessionEstadoSigeMineduc();
    getAllTipoEnsenanza();
    const intervalId = setInterval(() => {
      getSessionNominaAlumno();
    }, 5000); // Actualiza la tabla cada 5 segundos (puedes ajustar el tiempo según tus necesidades)

    return () => clearInterval(intervalId);
  }, []);

  const LoadingSpinner = () => {
    return (
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 30 },
    {
      field: 'anio',
      headerName: 'Año',
      width: 60,
      editable: false,
    },
    {
      field: 'rbd',
      headerName: 'RBD',
      width: 60,
      editable: false,
    },
    {
      field: 'cod_tipo_ensenanza',
      headerName: 'Cod. Tipo Enseñanza',
      width: 70,
      editable: false,
    },
    {
      field: 'cod_grado',
      headerName: 'Cod. Grado',
      width: 70,
      editable: false,
    },
    {
      field: 'desc_grado',
      headerName: 'Desc. Grado',
      width: 90,
      editable: false,
    },
    {
      field: 'letra_curso',
      headerName: 'Letra',
      width: 60,
      editable: false,
    },
    {
      field: 'run',
      headerName: 'Run',
      width: 100,
      editable: false,
    },
    {
      field: 'digito_ver',
      headerName: 'Digito Ver.',
      width: 60,
      editable: false,
    },
    {
      field: 'genero',
      headerName: 'Genero',
      width: 60,
      editable: false,
    },
    {
      field: 'nombres',
      headerName: 'Nombres',
      width: 150,
      editable: false,
    },
    {
      field: 'ape_paterno',
      headerName: 'Apellido Paterno',
      width: 150,
      editable: false,
    },
    {
      field: 'ape_materno',
      headerName: 'Apellido Materno',
      width: 150,
      editable: false,
    },
    {
      field: 'direccion',
      headerName: 'Dirección',
      width: 90,
      editable: false,
    },
    {
      field: 'comuna',
      headerName: 'Comuna',
      width: 150,
      editable: false,
    },
    {
      field: 'cod_comuna',
      headerName: 'Cod. Comuna',
      width: 90,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 90,
      editable: false,
    },
    {
      field: 'telefono',
      headerName: 'Teléfono',
      width: 90,
      editable: false,
    },
    {
      field: 'celular',
      headerName: 'Celular',
      width: 90,
      editable: false,
    },
    {
      field: 'fecha_nac',
      headerName: 'Fecha Nacimiento',
      width: 90,
      editable: false,
    },
    {
      field: 'cod_etnia',
      headerName: 'Cod. Etnia',
      width: 90,
      editable: false,
    },
    {
      field: 'fecha_inc_curso',
      headerName: 'Fecha Ing. Curso',
      width: 90,
      editable: false,
    },
    {
      field: 'fecha_retiro',
      headerName: 'Fecha Ret. Curso',
      width: 90,
      editable: false,
    },
    {
      field: 'asistencia',
      headerName: 'Asistencia',
      width: 90,
      editable: false,
    },
    {
      field: 'promedio_final',
      headerName: 'Promedio Final',
      width: 90,
      editable: false,
    },
  ];

  function getColegioByRBD(rbd) {
    let colegio = "";
    if (Array.isArray(listaColegios)) {
      listaColegios.map((infoColegio) => {
        if (infoColegio.rbd === rbd) {
          colegio = infoColegio.nombre;
        }
      });
    }

    return colegio;
  }

  function getTipoEnsenanzaByCode(codigo, grado) {
    let descripcion = "";
    if (Object.keys(listTipoEnsenanza).length > 0) {
      listTipoEnsenanza.forEach((item) => {
        if (item.codigo === codigo && item.grado === grado) {
          descripcion = item.descripcion;
        }
      });
    }
    return descripcion;
  }

  function getSessionEstadoByCode(code) {
    let descripcion = "";
    if (Object.keys(listaSessionEstadoSigeMineduc).length > 0) {
      listaSessionEstadoSigeMineduc.forEach((item) => {
        if (item.estado === code) {
          descripcion = item.descripcion;
        }
      });
    }
    return descripcion;
  }

  const renderOptionsColegios = () => {
    const options = [];
    options.push(
      <option key="sel-colegio-null" value="   ">
        [seleccione colegio]
      </option>
    );

    if (Array.isArray(listaColegios)) {
      listaColegios.map((infoColegio) => {
        options.push(
          <option key={`sel-colegio-${infoColegio.id_colegio}`} value={infoColegio.rbd}>
            {infoColegio.nombre}
          </option>
        );
      });
    }

    return options;
  };

  const actualAnio = new Date().getFullYear();
  const iniciaAnio = 2009;

  const renderOptionsAnios = () => {
    const options = [];
    options.push(<option key="option-anio-0" value="    "></option>);
    for (let anio = actualAnio; anio >= iniciaAnio; anio--) {
      options.push(
        <option key={`option-anio-${anio}`} value={anio}>
          {anio}
        </option>
      );
    }

    return options;
  };

  const BotonVerNominaAlumno = ({ rbd, anio, id_session_nomina_alumno, getNominaAlumnosAndUpdate }) => {

    return (
      <>
        <IconButton
          aria-label="Ver Nomina Alumnos"
          onClick={() =>
            getNominaAlumnosAndUpdate(rbd, anio, id_session_nomina_alumno)
          }
        >
          <VisibilityIcon />
        </IconButton>
      </>
    );
  };

  const renderTablaSesiones = () => {
    const tablaSession = [];
    const rowsTablaSession = [];

    const columns = [
      { field: 'id', headerName: 'ID', width: 30 },
      {
        field: 'colegio',
        headerName: 'Colegio',
        width: 200,
        editable: false,
      },

      {
        field: 'anio',
        headerName: 'Año',
        width: 70,
        editable: false,
      },
      {
        field: 'estado',
        headerName: 'Estado',
        width: 200,
        editable: false,
      },
      {
        field: 'creado',
        headerName: 'Creado',
        width: 200,
        editable: false,
      },
      {
        field: 'actualizado',
        headerName: 'Actualizado',
        width: 200,
        editable: false,
      },
      {
        field: 'registros_almacenados',
        headerName: 'Registros Almacenados',
        width: 200,
        editable: false,
      },
      {
        field: 'opciones',
        headerName: 'Opciones',
        width: 100,
        editable: false,
        renderCell: (params) => (
          <>
          <BotonVerNominaAlumno  rbd={params.row.rbd} anio={params.row.anio} id_session_nomina_alumno={params.id} getNominaAlumnosAndUpdate={getNominaAlumnosAndUpdate} />
          </>
        ),
      },

    ];

    const rows = [];

    if (Object.keys(listaSessionNominaAlumno).length > 0) {
      listaSessionNominaAlumno.forEach((item) => {
        let createdAtFecha = new Date(item.createdAt);
        let updatedAtFecha = new Date(item.updatedAt);

        let objTablaSessionNominaAlumno = {
          id: item.id_session_nomina_alumno,
          rbd: item.rbd,
          colegio: capitalize(item.infocolegio.nombre),
          anio: item.anio,
          estado: getSessionEstadoByCode(item.estado),
          creado: createdAtFecha.toLocaleString(),
          actualizado: updatedAtFecha.toLocaleString(),
          registros_almacenados: item.registrosAlmacenados,

        };

        rows.push(objTablaSessionNominaAlumno);

      });
    }
    tablaSession.push(
      <>
        <DataGrid
          autoHeight
          autoWidth
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
            sorting: {
              sortModel: [{ field: 'anio', sort: 'desc' }], // Ordenar por 'anio' de forma descendente
            },
          }}
          pageSizeOptions={[15]}
          checkboxSelection
          disableRowSelectionOnClick
          rowHeight={30}
          columnHeaderHeight={40}
        />
      </>
    );
    return tablaSession;
  };

  const handleOpenNominaSesion = async (rbd, anio, id, updatedListNominaAlumnos) => {
    setStatusLoading(true);
    setPreviewNominaDB(true);
    setRowsDB([]);

    if (Array.isArray(updatedListNominaAlumnos)) {

      let indexNomina = 1;
      let toma_muestra_anio = null;
      updatedListNominaAlumnos.forEach(nomina => {
        console.log(nomina);
        if (indexNomina == 1) { toma_muestra_anio = nomina.anio; }

        let objTablaNominaAlumno = {
          id: indexNomina,
          anio: nomina.anio,
          rbd: nomina.rbd,
          cod_tipo_ensenanza: nomina.codigo,
          cod_grado: nomina.grado,
          desc_grado: getTipoEnsenanzaByCode(nomina.codigo, nomina.grado),
          letra_curso: nomina.letra,
          run: nomina.run,
          digito_ver: nomina.digito_ve,
          genero: nomina.genero,
          nombres: nomina.nombres,
          ape_paterno: nomina.ape_paterno,
          ape_materno: nomina.ape_materno,
          direccion: nomina.direccion,
          comuna: nomina.comuna_residencia,
          //cod_comuna: nomina[14],
          email: nomina.email,
          telefono: nomina.telefono,
          //celular: nomina[17],
          fecha_nac: nomina.fecha_nacimiento,
          cod_etnia: nomina.etnia,
          fecha_inc_curso: nomina.fecha_incorporacion_curso,
          fecha_retiro: nomina.fecha_retiro,
          //asistencia: nomina.asistencia_final,
          promedio_final: nomina.promedio_final,
        };

        setRowsDB((prevRowsDB) => [...prevRowsDB, objTablaNominaAlumno]);
        indexNomina = indexNomina + 1;
      });
      setStatusLoading(false);
      console.log("anio : " + anio + "toma_muestra_anio : " + toma_muestra_anio);
      if (anio != toma_muestra_anio) {
        toast.info("Por favor, intente nuevamente.", {
          position: "bottom-left",
          autoClose: NOTIFICATIONTIME,
        });
      }
    } else {
      toast.info("Por favor, intente nuevamente.", {
        position: "bottom-left",
        autoClose: NOTIFICATIONTIME,
      });
      console.log('updatedListNominaAlumnos Vacio');
      setStatusLoading(false);
    }
  };

  const getNominaAlumnosAndUpdate = async (rbd, anio, id) => {
    setSessionRBD(rbd);
    setSessionAnio(anio);
    setPreviewNominaDB(false);
    await getNominaAlumnos(rbd, anio);

    setTimeout(() => {
      handleOpenNominaSesion(rbd, anio, id, listNominaAlumnos);
    }, 2000); // Ajusta el tiempo según sea necesario
  };

  return (
    <>
      <Container maxWidth={false} sx={{ background: "#ffffff", height: "auto", pt: "40px", pb: '40px' }}>
        <ModulosBreadCrumbs menuItems={menuItems} />
        <Box>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: fontSizeTitle,
            }}
          >
            NÓMINA DE ALUMNO
          </Typography>
          <Divider variant="middle" sx={{ mb: "40px", mt: "10px" }} />
          <Box sx={{ textAlign: "justify", m: "auto", pb: "20px" }}>
            <Typography
              variant="a"
              sx={{
                fontSize: fontSize,
              }}
            >
              Esta nómina permite obtener los datos completos de identificación de todos los alumnos, curso, run, nombres, domicilio, fecha de nacimiento, fecha de incorporación, fecha de retiro, % de asistencia
              Para importar la nómina de los alumnos desde Mineduc SIGE seleccione año y el colegio a importar, luego haga click en el botón "Importar Nomina Alumno (Automatizado)" , para importar de forma manual presione "Importar Nomina Alumno (Manual)".
            </Typography>
          </Box>
          {isLoadUseEffect ? (
            <>
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ m: "auto" }}
                >
                  {statusLoading && <LoadingSpinner />}
                  <Box sx={{ "& > :not(style)": { m: 1 } }}>
                    <Grid container spacing={2} style={{ margin: '0px', width: 'auto' }}>

                      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                        <FormControl fullWidth>
                          <InputLabel variant="standard" htmlFor="colegio">
                            Colegio
                          </InputLabel>
                          <NativeSelect

                            defaultValue={0}
                            size="small"
                            inputProps={{
                              name: "cliente-colegio",
                              id: "cliente-colegio",
                            }}
                            onChange={(e) =>
                              setExtNominaAlumnosRBD(e.target.value)
                            }
                          >
                            {renderOptionsColegios()}
                          </NativeSelect>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                        <FormControl fullWidth>
                          <InputLabel variant="standard" htmlFor="anio">
                            Año
                          </InputLabel>
                          <NativeSelect
                            defaultValue={0}
                            size="small"
                            inputProps={{
                              name: "cliente-anio",
                              id: "cliente-anio",
                            }}
                            onChange={(e) =>
                              setExtNominaAlumnosANIO(e.target.value)
                            }
                          >
                            {renderOptionsAnios()}
                          </NativeSelect>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                        <Button
                          fullWidth
                          size="small"
                          variant="contained"
                          type="button"
                          onClick={() =>
                            callBotNominaAlumno(
                              extNominaAlumnosRBD,
                              extNominaAlumnosANIO
                            )
                          }
                          sx={{
                            fontSize: {
                              xl: 11,
                              lg: 11,
                              md: 10,
                              sm: 10,
                              xs: 10,
                            },
                            whiteSpace: "pre-line",
                          }}
                        >

                          Importar Nomina Alumno
                          <br />
                          (Automatizado)
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                        <Link to="/modulo/ilumen/nomina_alumno/manual">
                          <Button
                            fullWidth
                            size="small"
                            variant="contained"
                            type="button"
                            sx={{
                              fontSize: {
                                xl: 11,
                                lg: 11,
                                md: 10,
                                sm: 10,
                                xs: 10,
                              },
                              whiteSpace: "pre-line",
                            }}
                          >
                            Importar Nomina Alumno
                            <br />
                            (Manual)
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <LoadingSpinner />
            </>
          )}
          <Grid container spacing={2}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ m: "auto", width: "100%" , marginTop: "30px"}}
            >
              {renderTablaSesiones()}
            </Grid>
          </Grid>
          {previewNominaDB && (
            <>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{ m: "auto", width: "100%" }}
              >
                {rowsDB && rowsDB.length > 0 && (
                  <>
                    <Typography variant="h6" gutterBottom sx={{ position: 'relative', backgroundColor: '#fff', zIndex: 1, textAlign: 'left' }}>
                      <span>{getColegioByRBD(sessionRBD)}: Nomina de alumnos {sessionAnio} </span>
                    </Typography>

                    <DataGrid
                      autoHeight
                      autoWidth
                      rows={rowsDB}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 30,
                          },
                        },
                      }}
                      pageSizeOptions={[30]}
                      checkboxSelection
                      disableRowSelectionOnClick
                      rowHeight={30}
                      columnHeaderHeight={40}
                    />

                  </>
                )}
              </Grid>
            </>
          )}
        </Box >
      </Container >
    </>
  );
}
